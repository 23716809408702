/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./proponemos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./proponemos.component";
import * as i3 from "@angular/router";
import * as i4 from "../wpbackend.service";
import * as i5 from "../app.service";
var styles_ProponemosComponent = [i0.styles];
var RenderType_ProponemosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProponemosComponent, data: {} });
export { RenderType_ProponemosComponent as RenderType_ProponemosComponent };
export function View_ProponemosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["src", "https://docs.google.com/document/d/1IHYlqlmkjqdxtR4uqf8KNGLrkEWo6qwo2MRWaOFH_LE/preview"]], null, null, null, null, null))], null, null); }
export function View_ProponemosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-proponemos", [], null, null, null, View_ProponemosComponent_0, RenderType_ProponemosComponent)), i1.ɵdid(1, 114688, null, 0, i2.ProponemosComponent, [i3.ActivatedRoute, i3.Router, i4.WpbackendService, i5.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProponemosComponentNgFactory = i1.ɵccf("app-proponemos", i2.ProponemosComponent, View_ProponemosComponent_Host_0, {}, {}, []);
export { ProponemosComponentNgFactory as ProponemosComponentNgFactory };
