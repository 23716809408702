<div class="base_logo" *ngIf="!started" (click)="start()">
  <div class="logo" (click)="start()">
  </div>
  <div class="play_btn">
  </div>
</div>

<div id="yt-player"></div>
<audio #audioplayer></audio>
<div class="navigation">
  <div class="marquee" *ngIf="marquee_display"><span [innerHTML]="titles[ current_song ]"></span></div>
  <div (click)="prev()" class="flechas left"><img src="assets/images/waitingroom/flecha_left.svg"></div>
  <div (click)="next()" class="flechas right"><img src="assets/images/waitingroom/flecha_right.svg"></div>
</div>
