import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WpbackendService = /** @class */ (function () {
    function WpbackendService(http) {
        this.http = http;
    }
    WpbackendService.prototype.getOptions = function () {
        // console.log("getting options from wp wpbackend");
        return this.http.get(environment.apiurl + 'wp-json/wp/v2/acf/options', {
            params: {
                per_page: '99',
                custom_per_page: '1000'
            }
        });
    };
    WpbackendService.prototype.getPosts = function () {
        return this.http.get(environment.apiurl + 'wp-json/wp/v2/media?author=11', {
            params: {
                per_page: '99',
                custom_per_page: '1000'
            }
        });
    };
    WpbackendService.prototype.getNews = function () {
        return this.http.get('https://45sna.com/news.json');
    };
    WpbackendService.prototype.getTerms = function (taxonomy) {
        return this.http.get('https://45sna.com/wp-json/wp/v2/' + taxonomy);
    };
    WpbackendService.prototype.getPostit = function (type) {
        return this.http.get(environment.apiurl + 'wp-json/wp/v2/' + type + '?_embed', {
            params: {
                per_page: '1',
                categories: '2,8'
            }
        });
    };
    WpbackendService.prototype.getList = function (type) {
        var orderby = 'date';
        if (type == 'curadurias' || type == 'artistas' || type == 'equipo') {
            orderby = 'rand';
        }
        return this.http.get(environment.apiurl + 'wp-json/wp/v2/' + type + '?_embed', {
            params: {
                per_page: '99',
                orderby: orderby
            }
        });
    };
    WpbackendService.prototype.getCounter = function () {
        return this.http.get(environment.apiurl + 'counter.php');
    };
    WpbackendService.prototype.getSingle = function (slug, type) {
        return this.http.get(environment.apiurl + 'wp-json/wp/v2/' + type, {
            params: {
                slug: slug
            }
        });
    };
    WpbackendService.prototype.getCalendar = function () {
        return this.http.get('https://45sna.com/calendar.json');
    };
    WpbackendService.prototype.shuffle = function (array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };
    WpbackendService.ngInjectableDef = i0.defineInjectable({ factory: function WpbackendService_Factory() { return new WpbackendService(i0.inject(i1.HttpClient)); }, token: WpbackendService, providedIn: "root" });
    return WpbackendService;
}());
export { WpbackendService };
