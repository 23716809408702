<div class="logo"><img src="assets/images/05.svg"></div>
<div class="aarea_logo"><a href="https://www.aarea.co/" target="_blank"><img src="assets/images/aarea_logo.svg"></a></div>

<div class="intro">
  <div class="content" >
    <h1>Redirección</h1>

      <div class="idioma">
        <span (click)="activateLang( 'es' )" [ngClass]="{'active': lang == 'es'}">español</span>  /   <span (click)="activateLang( 'en' )" [ngClass]="{'active': lang == 'en'}">english</span>
      </div>
    <div class="intro_espanol" *ngIf="lang == 'es'">
      <p>
        Fundado en 2017 por Livia Benedetti y Marcela Vieira, en São Paulo, Brasil, el
        colectivo <strong><a href="https://www.aarea.co/" target="_blank">aarea</a></strong> comisiona proyectos a artistas para ser exhibidos exclusivamente
        en internet.
      </p>
      <p>
        <strong><i>Redirección</i></strong> fue una curaduría realizada por <strong><a href="https://www.aarea.co/" target="_blank">aarea</a></strong> y <strong><a href="equipo-curatorial/ana-maria-montenegro">Ana María Montenegro</a></strong>
        que invitó a 10 artistas de nacionalidades y trayectorias diversas a proponer
        obras para la web del Salón y <strong><a href="https://www.aarea.co/" target="_blank">www.aarea.co</a></strong>. Los proyectos se presentaron de manera
        consecutiva, mostrándose cada uno durante un tiempo específico del periodo
        del Salón en ambos sitios web simultáneamente.
      </p>
      <p>
        En 45sna.com se accedía a las obras al hacer click en esta barra negra siempre
        presente en la parte superior del sitio, que anunciaba el artista y el título del
        proyecto. En <strong><a href="https://www.aarea.co/" target="_blank">aarea.co</a></strong> –siguiendo el formato característico de <strong><a href="https://www.aarea.co/" target="_blank">aarea-</a></strong>, el espectador se encontraba con el proyecto directamente sin información adicional.
      </p>
      <p>
        Este archivo presenta los proyectos en el orden en el que fueron exhibidos.
        Debido a su naturaleza temporal y sus características técnicas es posible
        que algunos no funcionen tal cual como funcionaron en el momento de la
        exposición. Para comenzar a recorrerlos puede hacer click <strong><a href="/redireccion/anti">aquí</a></strong> o en el botón de
        <strong>iniciar / start ></strong> en la barra inferior.
      </p>

      <p>&nbsp;</p>
      <p class="center">acerca de los artistas y los proyectos<br>
      v</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <p>
      <strong>13 de septiembre y 5 de noviembre, 7:00 - 7:46 p. m.</strong><br>
      <a href="redireccion/anti" ><i>Anti</i></a> (<a href="/artistas/wallace-masuko" class="artista"  target="_blank">Wallace V. Masuko</a>)<br>
      <a href="redireccion/anti" ><i>Anti</i></a> es parte de la serie <i>Ciclo:</i> tres trabajos que se hicieron para el 45 Salón Nacional de Artistas,
      dos de los cuales son videotextos (<i>Rey y Colono</i>), que fueron proyectado en espacios públicos en Bogotá y Medellín
      exactamente al mismo tiempo que sucedía <i>Anti</i> en la web.
      <i>Anti</i> es una adaptación sonora de 47 minutos de la tragedia griega <i>Antígona</i> de Sófocles,
      en la que la cantante colombiana Juanita Delgado pronuncia únicamente los nombres de los personajes según
      van apareciendo de forma sostenida de acuerdo a la duración de cada diálogo, variando e improvisando efectos
      y modulaciones vocales para cada uno. El personaje “Coro” era distorsionado<sup>1</sup> y afectado en tiempo real de acuerdo
      a la cantidad de usuarios conectados.
      Estas obras, que abrieron y cerraron el Salón sin estar técnicamente en él (estaban justo antes y justo después),
      fueron prólogo y epílogo, como las puertas de una ciudad que se abrieron y cerraron.<br><br>
      <sub>1 Los arreglos y la distorsión fueron hechos por Vladimir Giraldo.</sub>
      <a href="redireccion/anti" ><img src="https://45sna.com/wp-content/uploads/2019/06/wallace_masuko-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>14 - 20 de septiembre</strong><br>
      <a href="redireccion/birthday"><i>Having Someone’s Birthday Party</i></a> (<a href="/artistas/yoshinori-niwa" class="artista"  target="_blank">Yoshinori Niwa</a>)<br>
      Al ingresar a <a href="redireccion/birthday"><i>Having Someone’s Birthday Party</i></a>  el espectador
      se encuentra con videos de YouTube de celebraciones de personas que cumplen años ese día.
      El trabajo de Yoshinori recopila más de 600 de estas celebraciones íntimas, de países tan diversos
      como Ucrania, Vietnam, Colombia, Estonia y Corea, entre otros. Las cualidades y la estética de estos videos
      caseros son muy variadas: algunas fueron grabadas con teléfonos celulares, mientras que otras fueron
      filmadas por equipos profesionales de  filmación. El extenso material organizado por Niwa es
      llamativo por su capacidad de ser simultáneamente ordinario, empático e íntimo. En la repetición de estos eventos,
      completamente distintos en su intención singular de celebrar un cumpleaños, es posible encontrar contrastes culturales profundos.
      <a href="redireccion/birthday"><img src="https://45sna.com/wp-content/uploads/2019/06/yoshinori_niwa-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>21 - 28 de septiembre</strong><br>
      <a href="redireccion/waitingroom"><i>The Waiting Room</i></a> (<a href="/artistas/sebastian-munera" class="artista"  target="_blank">Sebastián Múnera</a>)<br>
      En <a href="redireccion/waitingroom"><i>The Waiting Room</i></a> el espectador se encuentra con la transmisión en vivo de lo que sucede
      en zoológicos de diferentes partes del mundo. Estas imágenes de animales salvajes como osos polares,
      pandas, orangutanes, tigres y pingüinos en espacios de cautiverio pueden navegarse por medio de botones
      para avanzar y retroceder en la interfaz, que a su vez cambian la canción de fondo.
      Tanto las cámaras como las canciones fueron seleccionadas por Sebastián pensando en ambientes de salas de espera:
      consultorios, bancos, aeropuertos, etc., ambientes que usualmente cuentan con música y televisores para entretener
      a quienes esperan. Al utilizar estas imágenes, que de hecho han sido pensadas para vigilar pero también para entretener
      (son transmisiones de los mismos zoológicos), la obra señala la artificialidad de la manipulación que los humanos hacemos
      de la naturaleza.
      <a href="redireccion/waitingroom"><img src="https://45sna.com/wp-content/uploads/2019/06/sebastian_munera-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>29 de septiembre - 8 de octubre</strong><br>
      <a href="redireccion/seneb"><i>House of Seneb</i></a> (<a href="/artistas/tabita-rezaire-alicia-mersy" class="artista"  target="_blank">Alicia Mersy y Tabita Rezaire</a>)<br>
      Fundado en 2016 por Tabita Rezaire, <a href="redireccion/seneb"><i>House of Seneb</i></a>  (www.seneb.house) es un proyecto centrado en la noción
      de curación, una comunidad comprometida con las diásporas africanas y las tecnologías curativas,
      un centro de energía para que el alma recuerde, sienta, reconecte, comparta y vibre con el cosmos.
      Dentro de su espíritu colaborativo esta edición integra el trabajo de Alicia Mersy. <i>Hypnosis Excite</i>
      combina la estética Y2K con un tratamiento visual cómico, inspirado en una ficción científica para capturar
      la atención del visitante y crear un espacio para el diálogo. La hipnoterapeuta y avatar Lotoya Charles
      nos guía por las trampas de nuestras mentes hacia un presente en suspenso, donde compartimos el mismo aire
      y la potencialidad de una alegría inquebrantable. El trabajo renueva la noción de autocuidado separándose
      de la cultura capitalista que también la promueve, nos ofrece una conexión con lo divino y nos invita al descanso,
      con el objetivo de que nuestro diálogo interno pueda influir en la composición fundamental de nuestro ser colectivo.
      Mercy nos pide que recordemos que, en el nivel más elemental, todos somos seres espirituales atravesando una experiencia humana.
      <a href="redireccion/seneb"><img src="https://45sna.com/wp-content/uploads/2019/06/alicia_mersy_tabita_rezaire-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>2 de octubre</strong><br>
      <a href="redireccion/mondrigo"><i>Buscando a ¡el móndrigo!</i></a> (<a href="/artistas/david-escobar" class="artista"  target="_blank">David Escobar Parra</a>)<br>
      El prólogo de <i>¡El móndrigo! Bitácora del Consejo Nacional de
      Huelga</i>, publicado en la Ciudad de México, afirma que estamos ante el diario de uno de los líderes del
      movimiento estudiantil asesinado en la Masacre de Tlatelolco el 2 de octubre de 1968. Sin embargo, una búsqueda
      rápida confirma su origen en las oficinas del gobierno, usando
      los archivos de la Dirección Federal de Seguridad (DFS),
      para justificar sus prácticas represivas. Así, el relato es una verdad construida a partir de un sistema
      de símbolos apropiados para provocar efectos basados en una mentira. <a href="redireccion/mondrigo"><i>Buscando a ¡el móndrigo!</i></a> plantea la búsqueda
      por México de este estudiante y escritor enigmático, presentando transmisiones en vivo de plazas públicas
      las 24 horas del 2 de octubre. El espectador puede avanzar y retroceder estas cámaras para generar un poema
      inédito a partir de poemas de Jaime Jaramillo Escobar, Mario Santiago Papasquiaro, Caupolicán Ovalles y
      Juan Ramírez Ruiz: los posibles poemas que ¡el móndrigo! nunca escribió. Utilizando la construcción de un relato,
      el lenguaje como dispositivo y la especulación, la obra reivindica simbólicamente la lucha de los estudiantes
      mediante la reestructuración de los símbolos y espacios ganados en la guerra de las ideas y las palabras.
      <a href="redireccion/mondrigo"><img src="https://45sna.com/wp-content/uploads/2019/06/david_escobar-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>9 de octubre - 15 de octubre</strong><br>
      <a href="redireccion/cine"><i>Cine Continuado</i></a> (<a href="/artistas/ivan-candeo"  class="artista" target="_blank">Iván Candeo</a>)<br>
      <a href="redireccion/cine"><i>Cine Continuado</i></a> es un <i>collage</i> de fragmentos
      de películas colombianas realizadas entre 1915 y 2002 que narran y confrontan la historia
      de Colombia. Partiendo de la exigencia de
      cuantificar el tiempo, se extraen e intervienen una serie de escenas con un reloj que muestra
      la hora, minutos, segundos y milésimas de segundo de donde está el visitante. Ese presente
      provisional cuantificado se despliega en una variedad de fragmentos del “cine histórico”
      colombiano. Este <i>collage</i> de aproximadamente 30 minutos, se presenta en <i>loop</i>,
      sin poderse determinar cuándo comienza o termina, pues su inicio cambia al azar cuando se abre
      o recarga el sitio. Iván Candeo, venezolano, encuentra en estas películas fuertes similitudes
      con el paisaje y la historia política de Venezuela. La variedad de ríos, por ejemplo, muy usados
      como medio de transporte; las mujeres, que a menudo asumen un papel casi siempre relacionado con
      la religiosidad; y los asesinatos, que están presentes en prácticamente todas estas películas
      como un recurso estratégico para el cambio político.
      <a href="redireccion/cine"><img src="https://45sna.com/wp-content/uploads/2019/06/ivan_candeo-768x491.png"></a><br>
      </p>

      <p>
      <strong>16 de octubre - 22 de octubre</strong><br>
      <a href="redireccion/motta"><i>Cuerpo Celeste</i></a> (<a href="/artistas/aline-motta-rafael-galante"  class="artista" target="_blank">Aline Motta y Rafael Galante</a>)<br>
      <a href="redireccion/motta"><i>Cuerpo Celeste</i></a> es una animación construida a partir de formas sagradas milenarias centroafricanas del pueblo Bakongo,
      y de sus manifestaciones en la diáspora afrobrasileña. El trabajo tiene como referencia principal su símbolo más sagrado,
      llamado en el mundo kongo “dikenga diá kongo”, “Yowa” y, en Occidente, “cosmograma kongo”. A partir de este referente,
      la obra propone una especie de reloj que indica la “hora espiritual” según el lugar de donde se accede. Así,
      las 24 horas del día representan el ciclo de la vida: nacimiento (6 h), madurez (mediodía), muerte (18 h)
      y renacimiento (medianoche). Como una especie de oráculo, el reloj ofrece mensajes relacionados con cada momento
      a partir de expresiones  filosócas traducidas del kikongo por Tiganá Santana y del umbundu por José Francisco Valente.
      De origen centroafricano, el cosmograma bakongo es una síntesis de los grandes ciclos de la vida, el universo y el tiempo.
      Una línea, llamada kalunga, corta horizontalmente los mundos físico y espiritual, y alude al infinito de la línea del
      horizonte en el océano, punto de conexión entre estos mundos y las diferentes formas del ser/vivir.
      La división en cuatro cuadrantes por una cruz relaciona el paso del tiempo al ciclo de vida de los seres manera cíclica,
      transmutacional e interrelacional.
      <a href="redireccion/motta"><img src="https://45sna.com/wp-content/uploads/2019/06/aline_mota_rafael_galante-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>23 de octubre - 29 de octubre</strong><br>
      <a href="redireccion/esquina"><i>Sin título</i></a> (<a href="/artistas/carlos-fajardo"  class="artista" target="_blank">Carlos Fajardo</a>)<br>
      En este trabajo Carlos Fajardo (una  figura fundamental del
      arte en Brasil desde los años sesenta) presenta la transmisión en vivo de la imagen de la esquina que forman
      dos paredes, ubicada en un espacio interior sin luz artificial. Durante el periodo de la exposición del trabajo,
      durante 24 horas, la cámara registra sin interrupción la variación de la luz del sol re ejada en la superficie
      imperfecta desde el amanecer hasta el siguiente amanecer. Con este trabajo, el primero de Fajardo hecho para
      Internet, el artista retoma una serie de fotografías en blanco y negro que hizo a principios de la década de 2000,
      en el que también registraba el comportamiento de la luz en el encuentro de dos paredes.
      <a href="redireccion/esquina"><img src="https://45sna.com/wp-content/uploads/2019/06/carlos_fajardo-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>30 de octubre - 5 de noviembre, 7 p. m.</strong><br>
      <a href="redireccion/agua"><i>Agitando el agua durmiente</i></a> (<a href="/artistas/alejandra-jaramillo" class="artista"  target="_blank">Alejandra Jaramillo</a>)<br>
      Al ingresar a esta obra el espectador se encuentra con la transmisión en tiempo real de lo que sucedía en
      la pantalla de Alejandra, permitiéndole ser testigo del proceso de creación y edición de una
      película realizada a partir de la apropiación de videos encontrados en internet.
      Alejandra trabajaba durante largas sesiones en el día en este <i>video-collage</i> centrado en impresiones,
      recuerdos, desplazamientos y construcciones de su imaginario cotidiano. El guion, escrito por ella para esta obra,
      se desplegaba en la pantalla para ser leído cuando la artista no estaba editando la película.
      De esta manera, <i>Agitando el agua durmiente</i> se construye y se despliega paulatinamente ante el espectador,
      en un juego de reiteración del interés de la película por la forma del recuerdo y lo que sucede en la cotidianidad del día.
      <a href="redireccion/agua"><img src="https://45sna.com/wp-content/uploads/2019/07/alejandra_jaramillo-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>14 de septiembre - 4 de noviembre</strong><br>
      <strong><u><i>¿Quieres ser culpado por tu inocencia, o inocente de tu culpa?</i></u></strong>
      (<a href="/artistas/debora-bolsoni"  class="artista" target="_blank">Débora Bolsoni</a>)<br>
      Este proyecto funcionó como una intervención a lo largo del
      Salón que aparecía en <strong><i>fade-in</i></strong> intempestivamente luego de varios minutos de estar en 45sna.com y aarea.co.
      La imagen era un fragmento de video de 14 segundos del <i>Testamento de Orfeo</i> de Jean Cocteau (1960).
      En él un hombre emerge de la oscuridad de las aguas con una flor en la mano y se la ofrece al poeta, un personaje que
      circula libremente a través del tiempo y la narrativa de la película, moviéndose entre pasado y futuro.
      Débora toma prestada la estructura de este movimiento: su trabajo se mueve a través de las otras
      obras presentadas en aarea.co y en las páginas de 45sna.com. El poeta aparece en la pantalla brevemente para ofrecernos
      la flor. La pregunta “¿Quieres ser culpado por tu inocencia, o inocente de de tu culpa?”, que da título a la obra,
      es un texto de Débora que aparece como un subtítulo. La ambigüedad de la pregunta, inquisidora, interpela
      inesperadamente al espectador. Nos ofrece la flor amigablemente mientras no deja elección sobre el veredicto de culpabilidad.
      <img src="https://45sna.com/wp-content/uploads/2019/06/debora_bolsoni-1-768x498.png"><br>
      </p>
      <p>&nbsp;</p><p>&nbsp;</p>

  </div>



    <div class="intro_ingles" *ngIf="lang == 'en'">
      <p>
        Founded in 2017 by Livia Benedetti and Marcela Vieira, in São Paulo, Brazil,
        the collective <strong><a href="https://www.aarea.co/" target="_blank">aarea</a></strong>
         commissions projects to artists to be exhibited exclusively on the internet.
      </p>
      <p>
        Redirección
        <strong><i>Redirección</i></strong> [Redirection]
        was an exhibition curated by  <strong><a href="https://www.aarea.co/" target="_blank">aarea</a></strong> and <strong><a href="equipo-curatorial/ana-maria-montenegro">Ana María Montenegro</a></strong>
        which invited 10 artists from different nationalities and trajectories to propose works for the Salón website
        and <strong><a href="https://www.aarea.co/" target="_blank">www.aarea.co</a></strong>.
        The projects were presented consecutively, showing each one during a specific time of the Salón
        period on both websites simultaneously.
      </p>
      <p>
        In 45sna.com the works were accessed by clicking on this black bar always present at
        the top of the site, announcing the artist and the title of the project. In <strong><a href="https://www.aarea.co/"   target="_blank">aarea.co</a></strong> -
        following the characteristic format of <strong><a href="https://www.aarea.co/" target="_blank">aarea.co</a></strong>-, the viewer met the project directly without additional information.
      </p>
      <p>
        This archive presents the projects in the order in which they were exhibited.
        Due to their temporary nature and technical characteristics, some may not function
         as they did at the time of the exhibition. To start going through them you can click
         <strong><a href="/redireccion/anti">here</a></strong> or the <strong>iniciar / start></strong> button in the bottom bar.


      </p>

      <p>&nbsp;</p>
      <p class="center">artists and projects<br>
      v</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <p>
      <strong>September 13 and November 5, 7:00 - 7:46 p. m.</strong><br>
      <a href="redireccion/anti" ><i>Anti</i></a> (<a href="/artistas/wallace-masuko"  class="artista" target="_blank">Wallace V. Masuko</a>)<br>
      <a href="redireccion/anti" ><i>Anti</i></a>
      is part of the <i>Ciclo series: </i>three works that were made for the 45 Salón Nacional de Artistas,
      two of which are videotexts (<i>Rey and Colono</i>), which were screened in public spaces in Bogotá and Medellín
      exactly at the same time as <i>Anti</i> happened in the web. Anti is a 47-minute sound adaptation of the greek
       tragedy <i>Antígona</i>  by Sophocles, in which Colombian singer Juanita Delgado pronounces only the names of the
        characters as they appear in a sustained way according to the duration of each dialogue, varying and
        improvising effects and vocal modulations for each one. The character “Chorus” was distorted <sup>1</sup> and affected in
        real time according to the number of users online hearing the work. These works, which opened and closed
        the Salón without being technically in it (they were right before and right after), were prologue and epilogue,
        like the doors of a city that opened and closed. <br><br>
      <sub> The arrangements and the distortion were made by Vladimir Giraldo.</sub>
      <a href="redireccion/anti"><img src="https://45sna.com/wp-content/uploads/2019/06/wallace_masuko-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>September 14 - 20</strong><br>
      <a href="redireccion/birthday"><i>Having Someone’s Birthday Party</i></a> (<a href="/artistas/yoshinori-niwa"  class="artista" target="_blank">Yoshinori Niwa</a>)<br>
      Upon entering <a href="redireccion/birthday"><i>Having Someone’s Birthday Party</i></a>, the viewer
      finds YouTube videos of birthday celebrations of people who’s birthday is that day.
      Yoshinori's work collects more than 600 of these intimate celebrations, from countries as diverse
      as Ukraine, Vietnam, Colombia, Estonia and Korea, among others. The qualities and aesthetics of these home videos
      are varied: some were recorded with cell phones, while others were filmed by professional film crews.
      The extensive material organized by Niwa is striking for its ability to be simultaneously ordinary,
      empathetic and intimate. In the repetition of these events, completely different in their singular
      intention of celebrating a birthday, it is possible to find deep cultural contrasts.
      <a href="redireccion/birthday"><img src="https://45sna.com/wp-content/uploads/2019/06/yoshinori_niwa-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>September 21 - 28</strong><br>
      <a href="redireccion/waitingroom"><i>The Waiting Room</i></a> (<a href="/artistas/sebastian-munera"  class="artista" target="_blank">Sebastián Múnera</a>)<br>
       In <a href="redireccion/waitingroom"><i>The Waiting Room</i></a> the viewer finds the live streamings of what is happening in zoos
       in different parts of the world. These images of wild animals such as polar bears,
       pandas, orangutans, tigers and penguins in captive spaces can be navigated through
       buttons to move forward and backwards in the interface, which also change the background
       song. Both the cameras and the songs were selected by Sebastián thinking about waiting
       room environments: offices, banks, airports, etc., environments that usually have music
       and televisions to entertain those who wait. By using these images, which in fact have
       been designed to monitor but also to entertain (transmissions are made by zoos themselves),
       the work points to the artificiality of the manipulation that humans make of nature.
      <a href="redireccion/waitingroom"><img src="https://45sna.com/wp-content/uploads/2019/06/sebastian_munera-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>September 29 - October 8</strong><br>
      <a href="redireccion/seneb"><i>House of Seneb</i></a> (<a href="/artistas/tabita-rezaire-alicia-mersy"  class="artista" target="_blank">Alicia Mersy and Tabita Rezaire</a>)<br>

      Founded in 2016 by Tabita Rezaire,  <a href="redireccion/seneb"><i>House of Seneb </i></a>   (www.seneb.house)
      is a project focused on the notion of healing, a community committed to African diasporas and healing technologies,
       an energy center for the soul to remember, feel , reconnect, share and vibrate with the cosmos. Within its
       collaborative spirit this edition integrates the work of Alicia Mersy. <i>Hypnosis Excite</i> combines Y2K aesthetics
        with a comic visual treatment, inspired by a scientific fiction to capture the visitor's attention and
        create a space for dialogue. The hypnotherapist and avatar Lotoya Charles guides us through the traps of
         our minds towards a present in suspense, where we share the same air and the potential of an
         unshakable joy. The work renews the notion of self-care, separating itself from the capitalist
         culture that also promotes it, offers us a connection with the divine and invites us to rest,
          with the aim that our internal dialogue can influence the fundamental composition of our collective being.
          Mercy asks us to remember that, on the most elementary level, we are all spiritual beings going through
          a human experience.
          <a href="redireccion/seneb"><img src="https://45sna.com/wp-content/uploads/2019/06/alicia_mersy_tabita_rezaire-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>October 2nd</strong><br>
      <a href="redireccion/mondrigo"><i>Buscando a ¡el móndrigo!</i></a> [Looking for the ¡móndrigo!] (<a href="/artistas/david-escobar"  class="artista" target="_blank">David Escobar Parra</a>)<br>

      The prologue of <i>¡El móndrigo!</i> Bitácora del Consejo Nacional de Huelga,
      published in Mexico City, states that we are before the diary of one of the leaders
      of the student movement killed in the Tlatelolco Massacre on October 2, 1968. However,
      a quick research confirms it was produced in the government offices, using the files of
       the Federal Security Directorate (DFS), to justify its repressive practices. Thus, the story
       is a truth constructed from a system of appropriated symbols in order to cause an effect
        based on a lie.    <a href="redireccion/mondrigo"><i>Buscando a ¡el móndrigo!</i></a> proposes the search of this enigmatic student
        and writer around Mexico, presenting live streamings of public plazas during the 24 hours
        of October 2nd. The viewer can move forward and backwards through these cameras,
         generating an unpublished poem made from poems by Jaime Jaramillo Escobar, Mario Santiago Papasquiaro,
         Caupolicán Ovalles and Juan Ramírez Ruiz: the possible poems that the móndrigo never wrote.
          Using the construction of a story, language as a device and speculation, the work symbolically
          claims the struggle of the students by restructuring the symbols and spaces gained in the war of ideas and words.
          <a href="redireccion/mondrigo"><img src="https://45sna.com/wp-content/uploads/2019/06/david_escobar-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>October 9 - October 15</strong><br>
      <a href="redireccion/cine"><i>Cine Continuado</i></a> [Continued Cinema] (<a href="/artistas/ivan-candeo" class="artista"  target="_blank">Iván Candeo</a>)<br>
      <a href="redireccion/cine"><i>Cine Continuado</i></a>
      is a <i>collage</i>  of fragments of Colombian films made between 1915 and 2002 that tell
      and confront the history of Colombia. The requirement to quantify time is the starting point
      for a series of scenes which are extracted and intervened with a clock that shows the hour,
      minutes, seconds and thousandths of a second from the visitor’s local time.
      This quantified provisional present is displayed in a variety of fragments of the Colombian “historical cinema”.
      This <i>collage</i>  of approximately 30 minutes, is presented in <i>loop</i> , without being able to determine
      when it starts or ends, because its beginning changes randomly when the site is opened or reloaded. Iván Candeo,
      Venezuelan, finds in these films strong similarities with the landscape and political history of Venezuela.
       The variety of rivers, for example, widely used as a means of transport; women, who often assume a role almost always
       related to religiosity; and the murders, which are present in virtually all of these films as a strategic
       resource for political change.
      <a href="redireccion/cine"><img src="https://45sna.com/wp-content/uploads/2019/06/ivan_candeo-768x491.png"></a><br>
      </p>

      <p>
      <strong>October 16 - October 22</strong><br>
      <a href="redireccion/motta"><i>Cuerpo Celeste</i></a>  [Celestial Body] (<a href="/artistas/aline-motta-rafael-galante"  class="artista" target="_blank">Aline Motta and Rafael Galante</a>)<br>
      The project is an animation built from ancient Central African sacred forms of the Bakongo people, and their
       manifestations in the Afro-Brazilian diaspora. The work has as its main reference its most sacred symbol,
       called in the kongo world “dikenga diá kongo”, “Yowa” and, in the West, “kongo cosmogram”. From this
       reference, the work proposes a kind of clock that indicates the "spiritual hour" according to the place
       where it is accessed. Thus, the 24 hours of the day represent the cycle of life: birth (6 h), maturity (noon),
       death (18 h) and rebirth (midnight). As a kind of oracle, the clock offers messages related to each moment
       from philosophical expressions translated from kikongo by Tiganá Santana and from umbundu by José Francisco
       Valente. Of Central African origin, the bakongo cosmogram is a synthesis of the great cycles of life,
       the universe and time. A line, called Kalunga, horizontally cuts the physical and spiritual worlds,
       and alludes to the infinity of the horizon line in the ocean, a connection point between these worlds
       and the different forms of being / living. The division into four quadrants by a cross relates the passage
       of time to the life cycle of beings in a cyclic, transmutational and interrelational way.
      <a href="redireccion/motta"><img src="https://45sna.com/wp-content/uploads/2019/06/aline_mota_rafael_galante-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>October 23 - October 29</strong><br>
      <a href="redireccion/esquina"><i>Sin título</i></a> [Untitled] (<a href="/artistas/carlos-fajardo" class="artista"  target="_blank">Carlos Fajardo</a>)<br>
      In this work Carlos Fajardo (a fundamental figure of art in Brazil since the sixties)
      presents the live transmission of the image of the corner formed by two walls, located
      in an interior space without artificial light. During the period of the exhibition of the work,
      for 24 hours without interruption, the camera captures the variation of sunlight reflected on
      the imperfect surface from dawn to dawn. With this work, the first of Fajardo made for the Internet,
      the artist returns to a series of black and white photographs that he made in the early 2000s,
      in which he also recorded the behavior of light in the encounter of two walls.
      <a href="redireccion/esquina"><img src="https://45sna.com/wp-content/uploads/2019/06/carlos_fajardo-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>October 30 - November 5, 7 p. m.</strong><br>
      <a href="redireccion/agua"><i>Agitando el agua durmiente</i></a>  [Shaking the sleeping water] (<a href="/artistas/alejandra-jaramillo"  class="artista" target="_blank">Alejandra Jaramillo</a>)<br>
      Upon entering the work, the viewers finds the real-time transmission of what was happening on Alejandra's screen,
       allowing them to witness the process of creating and editing a movie made from the appropriation of
       videos found on the internet. Alejandra worked during long sessions in the day in this  <i>video-collage</i>
       focused on impressions, memories, displacements and constructions of her everyday imaginary. The script,
        written by her for this work, was displayed on the screen to be read when the artist was not editing the film.
        In this way, <i>Agitando el agua durmiente</i> unfolds gradually before the viewer, in a game of reiteration
        of the interest of the film: the form of the memory and what happens in the daily life.
        <a href="redireccion/agua"><img src="https://45sna.com/wp-content/uploads/2019/07/alejandra_jaramillo-1-768x491.png"></a><br>
      </p>

      <p>
      <strong>September 14 - November 4</strong><br>
      <strong><u><i>¿Quieres ser culpado por tu inocencia, o inocente de tu culpa?</i></u></strong>
       [Do you want to be guilty for your innocence, or innocent of your guilt?] (<a href="/artistas/debora-bolsoni"  class="artista" target="_blank">Débora Bolsoni</a>)<br>

       This project worked as an intervention throughout the Salón that suddenly appeared in <strong><i>fade-in</i></strong>  after
       several minutes of being at 45sna.com and aarea.co. The image was a 14-second video fragment of
       <i>Testament of Orpheus</i> by Jean Cocteau (1960). In it a man emerges from the darkness of the waters
       with a flower in his hand and offers it to the poet, a character who circulates freely through
       the time and the narrative of the film, moving between past and future. Débora borrows the structure
       of this movement: her work moves through the other works presented in aarea.co and on the pages of 45sna.com.
        The poet appears on the screen briefly to offer us the flower. The question "Do you want to be guilty for your
         innocence, or innocent of your guilt?", which gives the title to the work, is a text by Débora that
         appears as a subtitle. The ambiguity of the question, inquiring, unexpectedly questions the viewer.
         He offers us the flower amicably while he leaves no choice about the guilty verdict.

     <img src="https://45sna.com/wp-content/uploads/2019/06/debora_bolsoni-1-768x498.png"><br>
      </p>
      <p>&nbsp;</p><p>&nbsp;</p>
    </div>

  </div>
</div>
