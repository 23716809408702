<div class="content" (click)="updateActive()">
	<a *ngIf="!clicked" (click)="clickinit()" id="clickstart"><img src="https://45sna.com/assets/images/antigona/player.png" ></a>
  <div id="hide">
    <h1>Online: {{ online_count }} - Active track: {{ active_track }}</h1>
  <h3>Elapsed: {{ elapsed_time }}</h3>
  <div id="audios">
    <div>
      <h2>Track 1</h2>
      <audio [ngClass]="{'active': active_track == 1 }" #track1 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 1- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 2</h2>
      <audio [ngClass]="{'active': active_track == 2 }" #track2 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 2- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 3</h2>
      <audio [ngClass]="{'active': active_track == 3 }" #track3 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 3- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 4</h2>
      <audio [ngClass]="{'active': active_track == 4 }" #track4 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 4- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 5</h2>
      <audio [ngClass]="{'active': active_track == 5 }" #track5 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 5- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 6</h2>
      <audio [ngClass]="{'active': active_track == 6 }" #track6 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 6- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 7</h2>
      <audio [ngClass]="{'active': active_track == 7 }" #track7 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 7- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 8</h2>
      <audio [ngClass]="{'active': active_track == 8 }" #track8 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 8- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
    <div>
      <h2>Track 9</h2>
      <audio [ngClass]="{'active': active_track == 9 }" #track9 controls>
        <source src="https://45sna.com/archivos/mp3/Antigona/Anti 9- Wallace Mazuko.mp3" type="audio/mpeg">
      </audio>
    </div>
  </div>
  </div>
</div>
