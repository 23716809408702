/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./postit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "ng-inline-svg/lib/svg-cache.service";
import * as i5 from "ng-inline-svg/lib/inline-svg.config";
import * as i6 from "@angular/common/http";
import * as i7 from "ng-inline-svg/lib/inline-svg.directive";
import * as i8 from "ng-inline-svg/lib/inline-svg.service";
import * as i9 from "angular2-draggable";
import * as i10 from "../pipes/keep-html.pipe";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../pipes/translate.pipe";
import * as i13 from "../google.services";
import * as i14 from "../app.service";
import * as i15 from "../pipes/translate-html.pipe";
import * as i16 from "../lightbox/lightbox.component.ngfactory";
import * as i17 from "../lightbox/lightbox.component";
import * as i18 from "./postit.component";
import * as i19 from "../wpbackend.service";
var styles_PostitComponent = [i0.styles];
var RenderType_PostitComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostitComponent, data: {} });
export { RenderType_PostitComponent as RenderType_PostitComponent };
function View_PostitComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "contenedor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "streaming"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.acf.titulo_de_streaming; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.acf.url_de_streaming)); _ck(_v, 2, 0, currVal_1); }); }
function View_PostitComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, ("/blog/" + _v.parent.parent.context.$implicit.slug)); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), _v.parent.parent.context.$implicit.title.rendered)))); _ck(_v, 3, 0, currVal_3); }); }
function View_PostitComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.acf.link_postit; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 1), _v.parent.parent.context.$implicit.title.rendered)))); _ck(_v, 1, 0, currVal_1); }); }
function View_PostitComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "thumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit._embedded["wp:featuredmedia"]["0"].source_url; _ck(_v, 1, 0, currVal_0); }); }
function View_PostitComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_8)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.$implicit._embedded["wp:featuredmedia"]; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.acf.link_postit; _ck(_v, 0, 0, currVal_0); }); }
function View_PostitComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "thumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showLightbox(_v.parent.parent.context.$implicit._embedded["wp:featuredmedia"]["0"].source_url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit._embedded["wp:featuredmedia"]["0"].source_url; _ck(_v, 1, 0, currVal_0); }); }
function View_PostitComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "contenedor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_5)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 2, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.show_postit = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.SVGCacheService, i4.SVGCacheService, [[2, i3.APP_BASE_HREF], [2, i3.PlatformLocation], [2, i5.InlineSVGConfig], i6.HttpClient, i1.RendererFactory2]), i1.ɵdid(8, 737280, null, 0, i7.InlineSVGDirective, [i1.ElementRef, i1.ViewContainerRef, i1.ComponentFactoryResolver, i4.SVGCacheService, i1.Renderer2, i8.InlineSVGService, i1.PLATFORM_ID], { inlineSVG: [0, "inlineSVG"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "division"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "excerpt"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(11, 1), i1.ɵppd(12, 1), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_7)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_9)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.acf.link_postit; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.acf.link_postit; _ck(_v, 5, 0, currVal_1); var currVal_2 = "../assets/images/close.svg"; _ck(_v, 8, 0, currVal_2); var currVal_4 = _v.parent.context.$implicit.acf.link_postit; _ck(_v, 15, 0, currVal_4); var currVal_5 = (_v.parent.context.$implicit._embedded["wp:featuredmedia"] && !_v.parent.context.$implicit.acf.link_postit); _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 13).transform(i1.ɵunv(_v, 10, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent.parent.parent, 2), i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.content.rendered)))))); _ck(_v, 10, 0, currVal_3); }); }
function View_PostitComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["ngDraggable", ""]], null, [[null, "mousedown"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseDown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4931584, null, 0, i9.AngularDraggableDirective, [i1.ElementRef, i1.Renderer2], { ngDraggable: [0, "ngDraggable"] }, null), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "post_single": 0, "has_streaming": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 3, 0, true, _v.context.$implicit.acf.activar_streaming); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.acf.activar_streaming; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_v.context.$implicit.acf.activar_streaming; _ck(_v, 7, 0, currVal_3); }, null); }
function View_PostitComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "posts_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PostitComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.EscapeHtmlPipe, [i11.DomSanitizer]), i1.ɵpid(0, i12.TranslatePipe, [i13.GoogleService, i14.AppService]), i1.ɵpid(0, i15.TranslatehtmlPipe, [i13.GoogleService, i14.AppService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostitComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-lightbox", [], null, [[null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closed" === en)) {
        var pd_0 = ((_co.show_lightbox = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_LightboxComponent_0, i16.RenderType_LightboxComponent)), i1.ɵdid(6, 638976, null, 0, i17.LightboxComponent, [], { opened: [0, "opened"], seturl: [1, "seturl"] }, { closed: "closed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show_postit; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.show_lightbox; var currVal_2 = _co.lightbox_url; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_PostitComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-postit", [], null, null, null, View_PostitComponent_0, RenderType_PostitComponent)), i1.ɵdid(1, 114688, null, 0, i18.PostitComponent, [i19.WpbackendService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostitComponentNgFactory = i1.ɵccf("app-postit", i18.PostitComponent, View_PostitComponent_Host_0, {}, {}, []);
export { PostitComponentNgFactory as PostitComponentNgFactory };
