import { Component, HostListener, EventEmitter, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-cursores',
  templateUrl: './cursores.component.html',
  styleUrls: ['./cursores.component.scss']
})
export class CursoresComponent implements OnInit {
  virtualMouse:any;
  cursor:string = environment.apiurl+'/assets/images/cursor.svg';
  touchIdentifier:any;

  constructor(
    private socket: Socket
  ) { }

  ngOnInit() {
    this.startMice();

    this.socket.on('mouse update',(mouse) => {
      this.virtualMouse.move(mouse.id, mouse.pos);
    });

    this.socket.on('mouse disconnect', (mouse) => {
      // console.log("mouse disconnected: ", mouse.id);
      // this.virtualMouse.remove(mouse.id);
    });

    window.addEventListener('mousemove', (e) => { this.onMouseMove(e) });
  }

  onMouseMove(e){
    // console.log("mousemove", e);
    let x = 0;
    let y = 0;
    if( e.type == "touchmove"){
      e.preventDefault();
      if( e.changedTouches[0].identifier == this.touchIdentifier ){
        x = e.changedTouches[0].pageX;
        y = e.changedTouches[0].pageY;
      }else if(e.changedTouches[1] && e.changedTouches[1].identifier == this.touchIdentifier){
        x = e.changedTouches[1].pageX;
        y = e.changedTouches[1].pageY;
      }
    }else{
      x = e.clientX;
      y = e.clientY;
    }
    this.socket.emit("mouse movement", { pos: { x: x, y: y, cursor: this.cursor } });
  }

  startMice(){

    this.virtualMouse = {
      // moves a cursor with corresponding id to position pos
      // if cursor with that id doesn't exist we create one in position pos
      move: (id, pos) => {
        let cursor = document.getElementById('cursor-' + id);
        if (!cursor) {
          // console.log("Mouse connected: ", id );
          cursor = document.createElement('img');
          cursor.className = 'virtualMouse';
          cursor.id = 'cursor-' + id;
          cursor.setAttribute("src", pos.cursor );
          cursor.style.position = 'fixed';
          cursor.style.zIndex = "99999";
          document.body.appendChild(cursor);
        }
        cursor.style.maxWidth = '23px';
        cursor.style.left = pos.x + 'px';
        cursor.style.top = pos.y + 'px';
        cursor.setAttribute("src", pos.cursor );
      },

      // remove cursor with corresponding id
      remove: (id) => {
        let cursor = document.getElementById('cursor-' + id);
        // console.log("Cursor: ", cursor );
        // console.log("ID Cursor: ", id );
        if( cursor ){
          cursor.remove();
        }
      },

      removeAll:  () => {
        let paras = document.getElementsByClassName('virtualMouse');

        while(paras[0]) {
            paras[0].parentNode.removeChild(paras[0]);
        }​
      }
    }
  }

}
