

<div id="persiana_container" [ngClass]="{'open': open }">
  <div id="persiana_content" *ngIf="open">
    <div id="cartel">
     <img src="../../assets/images/05.svg" /> <br>
    <span> gire el dispositivo en<br> posición horizontal</span> <br> <br>
   <span> turn your phone<br> sideways</span>


    </div>
    <app-intro *ngIf="proyecto_slug == 'intro'"></app-intro>
    <app-antigona *ngIf="proyecto_slug == 'anti'"></app-antigona>
    <app-birthday *ngIf="proyecto_slug == 'birthday'"></app-birthday>
    <app-waitingroom *ngIf="proyecto_slug == 'waitingroom'"></app-waitingroom>
    <app-seneb *ngIf="proyecto_slug == 'seneb'"></app-seneb>
    <app-mondrigo *ngIf="proyecto_slug == 'mondrigo'"></app-mondrigo>
    <app-cine *ngIf="proyecto_slug == 'cine-continuado'"></app-cine>
    <app-motta *ngIf="proyecto_slug == 'cuerpo-celeste'"></app-motta>
    <app-esquina *ngIf="proyecto_slug == 'sin-titulo'"></app-esquina>
	<app-agua *ngIf="proyecto_slug == 'agitando-agua'"></app-agua>
  </div>
  <div id="persiana_handle" (click)="toggle()">
    <div class="handle_inner">
      <img class="persiana_abre" src="/assets/images/persiana.png" >
      <span *ngIf="open && this.proyecto_activo > 0" class="prev" (click)="prev($event)">&lt; anterior proyecto</span>
      <span>Redirección (archivo / arquivo / archive) <span *ngIf="upcoming === false" [innerHTML]="titles[proyecto_slug]"></span><span *ngIf="upcoming !== false" [innerHTML]="titles[ proyectos[ upcoming ] ] + ' - ' + countdown"></span></span>
      <span *ngIf="open && this.proyecto_activo < this.proyectos.length-1 && this.proyecto_activo != 0" class="next" (click)="next($event)">siguiente proyecto &gt;</span>
      <span *ngIf="open && this.proyecto_activo == 0" class="next" (click)="next($event)">iniciar / start &gt;</span>
    </div>
  </div>
</div>
