import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.scss']
})

export class StreamingComponent implements OnInit {
  posts$: Observable<any[]>;
  categories$: Observable<any[]>;
  urlArray:any = [];
  posts:any  = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.posts$ = this.wp.getList('streaming');
    this.categories$ = this.wp.getTerms('tipovideo');

  }

  ngOnInit() {
    this.appService.set_global_color('negro pages streaming');
  }

  streamingURL( url ){
    let streaming_enc_url = encodeURI( url );
    let streaming_enc_url_2 = "https://www.facebook.com/plugins/video.php?href="+streaming_enc_url+"&width=216&show_text=false&appId=471701346240583&height=122"
    return this.sanitizer.bypassSecurityTrustResourceUrl( streaming_enc_url_2 );
  }

  streamingYoutubeURL( url ){
    return this.sanitizer.bypassSecurityTrustResourceUrl( url );
  }

  SetStreamingURL( url, index, type ){
    if( type == 'YouTube' ){
      let embed = this.streamingYoutubeURL( url );
      if( this.urlArray[ index ] ){
        return false;
      }else{
        console.log( "Stream Youtube: ", embed );
        this.urlArray[ index ] = embed;
        return false;
      }
    }else{
      let embed = this.streamingURL( url );
      if( this.urlArray[ index ] ){
        return false;
      }else{
        console.log( "Stream: ", embed );
        this.urlArray[ index ] = embed;
        return false;
      }
    }
  }

}
