import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  visible:boolean = false;
  show_form:boolean = false;
  interval:any;
  clicks:any = 0;
  messages:Array<any> = [
    'No estamos en un lugar privilegiado en el universo.',
    'El universo es indiferente a lo que hagas.',
    'Cuando eres feliz al universo le da igual, cuando eres infeliz también.',
    'Tu lugar en el universo es insignificante.',
    'No eres especial.',
    'Nadie conspira contra ti.',
    'No tienes un objetivo específico.',
    'A escala planetaria, tú eres intercambiable con cualquier otro ser vivo.',
    'Cuando naciste nada cambió en el universo, cuando mueras tampoco va a pasar.'
  ];
  messages_shown:number = 0;

  constructor() { }

  ngOnInit() {
    this.messages = this.shuffle( this.messages );

    window.addEventListener('click', (e) => { this.clicked(e) });
  }

  toggle(){
    this.visible = !this.visible;
  }

  clicked( e ){
    if( !this.visible ){
      this.clicks = this.clicks + 1;
      if( this.clicks > 11 ){
        if( !this.visible ){
          this.updateMessage();
          this.toggle();
          this.clicks = 0;
          // console.log("Click count reset");
        }
      }
    }
    // console.log("Registered click for Popup. ", this.clicks );
  }

  updateMessage(){
    this.messages_shown = this.messages_shown+1;
    if( this.messages_shown > this.messages.length ){
      this.messages = this.shuffle( this.messages );
      this.messages_shown = 1;
    }
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  viajar(){
	  this.visible = false;
	  this.show_form = true;
  }

  cerrar_form(){
	  this.visible = false;
	  this.show_form = false;
  }

}
