import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgxUploaderModule } from 'ngx-uploader';

import { WpbackendService } from './wpbackend.service';

import { environment } from '../environments/environment';
import { ExposicionComponent } from './exposicion/exposicion.component';
import { TimelineComponent } from './timeline/timeline.component';

import { InlineSVGModule } from 'ng-inline-svg';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RevesComponent } from './reves/reves.component';
import { RevesEnComponent } from './revesen/reves.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { BlogComponent } from './blog/blog.component';
import { SingleComponent } from './blog/single/single.component';

import { KeysPipe } from './pipes/keys.pipe';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslatehtmlPipe } from './pipes/translate-html.pipe';
import { StreamingComponent } from './streaming/streaming.component';
import { SingleStreamingComponent } from './streaming/single/single.component';

import { PageComponent } from './page/page.component';
import { ProponemosComponent } from './proponemos/proponemos.component';

import { GoogleService, GoogleObj } from './google.services';
import { GtagModule } from 'angular-gtag';
import { CookieService } from 'ngx-cookie-service';

import { PersianaComponent } from './persiana/persiana.component';
import { AntigonaComponent } from './persiana/antigona/antigona.component';
import { BirthdayComponent } from './persiana/birthday/birthday.component';
import { WaitingroomComponent } from './persiana/waitingroom/waitingroom.component';
import { SenebComponent } from './persiana/seneb/seneb.component';
import { MondrigoComponent } from './persiana/mondrigo/mondrigo.component';
import { CineComponent } from './persiana/cine/cine.component';
import { MottaComponent } from './persiana/motta/motta.component';
import { EsquinaComponent } from './persiana/esquina/esquina.component';
import { DeboraComponent } from './debora/debora.component';

import { AngularDraggableModule } from 'angular2-draggable';
import { CursoresComponent } from './cursores/cursores.component';

import { TooltipModule } from 'ng2-tooltip-directive';
import { NewscolumnComponent } from './newscolumn/newscolumn.component';
import { PostitComponent } from './postit/postit.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { ColumnasComponent } from './columnas/columnas.component';
import { SedesComponent } from './sedes/sedes.component';
import { VideoComponent } from './video/video.component';
import { LightboxComponent } from './lightbox/lightbox.component';

import { LivesearchPipe } from './pipes/filter.pipe';
import { FuturoComponent } from './futuro/futuro.component';
import { PopupComponent } from './popup/popup.component';
import { CuadernosComponent } from './cuadernos/cuadernos.component';
import { MapaComponent } from './mapa/mapa.component';
import { OddhorizonsComponent } from './oddhorizons/oddhorizons.component';
import { AguaComponent } from './persiana/agua/agua.component';
import { IntroComponent } from './persiana/intro/intro.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pan': {pointers: 2}
  };
}

const config: SocketIoConfig = { url: 'https://45sna.com/', options: {'path' : '/socketio/socket.io'} };

@NgModule({
  declarations: [
    AppComponent,
    WhiteboardComponent,
    ExposicionComponent,
    TimelineComponent,
    RevesComponent,
    RevesEnComponent,
    CalendarioComponent,
    BlogComponent,
    KeysPipe,
    TranslatePipe,
    TranslatehtmlPipe,
    EscapeHtmlPipe,
    LivesearchPipe,
    SingleComponent,
    ProponemosComponent,
    StreamingComponent,
	SingleStreamingComponent,
    PageComponent,
    ProponemosComponent,
    CursoresComponent,
    NewscolumnComponent,
    PostitComponent,
    JackpotComponent,
    ColumnasComponent,
    SedesComponent,
    VideoComponent,
    LightboxComponent,
    FuturoComponent,
    PopupComponent,
    CuadernosComponent,
    MapaComponent,
    PersianaComponent,
    AntigonaComponent,
    BirthdayComponent,
    WaitingroomComponent,
    SenebComponent,
    MondrigoComponent,
    CineComponent,
    MottaComponent,
    EsquinaComponent,
    DeboraComponent,
    OddhorizonsComponent,
    AguaComponent,
    IntroComponent
  ],

  imports: [
    AngularDraggableModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    ColorPickerModule,
    NgxUploaderModule,
    HttpClientModule,
    TooltipModule,
    InlineSVGModule.forRoot(),
    GtagModule.forRoot({ trackingId: 'UA-155042145-1', trackPageviews: true })
  ],
  providers: [
    WpbackendService,
    AppService,
    GoogleService,
    CookieService,
    {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: MyHammerConfig
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
