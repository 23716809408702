<div id="portada_container" *ngIf="!playing">
  <div class="portada" (click)="start()"><img src="assets/images/mondrigo/portada.svg"></div>
</div>
<div id="mondrigo_container">
  <div id="player_mondrigo">
    <!-- Camara -->
    <!-- <h1>{{ camara_activa }} - {{ camaras[ camara_activa ].tipo }}</h1> -->
    <div id="yt-container" [ngClass]="{'active_youtube': camaras[ camara_activa ].tipo == 'youtube' }" ><div id="yt-player"></div></div>
    <div id="jpg-player" *ngIf="camaras[ camara_activa ].tipo == 'jpg' || camaras[ camara_activa ].tipo == 'mjpg'">
      <img *ngIf="camaras[ camara_activa ].tipo == 'jpg'" [src]="camaras[ camara_activa ].src + '?t=' + time"/>
      <img *ngIf="camaras[ camara_activa ].tipo == 'mjpg'" [src]="camaras[ camara_activa ].src"/>
    </div>
  </div>
  <div id="poema_mondrigo">
    <span id="frase_1" [innerHTML]="frase_1"></span>
    <span id="frase_1" [innerHTML]="frase_2"></span>
  </div>
  <div class="navigation">
    <div (click)="prev()" class="flechas left"><img src="assets/images/mondrigo/flecha_left.svg"></div>
    <div (click)="next()" class="flechas right"><img src="assets/images/mondrigo/flecha_right.svg"></div>
  </div>
</div>
