import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  lang:string = 'es';
  constructor() { }

  ngOnInit() {
  }

  activateLang( lang:string ){
    this.lang = lang;
  }

}
