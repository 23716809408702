/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./debora.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./debora.component";
var styles_DeboraComponent = [i0.styles];
var RenderType_DeboraComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeboraComponent, data: {} });
export { RenderType_DeboraComponent as RenderType_DeboraComponent };
function View_DeboraComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["id", "video_debora"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "video_flotante": 0, "visible": 1 }), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["culpable", 1]], null, 0, "video", [["muted", ""], ["src", "https://45sna.com/videos/SNA_Debora_orange.mp4"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.play() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.visible); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DeboraComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { culpable: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeboraComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DeboraComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-debora", [], null, null, null, View_DeboraComponent_0, RenderType_DeboraComponent)), i1.ɵdid(1, 114688, null, 0, i3.DeboraComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeboraComponentNgFactory = i1.ɵccf("app-debora", i3.DeboraComponent, View_DeboraComponent_Host_0, {}, {}, []);
export { DeboraComponentNgFactory as DeboraComponentNgFactory };
