<ul class="tipovideo_list">
  <li *ngFor="let category of categories$ | async; let i=index;">
    <h2>{{ category.name }}</h2>
    <ul class="posts_list">
      <div class="li_container" *ngFor="let post of posts$ | async; let i=index;">
        <div class="li_inner_container" *ngIf="post._embedded">
          <li class="post_single" *ngIf="post._embedded['wp:term'][0][0].slug == category.slug">
            <h1 [attr.alt]="SetStreamingURL( post.acf.stream, i, post.acf.proveedor )">{{ i }}</h1>
            <iframe [src]="urlArray[ i ]" width="95%" height="350px" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
          </li>
        </div>
      </div>
    </ul>
  </li>
</ul>
