
<button type="button" (click)="toggle()" id="play_button" [innerHTML]="texto_boton"></button>

<div id="player" >
  <video #myVideo id="myvideo" width="100%" height="100%" autobuffer poster="../../../assets/images/seneb/seneb.jpg">
    <source src="http://45sna.com/videos/hypnosis_excite01.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
</div>



<!--

<div class="content">
  <div class="elementos">

    <div id="window" ngDraggable ngResizable  [rzAspectRatio]="aspectRatio" >
      <video controls="controls"   name="Intro" src="../../../assets/images/seneb/seneb.mp4"></video>
    </div>

    <div class="pendulo">
    </div>

    <div class="anillov" >
      <img src="../../assets/images/seneb/green_ring.gif">
    </div>

    <div id="sandscroll" class="sandtext">
     <div id="wrapper">

      <p>During hypnosis, it is possible to </p>
      <p>communicate directly with the subconscious mind,</p>
      <p>making hypnosis an extremely powerful and effective technique</p>
      <p>for permanent change.</p>
      <p>Through hypnosis, you can eliminate the</p>
      <p>thoughts, feelings, beliefs, fears, blocks and habits</p>
      <p>that no longer serve you and reprogram yourself</p>
      <p>for things you actually want.</p>
      <p>The mind is so much more powerful than we think.</p>

      </div>
    </div>
  </div>
</div> -->
