import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-debora',
  templateUrl: './debora.component.html',
  styleUrls: ['./debora.component.scss']
})
export class DeboraComponent implements OnInit {
  @ViewChild('culpable') culpable: any;
  active: boolean = false;
  visible: boolean = false;
  timeout:any;

  constructor() { }

  ngOnInit() {
    this.prepare();
  }

  prepare(){
    this.timeout = setTimeout( () => {
      console.log("Culpable de ser inocente iniciando");
      this.active = true;
      let wait = setTimeout( () => {
        this.visible = true;
        let after = setTimeout(() => {
          this.culpable.nativeElement.currentTime = 0;
          this.culpable.nativeElement.play();
          this.culpable.nativeElement.onended = () => { this.end() };
        }, 2000); // Esperar 2 segs de fade in
      }, 300); // esperar 300ms de render de elemento video sin opacidad
    }, 360000 ); // cada 6 minutos sucede
  }

  end(){
    this.visible = false;
    let after = setTimeout( () => {
      this.active = false;
      // this.prepare() // se repite cada dos minutos reiniciando
    }, 2000); // Esperar 2 segs de fade out
  }

  play(){
    this.culpable.nativeElement.currentTime = 0;
    this.culpable.nativeElement.play();
    this.culpable.nativeElement.onended = () => { this.end() };
  }

}
