import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-singlestreaming',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss']
})

export class SingleStreamingComponent implements OnInit {
  posts$: Observable<any[]>;
  active: any;
  streaming_url: any;

  constructor(
	  private route: ActivatedRoute,
      private router: Router,
      private wp: WpbackendService,
      private appService: AppService,
      private sanitizer: DomSanitizer
  ) {
	  this.posts$ = this.wp.getList('streaming');
  }

  ngOnInit() {
	 this.appService.set_global_color('jackpot negro home');
	 this.posts$.subscribe(
		 ( posts ) => {
			 this.active = posts[0];
			 this.streaming_url = this.streamingURL( this.active.acf.stream );

			 //console.log("Streaming active: ", this.active );
		 }
	 );
  }

  streamingURL( url ){
    let streaming_enc_url = encodeURI( url );
    let streaming_enc_url_2 = "https://www.facebook.com/plugins/video.php?href="+streaming_enc_url+"&width=auto&show_text=false&appId=471701346240583&height=270"
    // console.log( "Stream: ", url );
    return this.sanitizer.bypassSecurityTrustResourceUrl( streaming_enc_url_2 );
  }

}
