import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  pages$: Observable<any[]>;
  page:string;
  page$: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService
  ) {
    this.pages$ = this.wp.getList('pages');
  }

  ngOnInit() {
    this.appService.set_global_color('negro pages');
    this.route.url.subscribe(url =>{
         console.log("Url: ", url);
         this.page = this.route.snapshot.paramMap.get('id');
         console.log("Page: ", this.page);
         if( this.page != null ){
           this.getPage( this.page );
         }
    });
  }

  getPage( id ){
    console.log("Getting page: ", id);
    this.wp.getSingle( id, 'pages' ).subscribe( res => {
      this.page$ = res[0];
      console.log( "Got page: ", this.page$ );
      let pagecont = document.getElementsByClassName("page");

      if( pagecont.length > 0 ){
        pagecont[0].scrollTop = 0;
      }
      // window.scrollTo(0, 0);
      /*
      if( pagecont[0] ){
        pagecont[0].scrollTop = 0;
      } */
    });
  }

}
