import { Component, OnInit,  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-oddhorizons',
  templateUrl: './oddhorizons.component.html',
  styleUrls: ['./oddhorizons.component.scss']
})

export class OddhorizonsComponent implements OnInit {
  model:any;
  result:string;
  submitted:boolean = false;

  constructor( private http: HttpClient ) { }

  ngOnInit() {
    this.model = {}
  }

  onSubmit(){
    console.log("Submit form function: ", this.model );
    this.http.post('https://45sna.com/mailer.php', JSON.stringify(this.model)).subscribe(( response:any ) => {
      console.log("Email response: ", response );
      this.submitted = true;
      this.result = response.response;
    });
  }

}
