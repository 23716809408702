<div id="popup_container" *ngIf="visible">
  <div id="popup">
    <div id="popup_topbar">
      <span class="close_button" (click)="toggle()">X</span>
    </div>
    <div id="popup_content" [innerHTML]="current_frase"></div>
  </div>
</div>

<div id="motta">
  <div class="content" (click)="show()">

	<img [src]="prev_src" />
	<img [src]="src" class="visible"/>
	<img [src]="next_src" [ngClass]="{'visible': sec > 5, 'animated': sec > 3, 'future': true }" />

	<img [src]="reloj_prev_src" class="reloj" />
	<img [src]="reloj_src" class="visible reloj"/>
	<img [src]="reloj_next_src" [ngClass]="{'reloj': true, 'visible': sec > 5, 'animated': sec > 3, 'future': true }" />


  </div>
</div>
