<ul *ngIf='show_postit' class="posts_list">
  <li [ngClass]="{'post_single': true, 'has_streaming': post.acf.activar_streaming }" *ngFor="let post of content" ngDraggable>

	<div class="contenedor" *ngIf="post.acf.activar_streaming">
	  <h2 [innerHTML]="post.acf.titulo_de_streaming"></h2>
	  <div class="streaming" [innerHTML]="post.acf.url_de_streaming | keepHtml">
	  </div>
	</div>

    <div class="contenedor" *ngIf="!post.acf.activar_streaming">
       <h2>
		  <a *ngIf="!post.acf.link_postit" [routerLink]="['/blog/'+post.slug]">{{ post.title.rendered | translate | async }}</a>
		  <a *ngIf="post.acf.link_postit" [attr.href]="post.acf.link_postit" >{{ post.title.rendered | translate | async }}</a>
		  	<span class="close" (click)='show_postit = false' [inlineSVG]="'../assets/images/close.svg'"></span>
       		<div class="division"></div>
   		</h2>
    	<div class="excerpt" [innerHTML]="post.content.rendered | keepHtml | translatehtml | async"></div>
	    <a *ngIf="post.acf.link_postit" [attr.href]="post.acf.link_postit">
	      <div class="thumb" *ngIf="post._embedded['wp:featuredmedia']"><img [src]="post._embedded['wp:featuredmedia']['0'].source_url"/></div>
	    </a>
	    <div class="thumb" *ngIf="post._embedded['wp:featuredmedia'] && !post.acf.link_postit"><img (click)="showLightbox( post._embedded['wp:featuredmedia']['0'].source_url )" [src]="post._embedded['wp:featuredmedia']['0'].source_url"/></div>
	 </div>
  </li>
</ul>

<app-lightbox [opened]="show_lightbox" [seturl]="lightbox_url" (closed)="show_lightbox = false"></app-lightbox>
