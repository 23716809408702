import { Component, HostListener, EventEmitter, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { HttpClientModule } from '@angular/common/http';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppService } from '../app.service';
import * as $ from 'jquery';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.scss']
})
export class WhiteboardComponent implements OnInit, OnDestroy {
  posts$: Observable<any[]>;
  audio:any;
  canvas:any;
  context: any;
  current: any;
  drawing: boolean;
  drawn: boolean;
  total: number;
  virtualMouse:any;
  color:string = 'rgb(0,0,0)';
  cursor:string = environment.apiurl+'/assets/images/lapiz.svg';

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  show_gallery:boolean = false;
  uploading:boolean = false;
  uploaded:boolean = false;
  uploaded_file:string;
  subir:boolean = false;
  blinkClass:boolean = false;

  touchIdentifier:any;
  width:any;
  height:any;

  offset:any = {
    'x':0,
    'y':0,
    'max_x':0,
    'max_y':0
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private socket: Socket,
    private wp: WpbackendService,
    private http: HttpClientModule,
    private appService: AppService
  ) {
    this.options = { concurrency: 1, maxUploads: 1 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.posts$ = this.wp.getPosts();
  }

  ngOnInit() {
    this.appService.set_global_color('negro muro');

    this.canvas = document.getElementsByClassName('whiteboard')[0];
    this.context = this.canvas.getContext('2d');
    this.setSize();
    this.width = window.innerWidth*2;
    this.height = window.innerHeight*2;
    this.offset.x = 0; // - (window.innerWidth*2);
    this.offset.y = 0; // - (window.innerHeight*2);
    this.offset.max_x = window.innerWidth*1;
    this.offset.max_y = window.innerHeight*1;
    this.current = {
      color: 'black'
    };

    this.audio = new Audio();
    this.audio.src = "../../../assets/sound/camera-shutter-click-01.wav";
    this.audio.load();
    this.drawn = false;
    this.wp.getPosts().subscribe( (data) => {
      // console.log("Got initial posts: ", data );
      let post = data[ Math.floor( Math.random() * data.length)];
      // console.log("Auto load drawing: ", post);
      // this.draw( post.source_url );
      this.drawn = false;
    });

    this.drawing = false;
    // this.startMice();
    this.socket.on("drawing",(d) => this.onDrawingEvent(d) );

    // this.socket.on('mouse update',(mouse) => {
    //   this.virtualMouse.move(mouse.id, mouse.pos);
    // });

     $("#informacion").click(function(){
      $("#informacion_container").removeClass("hideInfo");
      $("#informacion_container").addClass("showInfo");
        console.log("showInfo");
      });
     $("#cerrar,#informacion_container").click(function(){
      $("#informacion_container").removeClass("showInfo");
      $("#informacion_container").addClass("hideInfo");
        console.log("hideInfo");
      });
  }

  ngOnDestroy(){
    // this.virtualMouse.removeAll();
    this.socket.removeListener('mouse update');
  }

  playAudio(){
    this.audio.play();
  }

  onPinch( e ){
    console.log("Pinch event: ", e);
  }

  draw( url ){
    console.log("Drawing in canvas: ", url );
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    let img = new Image();
    img.onload = () => {
       this.context.drawImage(img, 0, 0);
    };
    img.src = url;
  }

  download(event){
    console.log("Downloading screenshot");
    let dataURL = this.canvas.toDataURL('image/png');
    dataURL.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
    event.path[1].href = dataURL;
    //console.log( event.path[1] );
    //button.href = dataURL;
  }

  drawLine(x0, y0, x1, y1, color, emit){
    this.context.beginPath();
    if (!emit) {
      this.context.moveTo(x0, y0);
      this.context.lineTo(x1, y1);
    }else{
      this.context.moveTo(x0-this.offset.x, y0-this.offset.y);
      this.context.lineTo(x1-this.offset.x, y1-this.offset.y);
    }
    this.context.strokeStyle = color;
    this.context.lineWidth = 2;
    this.context.stroke();
    this.context.closePath();

    if (!emit) { return; }

    this.socket.emit('drawing', {
      x0: x0-this.offset.y,
      y0: y0-this.offset.y,
      x1: x1-this.offset.y,
      y1: y1-this.offset.y,
      color: color
    });
  }

  onMouseDown(e){
    console.log("mouse down: ", e);
    if( !this.drawn ){
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawn = true;
    }
    this.drawing = true;
    if( e.type == "touchstart"){
      this.touchIdentifier = e.changedTouches[0].identifier;
      this.current.x = e.changedTouches[0].pageX;
      this.current.y = e.changedTouches[0].pageY;
    }else{
      this.current.x = e.clientX;
      this.current.y = e.clientY;
    }
  }

  onMouseUp(e){
    // console.log("mouseup", e);
    if (!this.drawing) { return; }
    //this.drawLine(this.current.x, this.current.y, e.clientX, e.clientY, this.color, true);
    this.drawing = false;
    this.touchIdentifier = false;
  }

  onMouseMove(e){
    let x = 0;
    let y = 0;
    if( e.type == "touchmove"){
      e.preventDefault();
      if( e.changedTouches[0].identifier == this.touchIdentifier ){
        x = e.changedTouches[0].pageX;
        y = e.changedTouches[0].pageY;
      }else if(e.changedTouches[1] && e.changedTouches[1].identifier == this.touchIdentifier){
        x = e.changedTouches[1].pageX;
        y = e.changedTouches[1].pageY;
      }
    }else{
      x = e.clientX;
      y = e.clientY;
    }
    // this.socket.emit("mouse movement", { pos: { x: x, y: y, cursor: this.cursor } });
    if( e.targetTouches && e.targetTouches.length > 1 ){
      // this.current.x = x;
      // this.current.y = y;
      console.log("Too many touches: ", e.targetTouches.length );
      let change_x = x - this.current.x;
      console.log("change x", change_x);
      this.offset.x = this.offset.x + change_x
      let change_y = y - this.current.y;
      console.log("change y", change_y);
      this.offset.y = this.offset.y + change_y
      console.log( "New offset: ", this.offset );
      this.current.x = x;
      this.current.y = y;
      if( this.offset.x > 0 ){
        this.offset.x = 0;
      }
      if( this.offset.y > 0 ){
        this.offset.y = 0;
      }
      if( this.offset.x < (0-(this.offset.max_x+this.offset.max_x/2)) ){
        this.offset.x = 0-(this.offset.max_x+this.offset.max_x/2);
      }
      if( this.offset.y < (0-(this.offset.max_y+this.offset.max_y/2)) ){
        this.offset.y = 0-(this.offset.max_y+this.offset.max_y/2);
      }
      return false;
    }else{
      if (!this.drawing) { return; }
      this.drawLine(this.current.x, this.current.y, x, y, this.color, true);
      this.current.x = x;
      this.current.y = y;
    }
  }

  onColorUpdate(e){
    this.current.color = e.target.className.split(' ')[1];
  }

  setSize(){
    // create a temporary canvas obj to cache the pixel data //
    let temp_cnvs = document.createElement('canvas');
    let temp_cntx = temp_cnvs.getContext('2d');

    // set it to the new width & height and draw the current canvas data into it //
    temp_cnvs.width = this.canvas.width;
    temp_cnvs.height = this.canvas.height;
    temp_cntx.drawImage(this.canvas, 0, 0);

    // resize & clear the original canvas and copy back in the cached pixel data //

    this.canvas.height = window.innerHeight*2;
    this.canvas.width = window.innerWidth*2;

    this.context.height = window.innerHeight*2;
    this.context.width = window.innerWidth*2;

    this.width = window.innerWidth*2;
    this.height = window.innerHeight*2;

    this.context.drawImage(temp_cnvs, 0, 0);
  }

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i); }
    return new Blob([ab], { type: 'image/png' });
  }

  saveImage(){
    this.playAudio();
    this.blinkScreen();
    const fileInput = document.getElementById('file');
    let dataURL = this.canvas.toDataURL("image/png");
    let finalblob = this.dataURItoBlob(dataURL);

    this.canvas.toBlob( (blob) => {
      console.log("generated blob: ", finalblob);
      // fileInput._file = blob;
      // this.files.push( blob );
      // this.startUpload();

      let xhr = new XMLHttpRequest();
      let formData = new FormData();
      formData.append('file', blob, 'screenshot.png');
      xhr.open('POST', environment.apiurl+'uploader.php', true); //my url had the ID of the item that the blob corresponded to
      //xhr.setRequestHeader("Content-Type", "multipart/form-data");
      xhr.onload = (e) => {
        console.log("loaded e: ", e );
        this.blinkGallery();
      };
      xhr.send(formData);
    });
  }

  blinkScreen(){
    document.body.classList.add('white');
    let timeout = setTimeout( () => {
      document.body.classList.remove('white');
    }, 500 );
  }

  blinkGallery(){
    this.blinkClass = true;
    let timeout = setTimeout( () => {
      this.blinkClass = false;
    }, 500 );
  }
  // limit the number of events per second
  throttle(callback, delay) {
    let previousCall = new Date().getTime();
    return function() {
      let time = new Date().getTime();

      if ((time - previousCall) >= delay) {
        previousCall = time;
        callback.apply(null, arguments);
      }
    };
  }

  onDrawingEvent(data){
    // console.log("Drawing event");
    this.drawLine(data.x0 , data.y0, data.x1 , data.y1, data.color, false);
  }

  startMice(){

    this.virtualMouse = {
      // moves a cursor with corresponding id to position pos
      // if cursor with that id doesn't exist we create one in position pos
      move: function (id, pos) {
        let cursor = document.getElementById('cursor-' + id);
        if (!cursor) {
          cursor = document.createElement('img');
          cursor.className = 'virtualMouse';
          cursor.id = 'cursor-' + id;
          cursor.setAttribute("src", pos.cursor );
          cursor.style.position = 'absolute';
          document.body.appendChild(cursor);
        }
        cursor.style.maxWidth = '23px';
        cursor.style.left = pos.x + 'px';
        cursor.style.top = pos.y + 'px';
        cursor.setAttribute("src", pos.cursor );
      },

      // remove cursor with corresponding id
      remove: function (id) {
        let cursor = document.getElementById('cursor-' + id);
        cursor.parentNode.removeChild(cursor);
      },

      removeAll: function () {
        let paras = document.getElementsByClassName('virtualMouse');

        while(paras[0]) {
            paras[0].parentNode.removeChild(paras[0]);
        }​
      }
    }
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
          // uncomment this if you want to auto upload files when added
          // const event: UploadInput = {
          //   type: 'uploadAll',
          //   url: '/upload',
          //   method: 'POST',
          //   data: { foo: 'bar' }
          // };
          // this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          console.log("Added to queue: ", output.file );
          this.files.push(output.file);
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
          this.uploading = true;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        // The file is downloaded
        this.uploaded_file = output.file.response.media_details.sizes.thumbnail.source_url;
        this.cursor = output.file.response.media_details.sizes.thumbnail.source_url;
        this.uploading = false;
        this.uploaded = true;
        this.wp.getPosts();
        console.log("Uploaded: ", output.file.response.media_details.sizes.thumbnail.source_url );
        break;
    }
  }

  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: environment.apiurl+'uploader.php',
      method: 'POST',
      data: { foo: 'bar' }
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }
}
