/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cursores.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cursores.component";
import * as i3 from "ngx-socket-io/src/socket-io.service";
var styles_CursoresComponent = [i0.styles];
var RenderType_CursoresComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CursoresComponent, data: {} });
export { RenderType_CursoresComponent as RenderType_CursoresComponent };
export function View_CursoresComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CursoresComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cursores", [], null, null, null, View_CursoresComponent_0, RenderType_CursoresComponent)), i1.ɵdid(1, 114688, null, 0, i2.CursoresComponent, [i3.WrappedSocket], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CursoresComponentNgFactory = i1.ɵccf("app-cursores", i2.CursoresComponent, View_CursoresComponent_Host_0, {}, {}, []);
export { CursoresComponentNgFactory as CursoresComponentNgFactory };
