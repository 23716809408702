<div id="container">
  <div id="cuadernos">
    <div class="col">
      <div class="row">
          <h1> Lectura<br>colectiva</h1>
          <h3>
            Cuadernos de la violencia<br>
            Memorias de infancia en Villarrica y Sumapaz
          </h3>
          <h6>Número de páginas: 337</h6>
          <span>
          Este libro ha sido leído colectivamente a lo largo del 45SNA <em>El revés de la trama</em> en el marco de la curaduría «Lenguajes de la injuria» de Luisa Ungar. Se titula <em>Cuadernos de la violencia, memorias de infancia en Villarrica y Sumapaz</em> y fue escrito de noche por el campesino Jaime Jara Gómez. Más información sobre el libro en editorial Cajón de sastre: <a href="http://www.cajondesastre.com.co" target="_blank">www.cajondesastre.com.co</a>
          </span>
      </div>
    </div>
    <div class="col bgRight" >
      <div class="row">
        <div id="wrapper">
            <div class="table">
                <div class="tr">
                <div class="td" *ngFor="let number of numbers">
                  <audio controls preload='none' [attr.id]="'audio'+number" [attr.data-id]="number" (play)="reportplay($event)" (ended)="reportended($event, number)">
                    <source [src]="'https://45sna.com/audios/'+number+'.mp3'" type="audio/mpeg">
                  </audio>
                </div>
              </div>
            </div>
        </div>

    </div>


    </div>
  </div>
</div>
