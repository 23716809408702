/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jackpot.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../newscolumn/newscolumn.component.ngfactory";
import * as i5 from "../newscolumn/newscolumn.component";
import * as i6 from "../wpbackend.service";
import * as i7 from "../app.service";
import * as i8 from "./jackpot.component";
var styles_JackpotComponent = [i0.styles];
var RenderType_JackpotComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JackpotComponent, data: {} });
export { RenderType_JackpotComponent as RenderType_JackpotComponent };
function View_JackpotComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.colores[_v.context.$implicit.id]); _ck(_v, 1, 0, currVal_0); var currVal_4 = _ck(_v, 5, 0, "/artistas", _v.context.$implicit.slug); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.title.rendered; var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_JackpotComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "columna"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["columna_1", 1]], null, 2, "ul", [["id", "columna_1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.artistas; _ck(_v, 3, 0, currVal_0); }, null); }
function View_JackpotComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.colores[_v.context.$implicit.id]); _ck(_v, 1, 0, currVal_0); var currVal_4 = _ck(_v, 5, 0, "/artistas", _v.context.$implicit.slug); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.title.rendered; var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_JackpotComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "columna"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[2, 0], ["columna_2", 1]], null, 2, "ul", [["id", "columna_2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.artistas; _ck(_v, 3, 0, currVal_0); }, null); }
function View_JackpotComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.colores[_v.context.$implicit.id]); _ck(_v, 1, 0, currVal_0); var currVal_4 = _ck(_v, 5, 0, "/artistas", _v.context.$implicit.slug); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.title.rendered; var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_JackpotComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "columna"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[3, 0], ["columna_3", 1]], null, 2, "ul", [["id", "columna_3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_6)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.artistas; _ck(_v, 3, 0, currVal_0); }, null); }
function View_JackpotComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.colores[_v.context.$implicit.id]); _ck(_v, 1, 0, currVal_0); var currVal_4 = _ck(_v, 5, 0, "/artistas", _v.context.$implicit.slug); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.title.rendered; var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_JackpotComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "columna"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[4, 0], ["columna_4", 1]], null, 2, "ul", [["id", "columna_4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_8)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.artistas; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_JackpotComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { columna_1: 0 }), i1.ɵqud(671088640, 2, { columna_2: 0 }), i1.ɵqud(671088640, 3, { columna_3: 0 }), i1.ɵqud(671088640, 4, { columna_4: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-newscolumn", [], null, null, null, i4.View_NewscolumnComponent_0, i4.RenderType_NewscolumnComponent)), i1.ɵdid(5, 114688, null, 0, i5.NewscolumnComponent, [i3.ActivatedRoute, i3.Router, i6.WpbackendService, i7.AppService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["id", "jackpot_container"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["id", "jackpot"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startJackpot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_5)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JackpotComponent_7)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_0 = _co.active_columns[0]; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.active_columns[1]; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.active_columns[2]; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.active_columns[3]; _ck(_v, 15, 0, currVal_3); }, null); }
export function View_JackpotComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-jackpot", [], null, null, null, View_JackpotComponent_0, RenderType_JackpotComponent)), i1.ɵdid(1, 4308992, null, 0, i8.JackpotComponent, [i3.ActivatedRoute, i3.Router, i6.WpbackendService, i7.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JackpotComponentNgFactory = i1.ɵccf("app-jackpot", i8.JackpotComponent, View_JackpotComponent_Host_0, {}, {}, []);
export { JackpotComponentNgFactory as JackpotComponentNgFactory };
