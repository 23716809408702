/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./single.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./single.component";
import * as i4 from "@angular/router";
import * as i5 from "../../wpbackend.service";
import * as i6 from "../../app.service";
import * as i7 from "@angular/platform-browser";
var styles_SingleStreamingComponent = [i0.styles];
var RenderType_SingleStreamingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SingleStreamingComponent, data: {} });
export { RenderType_SingleStreamingComponent as RenderType_SingleStreamingComponent };
function View_SingleStreamingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["allowFullScreen", "true"], ["allowTransparency", "true"], ["frameborder", "0"], ["height", "100%"], ["scrolling", "no"], ["style", "border:none;overflow:hidden"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.streaming_url; _ck(_v, 0, 0, currVal_0); }); }
function View_SingleStreamingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "fbvideo_container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleStreamingComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active.acf.tipo == "Activo"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SingleStreamingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SingleStreamingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SingleStreamingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-singlestreaming", [], null, null, null, View_SingleStreamingComponent_0, RenderType_SingleStreamingComponent)), i1.ɵdid(1, 114688, null, 0, i3.SingleStreamingComponent, [i4.ActivatedRoute, i4.Router, i5.WpbackendService, i6.AppService, i7.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SingleStreamingComponentNgFactory = i1.ɵccf("app-singlestreaming", i3.SingleStreamingComponent, View_SingleStreamingComponent_Host_0, {}, {}, []);
export { SingleStreamingComponentNgFactory as SingleStreamingComponentNgFactory };
