import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-futuro',
  templateUrl: './futuro.component.html',
  styleUrls: ['./futuro.component.scss']
})
export class FuturoComponent implements OnInit {
  fecha_inicial:any;
  fecha_final:any;
  grad_stop1_inicial:number;
  grad_stop1_actual:number;
  grad_stop1_final:number;
  grad_stop2_inicial:number;
  grad_stop2_actual:number;
  grad_stop2_final:number;
  grad_stop3_inicial:number;
  grad_stop3_actual:number;
  grad_stop3_final:number;
  final_gradient:any;
  porcentaje_actual:number;
  interval:any;
  finished:boolean = false;

  constructor() { }

  ngOnInit() {
	  this.grad_stop1_inicial = 65;
	  this.grad_stop2_inicial = 69;
	  this.grad_stop3_inicial = 100;
	  this.grad_stop1_final = 0;
	  this.grad_stop2_final = 0;
	  this.grad_stop3_final = 0;
	  this.fecha_inicial = '2019-09-06';
	  this.fecha_final = '2019-10-06';
	  this.interval = setInterval( () => {
		  this.calcularPorcentaje();
	  }, 1000);
  }

	calcularPorcentaje(){
		let now = new Date().getTime()/1000;
		let parts_inicial =this.fecha_inicial.split('-');
		let parts_final =this.fecha_final.split('-');
		// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
		// January - 0, February - 1, etc.
		let date_inicial = new Date(parts_inicial[0], parts_inicial[1] - 1, parts_inicial[2]).getTime()/1000;
		let date_final = new Date(parts_final[0], parts_final[1] - 1, parts_final[2]).getTime()/1000;
		// console.log( "Fecha Inicial: ", date_inicial );
		// console.log( "Fecha Ahora: ", now)
		// console.log( "Fecha Final: ", date_final );
		if( now < date_inicial ){
			this.porcentaje_actual = 0;
		}else if( now > date_final ){
			this.porcentaje_actual = 100;
			this.finished = true;
		}else{
			let total = date_final - date_inicial;
			let recorrido = now - date_inicial;
			this.porcentaje_actual = ( recorrido * 100 ) / total;
		}
		// console.log( "Porcentaje actual: ", this.porcentaje_actual );
		this.calcularGradiente();
	}

	calcularGradiente(){
		let rango1 = this.grad_stop1_final - this.grad_stop1_inicial;
		let rango2 = this.grad_stop2_final - this.grad_stop2_inicial;
		let rango3 = this.grad_stop3_final - this.grad_stop3_inicial;

		let valor1 = ( this.porcentaje_actual * rango1 ) / 100;
		let valor2 = ( this.porcentaje_actual * rango2 ) / 100;
		let valor3 = ( this.porcentaje_actual * rango3 ) / 100;

		this.grad_stop1_actual = this.grad_stop1_inicial + valor1;
		this.grad_stop2_actual = this.grad_stop2_inicial + valor2;
		this.grad_stop3_actual = this.grad_stop3_inicial + valor3;

		// console.log( "Grad 1 actual: ", this.grad_stop1_actual );
		// console.log( "Grad 2 actual: ", this.grad_stop2_actual );
		// console.log( "Grad 3 actual: ", this.grad_stop3_actual );

		this.setGradient();
	}

	setGradient(){
		this.final_gradient = this.getCssValuePrefix() +'linear-gradient(270deg, rgba(5,50,135,1)' + this.grad_stop1_actual + '% , rgba(5,50,135,1)'+ this.grad_stop2_actual +'% , rgba(0,0,0,1) '+ this.grad_stop3_actual +'%)';
	}

	getCssValuePrefix()
	{
	    let rtrnVal = '';//default to standard syntax
	    let prefixes = ['-o-', '-ms-', '-moz-', '-webkit-'];

	    // Create a temporary DOM object for testing
	    let dom = document.createElement('div');

	    for (let i = 0; i < prefixes.length; i++)
	    {
	        // Attempt to set the style
	        dom.style.background = prefixes[i] + 'linear-gradient(#000000, #ffffff)';

	        // Detect if the style was successfully set
	        if (dom.style.background)
	        {
	            rtrnVal = prefixes[i];
	        }
	    }

	    dom = null;

	    return rtrnVal;
	}

}
