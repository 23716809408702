import { EventEmitter, OnChanges, SimpleChange } from '@angular/core';
var LightboxComponent = /** @class */ (function () {
    function LightboxComponent() {
        this.open = false;
        this.closed = new EventEmitter();
    }
    Object.defineProperty(LightboxComponent.prototype, "opened", {
        set: function (opened) {
            this.open = opened;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(LightboxComponent.prototype, "seturl", {
        set: function (url) {
            this.url = url;
        },
        enumerable: true,
        configurable: true
    });
    ;
    LightboxComponent.prototype.ngOnInit = function () {
    };
    LightboxComponent.prototype.ngOnChanges = function (changes) {
        console.log("changes: ", changes);
    };
    LightboxComponent.prototype.close = function () {
        this.open = false;
        this.closed.emit(this.open);
    };
    return LightboxComponent;
}());
export { LightboxComponent };
