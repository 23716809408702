<ul class="exposiciones_list" *ngIf="!exposicion">
  <li *ngFor="let post of exposiciones$ | async" [ngClass]="{'expo_single': true, 'about_open': post.parent}" [id]="'expoid-'+post.id">
    <a [routerLink]="['/exposicion/'+post.slug]">{{ post.title.rendered | translate | async }}</a>
    <ul>
      <li *ngFor="let timecode of post.acf.timecodes"><a>{{ timecode.caption | translate | async }}</a></li>
    </ul>
    <a class="about_link" (click)="post.parent = !post.parent">Sobre el proyecto
      <span class="flecha">&#8595;</span></a>
    <div class="about_content" [innerHTML]="post.content.rendered | keepHtml">
    </div>
  </li>
</ul>

<div id="player" *ngIf="expo$">
  <img *ngIf="muted" id="nosound" src="../../assets/images/nosound.png" (click)="activar_sonido()" />

  <video #myVideo id="myvideo" width="100%" height="100%" autobuffer autoplay loop [muted]="muted">
    <source [src]="expo$.acf.url_de_video+'.mp4'" type="video/mp4">
    <source [src]="expo$.acf.url_de_video+'.webmhd.webm'" type="video/webm">
    <source [src]="expo$.acf.url_de_video+'.oggtheora.ogv'" type="video/ogg">
  Your browser does not support HTML5 video.</video>
</div>

<div class="expo" *ngIf="expo$">

  <h1 class="titulo">{{ expo$.title.rendered | translate  | async }}</h1>
  <div class="list">
    <ul>
      <li *ngFor="let timecode of expo$.acf.timecodes"><a (click)="seekTo( timecode.tiempo )">{{ timecode.caption | translate | async }}</a><br *ngIf="timecode.doble_espacio == 'Activar'"><br *ngIf="timecode.doble_espacio == 'Activar'"></li>
    </ul>
  </div>
</div>
