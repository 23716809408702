import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExposicionComponent } from './exposicion/exposicion.component';
import { TimelineComponent } from './timeline/timeline.component';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';
import { RevesComponent } from './reves/reves.component';
import { RevesEnComponent } from './revesen/reves.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { BlogComponent } from './blog/blog.component';
import { SingleComponent } from './blog/single/single.component';
import { ProponemosComponent } from './proponemos/proponemos.component';
import { StreamingComponent } from './streaming/streaming.component';
import { SingleStreamingComponent } from './streaming/single/single.component';
import { PageComponent } from './page/page.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { ColumnasComponent } from './columnas/columnas.component';
import { SedesComponent } from './sedes/sedes.component';
import { VideoComponent } from './video/video.component';
import { FuturoComponent } from './futuro/futuro.component';
import { CuadernosComponent } from './cuadernos/cuadernos.component';
import { MapaComponent } from './mapa/mapa.component';
import { OddhorizonsComponent } from './oddhorizons/oddhorizons.component';

const routes: Routes = [
  // Home
  { path: '',  component: JackpotComponent },
  { path: 'redireccion',  component: JackpotComponent },
  { path: 'redireccion/:id',  component: JackpotComponent },

  // Site
  { path: 'oddhorizons',  component: OddhorizonsComponent },
  { path: 'lineatiempo', redirectTo: 'linea-de-tiempo/' },
  { path: 'linea-de-tiempo', redirectTo: 'linea-de-tiempo/' },
  { path: 'linea-de-tiempo/', component: ColumnasComponent, data: { type: 'lineatiempo', slug: 'linea-de-tiempo' } },
  { path: 'linea-de-tiempo/:id', redirectTo: 'linea-de-tiempo/' },
  { path: 'futuro',  component: ExposicionComponent, data: { id: 'edificio-de-arquitectura' } },
  { path: 'en',  component: RevesEnComponent },
  { path: 'muro', component: WhiteboardComponent },
  { path: 'future',  redirectTo: 'futuro610' },
  { path: 'futuro610', component: FuturoComponent },
  { path: 'cuadernos-de-la-violencia', component: CuadernosComponent },
  { path: 'mapa', component: MapaComponent },
  { path: 'jackpot',  redirectTo: 'ruleta' },
  { path: 'ruleta',  component: JackpotComponent },
  { path: 'sedes',  component: SedesComponent },
  { path: 'artistas',  redirectTo: 'artistas/' },
  { path: 'artistas/:id',  component: ColumnasComponent, data: { type: 'artistas', slug: 'artistas' } },
  { path: 'catedras',  redirectTo: 'catedra-performativa/' },
  { path: 'catedra-performativa',  component: ColumnasComponent, data: { type: 'catedras', slug: 'catedras' } },
  { path: 'catedra-performativa/:id',  component: ColumnasComponent, data: { type: 'catedras', slug: 'catedras' } },
  { path: 'columnistas',  redirectTo: 'columnistas/' },
  { path: 'columnistas/:id',  component: ColumnasComponent, data: { type: 'columnistas', slug: 'columnistas' } },
  { path: 'equipo',  redirectTo: 'equipo-curatorial/' },
  { path: 'equipo-curatorial',  redirectTo: 'equipo-curatorial/' },
  { path: 'equipo-curatorial/:id',  component: ColumnasComponent, data: { type: 'equipo', slug: 'equipo-curatorial' } },
  { path: 'curadurias', redirectTo: 'exposiciones-y-proyectos/' },
  { path: 'curadurias/:id', redirectTo: 'exposiciones-y-proyectos/:id' },
  { path: 'exposiciones-y-proyectos', redirectTo: 'exposiciones-y-proyectos/' },
  { path: 'exposiciones-y-proyectos/:id', component: ColumnasComponent, data: { type: 'curadurias', slug: 'exposiciones-y-proyectos' } },
  { path: 'exposiciones',  component: ExposicionComponent },
  { path: 'exposicion/:id',  component: ExposicionComponent },
  { path: 'timelines',  redirectTo: '' },
  { path: 'timeline/:id',  redirectTo: '' },
  { path: 'reves',  component: RevesComponent },
  { path: 'calendario',  component: CalendarioComponent },
  { path: 'noticias',  redirectTo: 'prensa-y-critica/' },
  { path: 'noticias/:id',  redirectTo: 'prensa-y-critica/:id' },
  { path: 'prensa-y-critica',  component: ColumnasComponent, data: { type: 'posts', slug: 'noticias' } },
  { path: 'prensa-y-critica/:id',  component: ColumnasComponent, data: { type: 'posts', slug: 'noticias' } },
  { path: 'blog', redirectTo: 'noticias' },
  { path: 'blog/:id', redirectTo: 'noticias/:id' },
  { path: 'manifiesto',  component: ProponemosComponent },
  { path: 'proponemos', redirectTo: 'manifiesto' },
  { path: 'streaming', redirectTo: 'videos-y-streamings' },
  { path: 'videos-y-streamings',  component: StreamingComponent },
  { path: 'pages',  component: PageComponent },
  { path: 'prensa', redirectTo: 'comunicados' },
  { path: ':id',  component: PageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
