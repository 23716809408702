import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import * as $ from 'jquery';

declare var skrollr:any;

@Component({
  selector: 'app-revesen',
  templateUrl: './reves.component.html',
  styleUrls: ['./reves.component.scss']
})
export class RevesEnComponent implements OnInit {

  s:any;

  constructor(
    private appService: AppService,
  ) {
    document.body.style.overflowY = "auto";
  }

  ngOnInit() {
    this.appService.set_global_color('negro reves home');
    let windowHeight = $(window).innerHeight()
    //console.log( this.skrollr );

    setTimeout(() => {
        this.s = skrollr.init({
            render: function( data ){
              $('.skrollr-body').css('opacity', 1);
            },
            edgeStrategy: 'set',
            mobileDeceleration: 0.010,
            skrollrBody:'skrollr-body'
        });

        setTimeout(() => {
          console.log("scroll to top");
          // $(window).scrollTop(0);
          $('html').animate({scrollTop:0}, 1000);
          $('body').animate({scrollTop:0}, 1000);
        }, 1500);

      }, 100);

 $(window).scroll(() => {
    let $heightScrolled = $(window).scrollTop();
    //console.log("Window scrolled: ", $heightScrolled);
    //reves
    let $defaultHeight = 500;

      });
  }

  ngOnDestroy() {
    this.appService.set_global_color('negro');
    $('body').css('height','auto');
    $('body').css('background','white');
    $('body').css('overflow-y','hidden');
    this.s.destroy();
  }

  setHeight() {
    console.log('setting height');
    let windowHeight = $(window).innerHeight();
    $('.heightsection').css('min-height', windowHeight);
  };
 }
