import { Component, OnInit, ViewChildren, AfterViewInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-antigona',
  templateUrl: './antigona.component.html',
  styleUrls: ['./antigona.component.scss']
})
export class AntigonaComponent implements OnInit {
  online_count:any = 1;
  previous_active:any = 0;
  active_track:any = 1;
  start_time:any;
  current_time:any;
  elapsed_time:any; // max 2778
  interval:any;
  started:boolean = false;
  clicked:boolean = false;


  @ViewChildren('track1') track1;
  @ViewChildren('track2') track2;
  @ViewChildren('track3') track3;
  @ViewChildren('track4') track4;
  @ViewChildren('track5') track5;
  @ViewChildren('track6') track6;
  @ViewChildren('track7') track7;
  @ViewChildren('track8') track8;

  constructor(
    private socket: Socket
  ) { }

  ngOnInit() {
    // this.start_time = new Date(Date.UTC( 2019, 10, 6, 0, 0, 0, 0 )); // Los meses en javascript empiezan en 0
    // force start independent of time
    this.start_time = new Date();
    this.start_time.setMinutes(-1);
    this.updateElapsed();
	this.interval = setInterval(() => {
		this.updateElapsed();
	}, 1000);
  }

  clickinit(){
	  this.clicked = true;
	  this.updateActive();


	  this.socket.on('connected',(count) => {
		  if( count == 0 ){
			  this.online_count = 1;
		  }else{
			  this.online_count = count;
		  }
		  this.updateActive();
		  // console.log("Total connected: ", count);
	  });
	  this.sync();
  }

  ngAfterViewInit(){
  }

  updateElapsed(){
    // console.log("updating elapsed");
    this.current_time = new Date();
    this.elapsed_time = Math.round( (this.current_time.getTime() - this.start_time.getTime()) / 1000 );
    if( this.elapsed_time <= 0 ){
      this.elapsed_time = 0;
    }
    if( this.elapsed_time > 2778 ){
      // let veces = Math.floor( this.elapsed_time / 2778 );
      // console.log("Antigona ha pasado: ", veces );
      // this.elapsed_time = this.elapsed_time - ( 2778 * veces );
      this.start_time = new Date();
      this.elapsed_time = 0;
    }
    // console.log("Elapsed: ", this.elapsed_time );
  }

  sync(){
    if( this.elapsed_time <= 0 || this.elapsed_time >= 2778 ){
      // console.log("Over or not started");
      this.track1.first.nativeElement.pause();
      this.track2.first.nativeElement.pause();
      this.track3.first.nativeElement.pause();
      this.track4.first.nativeElement.pause();
      this.track5.first.nativeElement.pause();
      this.track6.first.nativeElement.pause();
      this.track7.first.nativeElement.pause();
      this.track8.first.nativeElement.pause();
      this.track1.first.nativeElement.muted = true;
      this.track2.first.nativeElement.muted = true;
      this.track3.first.nativeElement.muted = true;
      this.track4.first.nativeElement.muted = true;
      this.track5.first.nativeElement.muted = true;
      this.track6.first.nativeElement.muted = true;
      this.track7.first.nativeElement.muted = true;
      this.track8.first.nativeElement.muted = true;
    }else{
      // if( this.previous_active != this.active_track ){
        if( this.active_track != 1 ){
          if( this.track1 != undefined ){
            this.track1.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 2 ){
          if( this.track2 != undefined ){
            this.track2.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 3 ){
          if( this.track3 != undefined ){
            this.track3.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 4 ){
          if( this.track4 != undefined ){
            this.track4.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 5 ){
          if( this.track5 != undefined ){
            this.track5.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 6 ){
          if( this.track6 != undefined ){
            this.track6.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 7 ){
          if( this.track7 != undefined ){
            this.track7.first.nativeElement.muted = true;
          }
        }
        if( this.active_track != 8 ){
          if( this.track8 != undefined ){
            this.track8.first.nativeElement.muted = true;
          }
        }
        if( this.active_track == 1 ){
          if( this.track1 != undefined ){
            this.track1.first.nativeElement.currentTime = this.elapsed_time;
            this.track1.first.nativeElement.play();
            this.track1.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 2 ){
          if( this.track2 != undefined ){
            this.track2.first.nativeElement.currentTime = this.elapsed_time;
            this.track2.first.nativeElement.play();
            this.track2.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 3 ){
          if( this.track3 != undefined ){
            this.track3.first.nativeElement.currentTime = this.elapsed_time;
            this.track3.first.nativeElement.play();
            this.track3.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 4 ){
          if( this.track4 != undefined ){
            this.track4.first.nativeElement.currentTime = this.elapsed_time;
            this.track4.first.nativeElement.play();
            this.track4.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 5 ){
          if( this.track5 != undefined ){
            this.track5.first.nativeElement.currentTime = this.elapsed_time;
            this.track5.first.nativeElement.play();
            this.track5.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 6 ){
          if( this.track6 != undefined ){
            this.track6.first.nativeElement.currentTime = this.elapsed_time;
            this.track6.first.nativeElement.play();
            this.track6.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 7 ){
          if( this.track7 != undefined ){
            this.track7.first.nativeElement.currentTime = this.elapsed_time;
            this.track7.first.nativeElement.play();
            this.track7.first.nativeElement.muted = false;
          }
        }else if( this.active_track == 8 ){
          if( this.track8 != undefined ){
            this.track8.first.nativeElement.currentTime = this.elapsed_time;
            this.track8.first.nativeElement.play();
            this.track8.first.nativeElement.muted = false;
          }
        }
        this.started = true;
      //}else{
    //    console.log("Keeping track");
      //}
    }
  }

  updateActive(){
    if( this.started ){
      this.previous_active = this.active_track;
    }
    if( this.online_count == 1 ){
      this.active_track = 1;
    }else if( this.online_count == 2 ){
      this.active_track = 2;
    }else if( this.online_count == 3 ){
      this.active_track = 3;
    }else if( this.online_count == 4 ){
      this.active_track = 4;
    }else if( this.online_count == 5 ){
      this.active_track = 5;
    }else if( this.online_count == 6 ){
      this.active_track = 6;
    }else if( this.online_count == 7 ){
      this.active_track = 7;
    }else if( this.online_count == 8 ){
      this.active_track = 8;
    }else if( this.online_count > 8){
      this.active_track = 9;
    }
    this.sync();
  }

}
