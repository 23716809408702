import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { CursoresComponent } from './cursores/cursores.component';
import { WpbackendService } from './wpbackend.service';
import { Router, ActivatedRoute, ParamMap, NavigationStart } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { Gtag } from 'angular-gtag';

import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title:string = '45SNA';
  posts$: Observable<any[]>;
  online_count:any;
  inline_title:string = '../assets/images/01.svg';
  inline_title2:string = '../assets/images/01.svg';
  class:string = "negro reves home";
  current_url:any;
  burger_check:any = {
    'checked': false
  };
  openonce:boolean = false;
  streamingactive:boolean = false;
  counter:any = 0;
  current_lang:string = 'es';
  show_postit:boolean = false;
  tooltip_opts:any = {
    'placement': 'bottom',
    'theme': 'light',
    'z-index': 11000,
    'animation-duration': 100,
    'shadow': false
  }


  constructor(
    private appService: AppService,
    private backendService: WpbackendService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private socket: Socket,
    gtag: Gtag
  ){
    this.posts$ = this.backendService.getList('streaming');
  }

  ngOnInit(){
    this.socket.on('connected',(count) => {
      if( this.counter != 0 && count > this.online_count ){
        // console.log("Increasing count from: ", this.counter );
        this.counter = this.counter+1;
        // console.log("New counter: ", this.counter );
      }
      this.online_count = count;
      // console.log("Total connected: ", count);
    });

    this.posts$.subscribe(
 		 ( posts ) => {
       this.streamingactive = true;
 			 if( posts[0].acf.tipo == 'Activo'){ // && this.current_url == '/'
         this.streamingactive = true;
       }else{
         this.streamingactive = false;
       };

 			 console.log("Streaming active: ", posts[0].acf.tipo );
 		 }
 	 );

    this.router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        // console.log("APP URL: ", value); // your current route
        if( value.url == '/'){
          this.current_url == '/';
          if( !this.openonce ){
            this.openonce = true;
            // console.log("Opening menu: ");
            this.burger_check.checked = false;
			      this.show_postit = false;
          }
          // this.posts$ = this.backendService.getList('streaming');
          // this.router.events.unsubscribe();
        }else{
          this.current_url == value.url;
          this.streamingactive = false;
          if( this.show_postit ){
            this.show_postit = false;
          }
        }
      }
    });

    this.appService.global_color$.subscribe( (color) => {
      this.class = color;
      // console.log("Global color is now: ", color );
    });

    this.appService.language$.subscribe( (lang) => {
      this.current_lang = lang;
      // console.log("App lang now: ", this.current_lang );
    });

    this.randomTitle();
    this.randomTitle2();
    this.getCounter();

    this.appService.get_language();

    this.appService.test_translate();
  }

  setColor( color ){
    this.appService.set_global_color( color );
  }

  getCounter(){
    this.backendService.getCounter().subscribe( res => {
      this.counter = Number(res);
      console.log("Got counter: ", res );
    });
  }

   menuToggle(){

     this.burger_check.checked = !this.burger_check.checked;
     // let pagecont = document.body || document.documentElement;
     // pagecont.scrollTop = 0;
     // console.log( "checked: ", this.burger_check.checked );
  }

    randomTitle( ){
      let random_number = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
      this.inline_title = '../assets/images/0'+random_number+'.svg';
    }

  randomTitle2( ){
    let random_number = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
    this.inline_title2 = '../assets/images/logo_sna0'+random_number+'.svg';
  }

  onChangeLang($event) {
    this.appService.set_language( $event.target.value );
    location.reload();
  }

  onChangeLangReves($event) {
    this.appService.set_language( $event.target.value );
    if( $event.target.value == 'es'){
      window.location.assign('/');
    }else{
      window.location.assign('/en');
    }
  }

  goHome(){
    // console.log("gohome");
    window.location.assign('/');
  }

}
