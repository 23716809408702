/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./motta.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./motta.component";
var styles_MottaComponent = [i0.styles];
var RenderType_MottaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MottaComponent, data: {} });
export { RenderType_MottaComponent as RenderType_MottaComponent };
function View_MottaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["id", "popup_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["id", "popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["id", "popup_topbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "close_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["id", "popup_content"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.current_frase; _ck(_v, 5, 0, currVal_0); }); }
export function View_MottaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MottaComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["id", "motta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.show() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "visible"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "visible": 0, "animated": 1, "future": 2 }), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["class", "reloj"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["class", "visible reloj"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(13, { "reloj": 0, "visible": 1, "animated": 2, "future": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); var currVal_4 = _ck(_v, 8, 0, (_co.sec > 5), (_co.sec > 3), true); _ck(_v, 7, 0, currVal_4); var currVal_8 = _ck(_v, 13, 0, true, (_co.sec > 5), (_co.sec > 3), true); _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.prev_src; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.src; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.next_src; _ck(_v, 6, 0, currVal_3); var currVal_5 = _co.reloj_prev_src; _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.reloj_src; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.reloj_next_src; _ck(_v, 11, 0, currVal_7); }); }
export function View_MottaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-motta", [], null, null, null, View_MottaComponent_0, RenderType_MottaComponent)), i1.ɵdid(1, 114688, null, 0, i3.MottaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MottaComponentNgFactory = i1.ɵccf("app-motta", i3.MottaComponent, View_MottaComponent_Host_0, {}, {}, []);
export { MottaComponentNgFactory as MottaComponentNgFactory };
