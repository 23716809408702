<div *ngIf="!started && streamingactive" id="portada"  (click)="start()"  >
 <div class="content">
   AGITANDO EL AGUA DURMIENTE<br>
   <span>alejandra jaramillo</span><br>
   <img src="./../../assets/images/cine/player.png" >

 </div>
</div>
<div id="mask" *ngIf="show_mask && streamingactive"></div>
<div id="yt-player-container" [ngClass]="{'visible': streamingactive, 'invisible': !streamingactive }"><div id="yt-player"></div></div>
<div *ngIf="!streamingactive" id="pdfobject_container"><iframe class="embed-responsive-item" src="https://drive.google.com/file/d/18oY2q8XYQnsCLoEKnJUCQggMoA0evRYC/preview" width="640" height="480"></iframe></div>
