import { OnInit } from '@angular/core';
var WaitingroomComponent = /** @class */ (function () {
    function WaitingroomComponent() {
        this.started = false;
        this.api_loaded = false;
        this.show_controls = false;
        this.songs = [];
        this.titles = [];
        this.played = [];
        this.videos = [];
        this.marquee_display = false;
    }
    Object.defineProperty(WaitingroomComponent.prototype, "active", {
        set: function (active) {
            this.isActive = active;
            console.log("Changed state");
            if (!this.isActive) {
                this.audioplayer.nativeElement.stop();
                console.log("Stopped audio");
            }
        },
        enumerable: true,
        configurable: true
    });
    WaitingroomComponent.prototype.ngOnInit = function () {
        var _this = this;
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        var audioCtx = new AudioContext();
        this.songs = [
            'song1',
            'song2',
            'song3',
            'song4',
            'song5',
            'song6',
            'song7',
            'song8',
            'song9',
            'song10',
            'song11',
            'song12',
            'song13',
            'song14',
            'song15',
            'song16',
            'song17',
            'song18'
        ];
        this.titles = [
            'Agar Agar - I Am That Guy',
            'Everybody Loves The Sunshine - Roy Ayers Ubiquity',
            'Spaceships - Tank and the bangas',
            'France Gall - Les yeux bleus',
            'No sense song - Charles Chaplin',
            'El Vistado - Juana Molina',
            'Tindersticks - Trouble Every Day',
            'Angèle - Je veux tes yeux',
            'Daniel Norgren - Howling Around My Happy Home',
            'Daniel Norgren - Moonshine Got Me',
            'Julio Jaramillo - Desde que te fuiste',
            'Delinquent habits - Tres delincuentes',
            'CYRIUS - YOKOHAMA',
            'Guadalupe Pineda - Historia de una amor',
            'João Gilberto - Maria Ninguém',
            'Roberto Carlos - Qué será de ti'
        ];
        this.videos = [
            'IisHeJRXU3M',
            'RXvojVo8DDI',
            'W8TWCJ8ulAQ',
            'NVCi9yYwRCY',
            'z5F1a7_dsrs',
            'hWbt2eVCEe8',
            'xoLY8-WU-Wo',
            'ZOn7EVp9248',
            'eMe89SxutTU',
            'aaA8T2G79fk'
        ];
        this.current_video = 0;
        this.current_song = this.getRandom();
        window.onYouTubeIframeAPIReady = function () {
            console.log("API READY");
            _this.api_loaded = true;
            // this.createPlayer();
        };
    };
    WaitingroomComponent.prototype.setTimers = function () {
        var _this = this;
        this.timeout_init = setTimeout(function () {
            if (!_this.marquee_display) {
                _this.marquee_display = true;
                _this.timeout_hide = setTimeout(function () {
                    if (_this.marquee_display) {
                        _this.marquee_display = false;
                        _this.setTimers();
                    }
                }, 30000);
            }
        }, 45000);
    };
    WaitingroomComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!window.YT) {
            var doc = window.document;
            var playerApi = doc.createElement('script');
            playerApi.type = 'text/javascript';
            playerApi.src = 'https://www.youtube.com/iframe_api';
            doc.body.appendChild(playerApi);
        }
        this.audioplayer.nativeElement.addEventListener("ended", function () {
            _this.audioplayer.nativeElement.currentTime = 0;
            _this.randomAudio();
            console.log("ended");
        });
        // this.setInitialVideo();
    };
    WaitingroomComponent.prototype.playAudio = function (id) {
        this.audioplayer.nativeElement.src = "https://45sna.com/canciones/" + this.songs[id] + ".mp3";
        this.audioplayer.nativeElement.load();
        this.audioplayer.nativeElement.play();
        this.played.push(id);
    };
    WaitingroomComponent.prototype.randomAudio = function () {
        if (this.played.length == this.songs.length) {
            this.played = [];
        }
        var random = this.getRandom();
        if (this.played.indexOf(random) > -1) {
            this.randomAudio();
        }
        else {
            this.current_song = random;
            this.playAudio(this.current_song);
        }
    };
    WaitingroomComponent.prototype.getRandom = function () {
        var rand = Math.floor(Math.random() * this.songs.length - 1);
        return rand;
    };
    WaitingroomComponent.prototype.start = function () {
        console.log("Starting... ");
        if (window.YT) {
            this.started = true;
            this.createPlayer();
        }
        else {
            console.log("Can't find YT");
        }
    };
    WaitingroomComponent.prototype.createPlayer = function () {
        var _this = this;
        this.yt_player = new window.YT.Player('yt-player', {
            width: '560',
            height: '315',
            videoId: this.videos[this.current_video],
            playerVars: {
                autoplay: 0,
                iv_load_policy: '3',
                rel: '0',
                controls: '0',
                modestbranding: '1',
                fs: 0,
                playsinline: 1
            },
            events: {
                onReady: function (ev) {
                    console.log("Player ready");
                    _this.yt_player.playVideo();
                    _this.playAudio(_this.current_song);
                },
                onError: function (ev) {
                    console.log("skipping video with error");
                    _this.next();
                },
                onStateChange: function (ev) {
                    // console.log("State change: ", ev );
                    // console.log( "Today :", this.today.getDate() );
                    if (ev.data == 2) {
                        // this.yt_player.playVideo();
                        if (_this.yt_player.isMuted()) {
                            // this.yt_player.unMute();
                        }
                        else {
                            // this.yt_player.mute();
                        }
                    }
                    if (ev.data == 0) {
                        // this.updateCurrentVideo();
                    }
                    if (ev.data == 1) {
                        _this.show_controls = true;
                        _this.setTimers();
                    }
                }
            }
        });
    };
    WaitingroomComponent.prototype.prev = function () {
        console.log("Prev");
        this.current_video = this.current_video - 1;
        if (this.current_video < 0) {
            this.current_video = this.videos.length - 1;
        }
        this.yt_player.loadVideoById(this.videos[this.current_video]);
        this.randomAudio();
    };
    WaitingroomComponent.prototype.next = function () {
        console.log("Next");
        this.current_video = this.current_video + 1;
        if (this.current_video > this.videos.length - 1) {
            this.current_video = 0;
        }
        this.yt_player.loadVideoById(this.videos[this.current_video]);
        this.randomAudio();
    };
    WaitingroomComponent.prototype.randomSong = function () { };
    return WaitingroomComponent;
}());
export { WaitingroomComponent };
