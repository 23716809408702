import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-proponemos',
  templateUrl: './proponemos.component.html',
  styleUrls: ['./proponemos.component.scss']
})
export class ProponemosComponent implements OnInit {

  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService
  ) {
}

  ngOnInit() {
    this.appService.set_global_color('negro  ');
  }

}
