import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-seneb',
  templateUrl: './seneb.component.html',
  styleUrls: ['./seneb.component.scss']
})

export class SenebComponent implements OnInit {
  @ViewChild('myVideo') myVideo: any;
  player;
  texto_boton:string = 'Start Hypnosis';
  playing:boolean = false;

  constructor( ) {
 	 // console.log("TweenMax:", TweenMax );
  }

  toggle( ){
	  if( this.playing ){
		  this.myVideo.nativeElement.pause();
		  this.texto_boton = 'Start Hypnosis';
		  this.playing = false;
	  }else{
		  this.myVideo.nativeElement.play();
		  this.texto_boton = 'Stop Hypnosis';
		  this.playing = true;
	  }
  }

  ngOnInit() {

      if ( this.myVideo.nativeElement.play() == true ){
          this.myVideo.nativeElement.pause();
              // this.playButton = 'Start Hypnosis'

             }else{
               this.myVideo.nativeElement.pause();
               //this.playButton = 'Stop Hypnosis'

             }
      }
    // let playButton = document.getElementById("playButton");
    //let video = document.getElementById("video");


        //
        //
        // var playButton = document.getElementById("playButton");
        // var video = document.getElementById("video");
        //  playButton.addEventListener("click", function() {
        //
        //     console.log("play" );
        //   if (video.paused == true) {
        //     video.play();
        //      console.log("play:" );
        //     // Update the button text to 'Pause'
        //     playButton.innerHTML = "Start Hypnosis";
        //   } else {
        //     video.pause();
        //      console.log("pause:" );
        //
        //     // Update the button text to 'Play'
        //     playButton.innerHTML = "Stop Hypnosis";
        //   }
        // });


    // tweenlite text scroll
  //   let mouseY = 0;
  //   let followY = 0;
  //   let wh = window.innerHeight;
  //   let ease = 0.08;
  //   let tween = TweenMax.to("#wrapper", 1, { yPercent: 0, paused: false, z: 70 });
  //
  //   TweenMax.set("#wrapper p", { transformOrigin: "center center " });
  //
  //   window.addEventListener("mousemove", (e) => {
  //     mouseY = e.clientY;
  //   });
  //
  //   TweenMax.ticker.addEventListener("tick", () => {
  //     followY += (mouseY - followY) * ease;
  //     tween.progress(followY / wh);
  //
  //     let dy = (mouseY - followY) / 10;
  //     dy = Math.min(Math.max(dy, -15), 15);
  //     TweenMax.set("#wrapper p", { skewY: dy });
  //   });
  //
	// this.interval = setInterval( () => {
  //     this.incrementScroll();
  //   }, 35);

  }

  // incrementScroll(){
  //   if( this.scroll ){
  //     document.getElementById('sandscroll').scrollTop = document.getElementById('sandscroll').scrollTop+1
  //   }
  // }
