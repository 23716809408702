import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-persiana',
  templateUrl: './persiana.component.html',
  styleUrls: ['./persiana.component.scss']
})
export class PersianaComponent implements OnInit {
  open:boolean =  false;//false
  toggleable:boolean = true;
  proyecto_activo:number;
  proyecto_slug:string;
  proyectos:Array<any> = [];
  titles:Array<any> = [];
  starts:Array<any> = [];
  ends:Array<any> = [];
  openclose:Array<any> = [];
  toggleables:Array<any> = [];
  now:any;
  upcoming:any = false;
  countdown:any;
  current_url:string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.proyectos = [
      'intro',
      'anti',
      'birthday',
      'waitingroom',
      'seneb',
      'mondrigo',
      'cine-continuado',
      'cuerpo-celeste',
      'sin-titulo',
	    'agitando-agua'
    ];

    this.titles['intro'] = '';
    this.titles['antigona'] = '<em>Anti</em> de Wallace V. Masuko';
    this.titles['birthday'] = '<em>Having Someone’s Birthday Party</em> de Yoshinori Niwa';
    this.titles['waitingroom'] = '<em>Waiting Room</em> de Sebastián Múnera';
    this.titles['seneb'] = '<em>House of Seneb</em> de Alicia Mersy + Tabita Rezaire';
    this.titles['mondrigo'] = '<em>Buscando a ¡el móndrigo!</em> de David Escobar Parra';
    this.titles['cine-continuado'] = '<em>Cine continuado</em> de Iván Candeo';
    this.titles['cuerpo-celeste'] = '<em>Cuerpo celeste</em> de Aline Motta y Rafael Galante';
    this.titles['sin-titulo'] = '<em>Sem título</em> de Carlos Fajardo';
	  this.titles['agitando-agua'] = '<em>Agitando el agua durmiente</em> de Alejandra Jaramillo';
	  this.titles['archivo'] = "Archivo de obras de Redirección";

    this.starts['anti'] =  new Date(Date.UTC( 2019, 8, 14, 0, 0, 0, 0 ));
    this.ends['anti'] =  new Date(Date.UTC( 2019, 8, 14, 0, 46, 18, 0 ));
    this.openclose['anti'] = true;
    this.toggleables['anti'] = false;

    this.starts['birthday'] =  new Date(Date.UTC( 2019, 8, 14, 5, 0, 0, 0 ));
    this.ends['birthday'] =  new Date(Date.UTC( 2019, 8, 21, 5, 0, 0, 0 ));
    this.openclose['birthday'] = false;
    this.toggleables['birthday'] = true;

    this.starts['waitingroom'] =  new Date(Date.UTC( 2019, 8, 21, 5, 0, 0, 0 ));
    this.ends['waitingroom'] =  new Date(Date.UTC( 2019, 8, 29, 5, 0, 0, 0 ));
    this.openclose['waitingroom'] = false;
    this.toggleables['waitingroom'] = true;

    this.starts['seneb'] =  new Date(Date.UTC( 2019, 8, 29, 5, 0, 0, 0 ));
    this.ends['seneb'] =  new Date(Date.UTC( 2019, 9, 2, 5, 0, 0, 0 ));
    this.openclose['seneb'] = true;
    this.toggleables['seneb'] = true;

    this.starts['mondrigo'] =  new Date(Date.UTC( 2019, 9, 2, 5, 0, 0, 0 ));
    this.ends['mondrigo'] =  new Date(Date.UTC( 2019, 9, 3, 5, 0, 0, 0 ));
    this.openclose['mondrigo'] = true;
    this.toggleables['mondrigo'] = true;

    this.starts['seneb2'] =  new Date(Date.UTC( 2019, 9, 3, 5, 0, 0, 0 ));
    this.ends['seneb2'] =  new Date(Date.UTC( 2019, 9, 9, 5, 0, 0, 0 ));
    this.openclose['seneb2'] = false;
    this.toggleables['seneb2'] = true;

    this.starts['cine-continuado'] =  new Date(Date.UTC( 2019, 9, 9, 5, 0, 0, 0 ));
    this.ends['cine-continuado'] =  new Date(Date.UTC( 2019, 9, 16, 5, 0, 0, 0 ));
    this.openclose['cine-continuado'] = false;
    this.toggleables['cine-continuado'] = true;

    this.starts['cuerpo-celeste'] =  new Date(Date.UTC( 2019, 9, 16, 5, 0, 0, 0 ));
    this.ends['cuerpo-celeste'] =  new Date(Date.UTC( 2019, 9, 23, 5, 0, 0, 0 ));
    this.openclose['cuerpo-celeste'] = false;
    this.toggleables['cuerpo-celeste'] = true;

    this.starts['sin-titulo'] =  new Date(Date.UTC( 2019, 9, 23, 5, 0, 0, 0 ));
    this.ends['sin-titulo'] =  new Date(Date.UTC( 2019, 9, 30, 5, 0, 0, 0 ));
    this.openclose['sin-titulo'] = false;
    this.toggleables['sin-titulo'] = true;

	this.starts['agitando-agua'] =  new Date(Date.UTC( 2019, 9, 30, 5, 0, 0, 0 ));
    this.ends['agitando-agua'] =  new Date(Date.UTC( 2019, 10, 6, 0, 0, 0, 0 ));
    this.openclose['agitando-agua'] = false;
    this.toggleables['agitando-agua'] = true;

	this.starts['antigona2'] =  new Date(Date.UTC( 2019, 10, 6, 0, 0, 0, 0 ));
	this.ends['antigona2'] =  new Date(Date.UTC( 2019, 10, 6, 0, 46, 18, 0 ));
	this.openclose['antigona2'] = false;
	this.toggleables['antigona2'] = true;

    // this.check_schedule();

    //setInterval(()=>{
    //  this.check_schedule();
    //},12000);

    this.router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        console.log("PERSIANA URL: ", value); // your current route
        if( value.url == '/'){
          this.current_url == '/';
          // this.posts$ = this.backendService.getList('streaming');
          // this.router.events.unsubscribe();
        }else{
          this.current_url = value.url;
          console.log("Split: ", this.current_url.split('/')[1] );
          if( this.current_url.split('/')[1] == 'redireccion' ){
            if( this.current_url.split('/')[2] ){
              let proyecto = this.current_url.split('/')[2];
              console.log("Proyecto: ", proyecto);
              let index = this.proyectos.indexOf( proyecto );
              this.proyecto_activo = index;
              this.proyecto_slug = this.proyectos[ index ];
              this.setActivo( index );
            }
            this.open = true;
          }
        }
      }
    });
  }

  toggle(){
    if( this.toggleable ){
      this.open = !this.open;
      if( !this.proyecto_slug ){
        this.setActivo( 0 );
      }
    }
  }

  setActivo( index ){
    if( index == this.proyecto_activo ){
      console.log("Activo es igual a anterior");
      return;
    }else{
      this.proyecto_activo = index;
      this.proyecto_slug = this.proyectos[ index ];
      this.router.navigate(['/redireccion', this.proyecto_slug]);
    }
  }

  prev( $event ){
    console.log("Prev persiana");
    $event.stopPropagation();
    if( this.proyecto_activo > 0 ){
      this.setActivo( this.proyecto_activo - 1 );
      console.log("Persiana activo: ", this.proyecto_slug );
    }else{
      this.setActivo( this.proyectos.length - 1 );
    }
  }

  next( $event ){
    console.log("Next persiana");
    $event.stopPropagation();
    if( this.proyecto_activo < this.proyectos.length - 1 ){
      this.setActivo( this.proyecto_activo + 1 );
      console.log("Persiana activo: ", this.proyecto_slug );
    }else{
      this.setActivo( 0 );
    }
  }

  check_schedule(){
    this.now = new Date().getTime();
    //console.log("Checking schedule: ", this.now );
    if( this.now < this.starts['antigona'].getTime() ){
      //console.log("Upcoming: ", 0);
      let diff = this.starts['antigona'].getTime() - this.now;
      //console.log("In: ", diff );
      this.countdown = this.mtoms( diff );
      this.upcoming = 0;
      this.toggleable = false;
      this.open = false;
    }else if( this.now > this.starts['antigona'].getTime() && this.now < this.ends['antigona'].getTime() ){
      this.setActivo(0);
      this.upcoming = false;
      this.toggleable = this.toggleables['antigona'];
      if( this.open != this.openclose['antigona']){
        this.open = this.openclose['antigona'];
      }
    }else if( this.now < this.starts['birthday'].getTime() && this.now > this.ends['antigona'].getTime() ){
      // console.log("Upcoming: ", 1);
      let diff = this.starts['birthday'].getTime() - this.now;
      // console.log("In: ", diff );
      this.countdown = this.mtoms( diff );
      this.upcoming = 1;
      this.toggleable = false;
      this.open = false;
    }else if( this.now > this.starts['birthday'].getTime() && this.now < this.ends['birthday'].getTime() ){
      this.setActivo(3);
      this.upcoming = false;
      this.toggleable = true;
    }else if( this.now < this.starts['waitingroom'].getTime() && this.now > this.ends['birthday'].getTime() ){
      // console.log("Upcoming: ", 2);
      this.upcoming = 2;
      this.toggleable = false;
      this.open = false;

    }else if( this.now > this.starts['waitingroom'].getTime() && this.now < this.ends['waitingroom'].getTime() ){
      this.setActivo(2);
      this.upcoming = false;
    }else if( this.now < this.starts['seneb'].getTime() && this.now > this.ends['waitingroom'].getTime() ){
      console.log("Upcoming: ", 3);
      this.upcoming = 3;
    }else if( this.now > this.starts['seneb'].getTime() && this.now < this.ends['seneb'].getTime() ){
      this.setActivo(3);
      this.upcoming = false;
    }else if( this.now < this.starts['mondrigo'].getTime() && this.now > this.ends['seneb'].getTime() ){
      console.log("Upcoming: ", 4);
      this.upcoming = 4;
    }else if( this.now > this.starts['mondrigo'].getTime() && this.now < this.ends['mondrigo'].getTime() ){
      this.setActivo(4);
      this.upcoming = false;
    }else if( this.now < this.starts['seneb2'].getTime() && this.now > this.ends['mondrigo'].getTime() ){
      console.log("Upcoming: ", 3);
      this.upcoming = 3;
    }else if( this.now > this.starts['seneb2'].getTime() && this.now < this.ends['seneb2'].getTime() ){
      this.setActivo(3);
      this.upcoming = false;
    }
    else if( this.now < this.starts['cine-continuado'].getTime() && this.now > this.ends['seneb2'].getTime() ){
      console.log("Upcoming: ", 5);
      this.upcoming = 5;
    }else if( this.now > this.starts['cine-continuado'].getTime() && this.now < this.ends['cine-continuado'].getTime() ){
      this.setActivo(6); //(5)
      this.upcoming = false;
    }else if( this.now < this.starts['cuerpo-celeste'].getTime() && this.now > this.ends['cine-continuado'].getTime() ){
      console.log("Upcoming: ", 6);
      this.upcoming = 6;
    }else if( this.now > this.starts['cuerpo-celeste'].getTime() && this.now < this.ends['cuerpo-celeste'].getTime() ){
      this.setActivo(6);//6
      this.upcoming = false;
  }else if( this.now < this.starts['esquina'].getTime() && this.now > this.ends['cuerpo-celeste'].getTime() ){
      console.log("Upcoming: ", 7);
      this.upcoming = 7;
  }else if( this.now > this.starts['esquina'].getTime() && this.now < this.ends['esquina'].getTime() ){
      this.setActivo(7);
      this.upcoming = false;
  }else if( this.now < this.starts['agua'].getTime() && this.now > this.ends['esquina'].getTime() ){
        console.log("Upcoming: ", 8);
        this.upcoming = 8;
    }else if( this.now > this.starts['agua'].getTime() && this.now < this.ends['agua'].getTime() ){
        this.setActivo(8);
        this.upcoming = false;
	}else if( this.now < this.starts['antigona2'].getTime() && this.now > this.ends['agua'].getTime() ){
	        console.log("Upcoming: ", 0);
	        this.upcoming = 0;
	    }else if( this.now > this.starts['antigona2'].getTime() && this.now < this.ends['antigona2'].getTime() ){
			this.setActivo(0);
	        this.upcoming = false;
	        this.toggleable = this.toggleables['antigona'];
	        if( this.open != this.openclose['antigona']){
	          this.open = this.openclose['antigona'];
	        }
		}else if( this.now > this.ends['antigona2'].getTime() ){
  			this.setActivo(0);
  	        this.toggleable = true;
  	      }else{
            this.setActivo(0);
          }


  }

  mtoms(duration:any) {
    let seconds:any = Math.floor((duration / 1000) % 60);
    let minutes:any = Math.floor((duration / (1000 * 60)) % 60);
    let hours:any = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

}
