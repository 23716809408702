<div id="news_column" [ngClass]="{'show': show}">
  <a id="news_column_toggle" (click)="toggle()">
  <span>prensa y crítica</span></a>
  <!-- <h2>{{ "noticias" | translate | async }}</h2> -->
  <ul id="news_column_list">
    <li class="news_column_list_item" *ngFor="let post of news$ | async" [ngStyle]="{'background-color': post.acf.color_fondo  }">
      <div class="news_header">
        <h3 (click)="toggle()" [ngStyle]="{'color': post.acf.color_fuente  }" [innerHTML]="post.title.rendered | translate | async" [routerLink]="['/noticias/'+post.slug]"></h3>
        <h4 [ngStyle]="{'color': post.acf.color_fuente  }" [innerHTML]="post.acf.subtitulo | translate | async"  [routerLink]="['/noticias/'+post.slug]"></h4>
      </div>
      <div class="news_content">
        <div class="thumb" *ngIf="post._embedded['wp:featuredmedia']"><img [src]="post._embedded['wp:featuredmedia']['0'].source_url"/></div>
      </div>
      <!-- <div class="news_footer" [ngStyle]="{'color': post.acf.color_fuente  }"> -->
        <!-- <span *ngIf="post._embedded.author[0].name != 'lagente' && post._embedded.author[0].name != 'prensa' && post._embedded.author[0].name != 'mariana'">{{ "Por" | translate | async }}: <span class="author"> {{ post._embedded.author[0].name }}  </span></span><br /> -->
        <!--      <span class="tags">#{{ post._embedded["wp:term"][0][0].name | translate | async }}</span>
      </div> -->
    </li>
  </ul>
</div>
