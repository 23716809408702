<div id="oddhorizons_container">
  <div id="header_popup">
    <div id="maindiv">
      <div id="div1" class="">OddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizons
        <div id="div1flip" class="">OddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizons</div>
      </div>
      <div id="div2" class="">OddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizons
        <div id="div2flip" class="">OddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizonsOddHorizons</div>
      </div>
    </div>
  </div>
  <div id="dos" class="img_float">
      <img src="../assets/images/oddhorizons/02.jpg">
      <span>CONOCE TU INTERIOR</span>
  </div>
  <div id="ocho" class="img_float">
      <img src="../assets/images/oddhorizons/08.jpg">
      <span>CONOCE EL UNIVERSO</span>
  </div>
  <div id="cuatro" class="img_float">
      <img src="../assets/images/oddhorizons/04.jpg">
      <span>VIAJA CON NOSOTROS</span>
  </div>

  <form id="form" class="form1" (ngSubmit)="onSubmit()" #oddForm="ngForm">
    <div id="form_result" *ngIf="submitted">
      <h1>{{ result | translate | async }}</h1>
    </div>
    <div id="form_inner" *ngIf="!submitted">
  			<label for="firstName" class="first-name">nombres y apellidos:</label>
  			<input id="firstName" type="text" [(ngModel)]="model.firstName" name="firstName" required>

        <label for="email">e-mail:</label>
        <input id="email" type="email" [(ngModel)]="model.email" name="email" required>

        <label for="celular" class="celular">celular:</label>
  			<input id="celular" type="text" [(ngModel)]="model.celular" name="celular">

        <label for="signo">signo zodiacal:</label>
        <input id="signo" type="text" [(ngModel)]="model.signo" name="signo">

  			<label for="teoria">teoría de conspiración favorita: <br>(argumenta tu respuesta) </label>
        <textarea name="teoria" [(ngModel)]="model.teoria"></textarea>


  			<label for="modelo">modelo terrestre predilecto:</label>

    <ul class="modelo_container">
    <li>
      <input type="radio" id="esferoide" value="esferoide" [(ngModel)]="model.selector" name="selector">
      <label for="esferoide">tierra esferoide oblato</label>
      <div class="check"></div>
    </li>

    <li>
      <input type="radio" id="hueca" value="hueca" [(ngModel)]="model.selector" name="selector">
      <label for="hueca">tierra hueca - modelo intraterrestre</label>

      <div class="check"><div class="inside"></div></div>
    </li>

    <li>
      <input type="radio" id="plana" value="plana" [(ngModel)]="model.selector" name="selector">
      <label for="plana">tierra plana - tierraplanista</label>
      <div class="check"><div class="inside"></div></div>
    </li>

      <li>
        <input type="radio" id="toroide" value="toroide" [(ngModel)]="model.selector" name="selector">
        <label for="toroide">tierra toroide - dona</label>
        <div class="check"><div class="inside"></div></div>
      </li>
  </ul>

  <button><img src="../assets/images/oddhorizons/submit.svg" alt=""></button>
  </div>
  </form>
</div>
