<div class="base_top"></div>
<div class="breadcrumb"><a href="/">45sna - el revés de la trama</a> <img class="search_img" src="../../assets/images/flecha_right.svg" /><span class="counter" ><img (click)="prev()"  [ngClass]="{'flecha_izq':true, 'enabled': selected > 1}" class="" src="../../assets/images/flecha_izq.svg" />{{ crumb | translate | async }}  <span *ngIf="posts$ | async | filter:'title':query;let posts">(<span *ngIf="selected">{{selected}}/</span>{{posts.length}})<img (click)="next()" [ngClass]="{'flecha_der':true, 'enabled': selected < posts.length}" src="../../assets/images/flecha_der.svg" /></span></span></div>
<!-- <div class="search">
  <input type="text" name="" value="">
  <img class="search_img" src="../../assets/images/search_icon.svg" />
</div> -->

<ul [ngClass]="{'posts_list': true, 'has_index': show_index }" id="column_list_container">

  <li id="main_index" class="post_single index_column" *ngIf="show_index" (mouseenter)="enter()" (mouseleave)="leave()">
    <div *ngIf="post_type == 'curadurias'" class="column_search">
      <input type="text"  autofocus="autofocus"  placeholder="" [(ngModel)]="artquery" />
    </div>
    <div *ngIf="post_type != 'curadurias'" class="column_search">
      <input type="text"  autofocus="autofocus"  placeholder="" [(ngModel)]="query" />
    </div>
    <ul *ngIf="post_type == 'curadurias'">
      <li *ngFor="let post of ordered | filter:'title':artquery; let i=index;" (mouseenter)="setOverColor( post.id )" (mouseleave)="restoreColor( post.id )"><a [ngStyle]="{'color': colores_over[post.id]}" [innerHTML]="post.title.rendered" [routerLink]="['/exposiciones-y-proyectos', links[post.id]]"></a></li>
    </ul>
    <ul *ngIf="post_type == 'artistas'">
      <li *ngFor="let post of ordered | filter:'title':query; let i=index;" (mouseenter)="setOverColor( post.id )" (mouseleave)="restoreColor( post.id )"><a [ngStyle]="{'color': colores_over[post.id]}" [innerHTML]="post.title.rendered" [routerLink]="['/'+slug, post.slug]"></a></li>
    </ul>
    <ul *ngIf="post_type != 'curadurias' && post_type != 'artistas'">
      <li *ngFor="let post of ordered | filter:'title':query; let i=index;"><a [innerHTML]="post.title.rendered" [routerLink]="['/'+slug, post.slug]"></a></li>
    </ul>
  </li>

  <li #allcolumns class="post_single" *ngFor="let post of posts$ | async | filter:'title':query; let i=index;" [routerLink]="['/'+slug, post.slug]"  [ngStyle]="{'background-color': post.acf.color_fondo || colores[ post.id ] }" [ngClass]="{'activo': id == post.slug}" [attr.id]="post.slug" [attr.data-index]="i">

      <span *ngIf="id == post.slug" class="close" (click)="backtoindex($event, slug)" [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }"><img src="../../assets/images/cerrar_x.svg" /></span>

      <h2 [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }"  [ngClass]="{'h2abierto': id == post.slug, 'h2cerrado': id != post.slug}" [innerHTML]="post.title.rendered | translate | async">
        <a [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }" [routerLink]="['/'+slug, post.slug]"></a></h2>
        <span class="elreves">el revés de la trama</span>
        <h3 [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }">{{ post.acf.subtitulo | translate | async }}</h3>
      <div class="order" [ngClass]="{'abierto': id == post.slug, 'cerrado': id != post.slug}">
        <!-- <div class="thumb" *ngIf="post._embedded['wp:featuredmedia']"><img [src]="post._embedded['wp:featuredmedia']['0'].media_details.sizes.medium.source_url"/></div> -->
        <div *ngIf="post.acf.columna_derecha; else columnaUnica">
          <div class="excerpt" (click)="onContentClick($event)" [innerHTML]="post.content.rendered | keepHtml | translatehtml | async" [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }"></div>

          <div class="excerpt guion" >-</div>

          <div class="excerpt" *ngIf="post.acf.texto__ingles"  (click)="onContentClick($event)" [innerHTML]="post.acf.texto__ingles | keepHtml | translatehtml | async" [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }"></div>
        </div>

        <ng-template #columnaUnica>
          <div class="excerpt columnaUnica" (click)="onContentClick($event)" [innerHTML]="post.content.rendered | keepHtml | translatehtml | async" [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }"></div>
        </ng-template>

        <div class="columna_derecha"  *ngIf="post.acf.columna_derecha" (click)="onContentClick($event)" >
        <div class="derecha_inner" *ngIf="post.acf.columna_derecha" [innerHTML]="post.acf.columna_derecha | keepHtml" [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }">
          </div>
          <div class="adjuntos_inner" *ngIf="(post_type == 'artistas' || post_type == 'curadurias') && id == post.slug" [ngStyle]="{'color': post.acf.color_fuente || colores_fonts[ post.id ]  }">
            <div class="" *ngIf="post.acf.columna_adjuntos" [innerHTML]="post.acf.columna_adjuntos | keepHtml">
            </div>
          </div>

      </div>
      </div>
    </li>

    <div *ngIf="loading" id="loadinggif"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>


</ul>

<app-lightbox [opened]="show_lightbox" [seturl]="lightbox_url" (closed)="show_lightbox = false"></app-lightbox>
