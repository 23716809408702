/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./newscolumn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../pipes/translate.pipe";
import * as i5 from "../google.services";
import * as i6 from "../app.service";
import * as i7 from "./newscolumn.component";
import * as i8 from "../wpbackend.service";
var styles_NewscolumnComponent = [i0.styles];
var RenderType_NewscolumnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewscolumnComponent, data: {} });
export { RenderType_NewscolumnComponent as RenderType_NewscolumnComponent };
function View_NewscolumnComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "thumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit._embedded["wp:featuredmedia"]["0"].source_url; _ck(_v, 1, 0, currVal_0); }); }
function View_NewscolumnComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "li", [["class", "news_column_list_item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-color": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "news_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "h3", [], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggle() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "color": 0 }), i1.ɵdid(7, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), i1.ɵppd(9, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "h4", [], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(13, { "color": 0 }), i1.ɵdid(14, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), i1.ɵppd(16, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "news_content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewscolumnComponent_2)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.context.$implicit.acf.color_fondo); _ck(_v, 1, 0, currVal_0); var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit.acf.color_fuente); _ck(_v, 5, 0, currVal_2); var currVal_3 = _ck(_v, 8, 0, ("/noticias/" + _v.context.$implicit.slug)); _ck(_v, 7, 0, currVal_3); var currVal_5 = _ck(_v, 13, 0, _v.context.$implicit.acf.color_fuente); _ck(_v, 12, 0, currVal_5); var currVal_6 = _ck(_v, 15, 0, ("/noticias/" + _v.context.$implicit.slug)); _ck(_v, 14, 0, currVal_6); var currVal_7 = _v.context.$implicit._embedded["wp:featuredmedia"]; _ck(_v, 20, 0, currVal_7); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 10).transform(i1.ɵunv(_v, 4, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.title.rendered)))); _ck(_v, 4, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 17).transform(i1.ɵunv(_v, 11, 0, _ck(_v, 16, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.acf.subtitulo)))); _ck(_v, 11, 0, currVal_4); }); }
export function View_NewscolumnComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TranslatePipe, [i5.GoogleService, i6.AppService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["id", "news_column"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "show": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["id", "news_column_toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["prensa y cr\u00EDtica"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ul", [["id", "news_column_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NewscolumnComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.show); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.news$)); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_NewscolumnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-newscolumn", [], null, null, null, View_NewscolumnComponent_0, RenderType_NewscolumnComponent)), i1.ɵdid(1, 114688, null, 0, i7.NewscolumnComponent, [i3.ActivatedRoute, i3.Router, i8.WpbackendService, i6.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewscolumnComponentNgFactory = i1.ɵccf("app-newscolumn", i7.NewscolumnComponent, View_NewscolumnComponent_Host_0, {}, {}, []);
export { NewscolumnComponentNgFactory as NewscolumnComponentNgFactory };
