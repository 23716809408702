import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable,  Subject } from 'rxjs';
import { Resolve } from '@angular/router';
import { GoogleService, GoogleObj } from './google.services';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AppService {
  private global_color_source = new Subject<string>();
  global_color$ = this.global_color_source.asObservable();

  private language_source = new Subject<string>();
  language$ = this.language_source.asObservable();
  current_lang:string = 'es';

  public googleObj: GoogleObj = new GoogleObj();
  public key: string = 'AIzaSyABHLb_KjqP_bYFkNGhdFA5vo5ULdtYrFI';
  public result = '';

  constructor(
    private http: HttpClientModule,
    private _google: GoogleService,
    private cookieService: CookieService
  ) {

  }

  set_global_color( color: any ){
    console.log("Setting color...", color );
    this.global_color_source.next( color );
  }

  set_language( lang: string ){
    console.log("Setting language...", lang );
    this.cookieService.set( 'lang', lang );
    this.language_source.next( lang );
    this.current_lang = lang;
  }

  get_language(){
    if( this.cookieService.check('lang') ){
      this.current_lang = this.cookieService.get( 'lang' );
      this.language_source.next( this.current_lang );
    }else{
      this.language_source.next( this.current_lang );
    }
    console.log("Got language...", this.current_lang );
  }

  test_translate(){
    console.log("Testing translate disabled...");
    //this.googleObj.q = 'farm';
    //this._google.translate(this.googleObj, this.key).subscribe(
    //  (res: any) => {
    //    this.result = res.data.translations[0].translatedText;
    //    console.log("Got translation: ", res.data.translations[0].translatedText );
    //  },
    //  err => {
    //    console.log(err);
    //  }
    //);
  }
}
