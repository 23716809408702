import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-exposicion',
  templateUrl: './exposicion.component.html',
  styleUrls: ['./exposicion.component.scss']
})
export class ExposicionComponent implements OnInit, AfterViewInit {
  @ViewChild('myVideo') myVideo: any;
  exposiciones$: Observable<any[]>;
  expo$: any;
  exposicion:string;
  player_ready:boolean = false;
  player;
  id:string;
  muted:boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService
  ) {
    this.id = route.snapshot.data['id'];
    this.exposiciones$ = this.wp.getList('exposicion');
  }

  ngAfterViewInit() {
    const doc = (<any>window).document;
    const playerApiScript = doc.createElement('script');
    playerApiScript.type = 'text/javascript';
    playerApiScript.src = 'https://www.youtube.com/iframe_api';
    // doc.body.appendChild(playerApiScript);
  }

  ngOnInit() {
    this.route.url.subscribe(url =>{
         console.log("Url: ", url);
         this.exposicion = this.route.snapshot.paramMap.get('id') || this.id;
         console.log("Exposicion: ", this.exposicion);
         if( this.exposicion != null ){
           this.appService.set_global_color('blanco exposicion');
           this.getExpo( this.exposicion );
         }
    });
  }

  initPlayer(){
          this.player = new (<any>window).YT.Player('player', {
            height: '100%',
            width: '100%',
            videoId: this.expo$.acf.url_de_video,
            events: {
              'onReady': (event) => { this.onPlayerReady(event); },
              'onStateChange': (event) => { this.onPlayerStateChange(event); }
            },
            playerVars: {
              autoplay: 1,
              controls: 0,
              showinfo: 0,
              modestbranding: 1,
              rel: 0,
              showInfo: 0
            }
          });
  }

  getExpo( id ){
    console.log("Getting expo from service: ", id );
    this.wp.getSingle( id, 'exposicion' ).subscribe( res => {
      this.expo$ = res[0];
      console.log( "Got expo: ", this.expo$ );
      if( !this.player_ready ){
        // this.initPlayer();
      }
      if( this.player !== undefined ){
        this.player.cueVideoByUrl( this.expo$.acf.url_de_video );
        this.player.playVideo();
      }
      // this.player.play();
    });
  }

  seekTo( time ){
    console.log("Go to time: ", time );
    //this.player.seekTo( time, true );
    //this.player.playVideo();
    this.myVideo.nativeElement.pause();
    this.myVideo.nativeElement.currentTime = time;
    this.myVideo.nativeElement.play();
  }

  // The API calls this function when the player's state changes.
  onPlayerStateChange(event) {
    console.log('onPlayerStateChange');
    console.log(event.data);
  }

  activar_sonido(){
    this.muted = false;
  }

  // The API will call this function when the video player is ready
  onPlayerReady(event) {
    this.player_ready = true;

    console.log(event);

    this.getExpo( this.exposicion );

    // const videoId = this.getVideo();
    // event.target.cueVideoById({
    //   'videoId': videoId
    // });
    // event.target.playVideo();
  }

  getVideo() {
    return '60ItHLz5WEA';
  }
}
