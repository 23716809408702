import { Pipe, PipeTransform } from '@angular/core';
import { GoogleService, GoogleObj } from '../google.services';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppService } from '../app.service';

@Pipe({name: 'translatehtml'})
export class TranslatehtmlPipe implements PipeTransform {
  public googleObj: GoogleObj = new GoogleObj();
  public key: string = 'AIzaSyABHLb_KjqP_bYFkNGhdFA5vo5ULdtYrFI';
  public result = '';
  current_lang:string;

  constructor(
    private _google: GoogleService,
    private appService: AppService
  ) {
    this.appService.language$.subscribe( (lang) => {
      this.current_lang = lang;
      //console.log("HTML Pipe lang now: ", this.current_lang );
    });
  }

  transform(value: any): Observable<string> {
    let _subject = new BehaviorSubject('-');
    if( !this.current_lang ){
      this.appService.get_language();
    }else{
      this.googleObj.format = 'html';
      if( this.current_lang == 'es' ){
        value = value;
        _subject.next( value );
        return _subject;
      }else{
        this.googleObj.q = value.changingThisBreaksApplicationSecurity;
        this.googleObj.target = this.current_lang;
        //console.log("Trying to translate: ", value.changingThisBreaksApplicationSecurity );
        this._google.translate(this.googleObj, this.key).subscribe(
          (res: any) => {
            this.result = res.data.translations[0].translatedText;
            //console.log("Got translation: ", res.data.translations[0].translatedText );
            value = res.data.translations[0].translatedText;
            if( this.current_lang == 'en' ){
              value = value.replace("45 national artists\' show", "45 Salon");
              value = value.replace("The Hall", "The Salon");
              value = value.replace("story of the room", "story of the salon");
              value = value.replace("this room", "this salon");
              value = value.replace("the Hall", "the Salon");
              value = value.replace("this Hall", "this Salon");
              value = value.replace("this room", "this salon");
              value = value.replace("the room", "the salon");
              value = value.replace("45th National Hall", "45th Salon");
              value = value.replace("the reverse of the plot", "the back of things");
            }
             _subject.next( value );
          },
          err => {
            console.log(err);
          }
        );
        return _subject;
      }
    }
  }
}
