import { Routes } from '@angular/router';
import { ExposicionComponent } from './exposicion/exposicion.component';
import { WhiteboardComponent } from './whiteboard/whiteboard.component';
import { RevesComponent } from './reves/reves.component';
import { RevesEnComponent } from './revesen/reves.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { ProponemosComponent } from './proponemos/proponemos.component';
import { StreamingComponent } from './streaming/streaming.component';
import { PageComponent } from './page/page.component';
import { JackpotComponent } from './jackpot/jackpot.component';
import { ColumnasComponent } from './columnas/columnas.component';
import { SedesComponent } from './sedes/sedes.component';
import { FuturoComponent } from './futuro/futuro.component';
import { CuadernosComponent } from './cuadernos/cuadernos.component';
import { MapaComponent } from './mapa/mapa.component';
import { OddhorizonsComponent } from './oddhorizons/oddhorizons.component';
var ɵ0 = { type: 'lineatiempo', slug: 'linea-de-tiempo' }, ɵ1 = { id: 'edificio-de-arquitectura' }, ɵ2 = { type: 'artistas', slug: 'artistas' }, ɵ3 = { type: 'catedras', slug: 'catedras' }, ɵ4 = { type: 'catedras', slug: 'catedras' }, ɵ5 = { type: 'columnistas', slug: 'columnistas' }, ɵ6 = { type: 'equipo', slug: 'equipo-curatorial' }, ɵ7 = { type: 'curadurias', slug: 'exposiciones-y-proyectos' }, ɵ8 = { type: 'posts', slug: 'noticias' }, ɵ9 = { type: 'posts', slug: 'noticias' };
var routes = [
    // Home
    { path: '', component: JackpotComponent },
    { path: 'redireccion', component: JackpotComponent },
    { path: 'redireccion/:id', component: JackpotComponent },
    // Site
    { path: 'oddhorizons', component: OddhorizonsComponent },
    { path: 'lineatiempo', redirectTo: 'linea-de-tiempo/' },
    { path: 'linea-de-tiempo', redirectTo: 'linea-de-tiempo/' },
    { path: 'linea-de-tiempo/', component: ColumnasComponent, data: ɵ0 },
    { path: 'linea-de-tiempo/:id', redirectTo: 'linea-de-tiempo/' },
    { path: 'futuro', component: ExposicionComponent, data: ɵ1 },
    { path: 'en', component: RevesEnComponent },
    { path: 'muro', component: WhiteboardComponent },
    { path: 'future', redirectTo: 'futuro610' },
    { path: 'futuro610', component: FuturoComponent },
    { path: 'cuadernos-de-la-violencia', component: CuadernosComponent },
    { path: 'mapa', component: MapaComponent },
    { path: 'jackpot', redirectTo: 'ruleta' },
    { path: 'ruleta', component: JackpotComponent },
    { path: 'sedes', component: SedesComponent },
    { path: 'artistas', redirectTo: 'artistas/' },
    { path: 'artistas/:id', component: ColumnasComponent, data: ɵ2 },
    { path: 'catedras', redirectTo: 'catedra-performativa/' },
    { path: 'catedra-performativa', component: ColumnasComponent, data: ɵ3 },
    { path: 'catedra-performativa/:id', component: ColumnasComponent, data: ɵ4 },
    { path: 'columnistas', redirectTo: 'columnistas/' },
    { path: 'columnistas/:id', component: ColumnasComponent, data: ɵ5 },
    { path: 'equipo', redirectTo: 'equipo-curatorial/' },
    { path: 'equipo-curatorial', redirectTo: 'equipo-curatorial/' },
    { path: 'equipo-curatorial/:id', component: ColumnasComponent, data: ɵ6 },
    { path: 'curadurias', redirectTo: 'exposiciones-y-proyectos/' },
    { path: 'curadurias/:id', redirectTo: 'exposiciones-y-proyectos/:id' },
    { path: 'exposiciones-y-proyectos', redirectTo: 'exposiciones-y-proyectos/' },
    { path: 'exposiciones-y-proyectos/:id', component: ColumnasComponent, data: ɵ7 },
    { path: 'exposiciones', component: ExposicionComponent },
    { path: 'exposicion/:id', component: ExposicionComponent },
    { path: 'timelines', redirectTo: '' },
    { path: 'timeline/:id', redirectTo: '' },
    { path: 'reves', component: RevesComponent },
    { path: 'calendario', component: CalendarioComponent },
    { path: 'noticias', redirectTo: 'prensa-y-critica/' },
    { path: 'noticias/:id', redirectTo: 'prensa-y-critica/:id' },
    { path: 'prensa-y-critica', component: ColumnasComponent, data: ɵ8 },
    { path: 'prensa-y-critica/:id', component: ColumnasComponent, data: ɵ9 },
    { path: 'blog', redirectTo: 'noticias' },
    { path: 'blog/:id', redirectTo: 'noticias/:id' },
    { path: 'manifiesto', component: ProponemosComponent },
    { path: 'proponemos', redirectTo: 'manifiesto' },
    { path: 'streaming', redirectTo: 'videos-y-streamings' },
    { path: 'videos-y-streamings', component: StreamingComponent },
    { path: 'pages', component: PageComponent },
    { path: 'prensa', redirectTo: 'comunicados' },
    { path: ':id', component: PageComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
