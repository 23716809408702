import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnChanges {
  open:boolean = false;
  url:string;
  @Input()
  set opened(opened: boolean){
    this.open = opened;
  };
  @Input()
  set seturl(url: string){
    this.url = url;
  };
  @Output() closed = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    console.log( "changes: ", changes );
  }

  close(){
    this.open = false;
    this.closed.emit( this.open );
  }

}
