/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lightbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lightbox.component";
var styles_LightboxComponent = [i0.styles];
var RenderType_LightboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LightboxComponent, data: {} });
export { RenderType_LightboxComponent as RenderType_LightboxComponent };
function View_LightboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_LightboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["id", "lightbox_container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["id", "lightbox"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LightboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.open; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LightboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lightbox", [], null, null, null, View_LightboxComponent_0, RenderType_LightboxComponent)), i1.ɵdid(1, 638976, null, 0, i3.LightboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LightboxComponentNgFactory = i1.ɵccf("app-lightbox", i3.LightboxComponent, View_LightboxComponent_Host_0, { opened: "opened", seturl: "seturl" }, { closed: "closed" }, []);
export { LightboxComponentNgFactory as LightboxComponentNgFactory };
