import { OnInit } from '@angular/core';
var MondrigoComponent = /** @class */ (function () {
    function MondrigoComponent() {
        this.api_loaded = false;
        this.playing = false;
        this.camara_activa = 0;
    }
    MondrigoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.camaras = [
            {
                'tipo': 'youtube',
                'id': 'GSOXIpnbADc'
            },
            {
                'tipo': 'youtube',
                'id': 'r9tO-FxYggw'
            },
            {
                'tipo': 'youtube',
                'id': 'EY_bSul7YpI'
            },
            {
                'tipo': 'youtube',
                'id': 'NdqQiSt0Azg'
            },
            {
                'tipo': 'youtube',
                'id': '2wD6WH4PBRU'
            },
            {
                'tipo': 'youtube',
                'id': 'Q2idNXzfXaQ'
            },
            {
                'tipo': 'youtube',
                'id': 'p2pWCmQ6xbo'
            },
            {
                'tipo': 'youtube',
                'id': 'gcEPLWhwfJU'
            },
            {
                'tipo': 'youtube',
                'id': 'kqAKqgO3lgo'
            },
            {
                'tipo': 'youtube',
                'id': 'AorNLMYo1Vw'
            }
        ];
        this.camara_activa = 0;
        this.determinantes = [
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '1',
            '9',
            '16',
            '1974',
            '45231',
            '0 1234',
            'cinco',
            'cinco',
            'dos',
            'dos',
            'dos',
            'el',
            'le',
            'les',
            'lo',
            'los',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'me',
            'Me',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mi',
            'mis',
            'mis',
            'mis',
            'mis',
            'mis',
            'mis',
            'Mis',
            'mis',
            'mis',
            'mis',
            'mis',
            'mis',
            'mis',
            'mis',
            'mismo',
            'mismo',
            'no',
            'No',
            'no',
            'no',
            'Nos',
            'nuestra',
            'nuestra',
            'nuestra',
            'nuestra',
            'nuestra',
            'nuestras',
            'nuestro',
            'nuestros',
            'nuestros',
            'nuestros',
            'otro',
            'otros',
            'Qué',
            'qué',
            'se',
            'se',
            'se',
            'se',
            'se',
            'Se',
            'se',
            'se',
            'se',
            'se',
            'seis',
            'seis',
            'siete',
            'somos',
            'somos',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'su',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'Sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'sus',
            'Sus',
            'sus',
            'sus',
            'sus',
            'te',
            'te',
            'te',
            'te',
            'Te',
            'te',
            'te',
            'te',
            'te',
            'ti',
            'Tu',
            'tu',
            'tu',
            'tu',
            'tus',
            'tus',
            'tus',
            'tus',
            'tus',
            'tus',
            'tus',
            'Tus',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'un',
            'Un',
            'Un',
            'un',
            'un',
            'Un',
            'un',
            'un',
            'un',
            'Un',
            'un',
            'un',
            'un',
            'un',
            'una',
            'Una',
            'Una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'una',
            'Una',
            'Una',
            'Una',
            'una',
            'una',
            'una',
            'Una',
            'Una',
            'una',
            'Una',
            'una',
            'una',
            'una',
            'una',
            'Una',
            'una',
            'Una',
            'una',
            'Unas',
            'unas',
            'UNO',
            'uno',
            'uno',
            'uno',
            'Uno',
            'unos',
            'unos',
            'unos',
            'unos',
            'Unos',
            'veinte'
        ];
        this.adverbios = [
            'a cambio',
            'abajo',
            'abajo',
            'adentro',
            'Ahora',
            'ahora',
            'ahora',
            'al frente',
            'alguna',
            'allá',
            'allí',
            'allí',
            'ALREDEDOR',
            'alto',
            'año',
            'años',
            'ante',
            'ante',
            'ante',
            'ante',
            'ante',
            'ante',
            'Antes',
            'apariencias',
            'apariencias',
            'aquí',
            'aquí',
            'aquí',
            'arbitrariamente',
            'arriba',
            'arsenal',
            'así',
            'así',
            'Atribulada',
            'aún',
            'aún',
            'aunque',
            'Aunque',
            'ayer',
            'bajo',
            'bajo',
            'bajo',
            'bajo',
            'bajo',
            'bautizo',
            'bienvenida',
            'bienvenida',
            'cada',
            'cada',
            'cada',
            'cada',
            'Cada',
            'cadencias',
            'cálculo',
            'campo',
            'casi',
            'circulo',
            'Claro',
            'como',
            'Como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'Como',
            'Como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'como',
            'Como',
            'como',
            'como',
            'como',
            'como',
            'compañia',
            'compañía',
            'contra',
            'contra',
            'cuando',
            'cuando',
            'cuando',
            'cuando',
            'cuando',
            'cuando',
            'cuando',
            'Cuando',
            'cuándo',
            'cuanto',
            'cuidadosamente',
            'cuyo',
            'cuyo',
            'de pronto',
            'debajo',
            'debido',
            'definitivamente',
            'demás',
            'demás',
            'demasiado',
            'dentro',
            'dentro',
            'deriva',
            'descontento',
            'Desde',
            'desde',
            'desde',
            'desde',
            'desde',
            'Desde',
            'desdeñosamente',
            'después',
            'después',
            'después',
            'después',
            'detrás',
            'dificultades',
            'disparos',
            'diversión',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'donde',
            'dónde',
            'elevación',
            'Empero',
            'en',
            'En',
            'en',
            'en',
            'en',
            'En',
            'en',
            'en',
            'en',
            'en',
            'en',
            'En',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'En',
            'En',
            'en',
            'en',
            'en',
            'en cuenta',
            'en medio',
            'Entonces',
            'entonces',
            'entonces',
            'Entrada',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'entre',
            'esa',
            'esas',
            'ese',
            'ese',
            'ese',
            'eso',
            'eso',
            'esos',
            'esos',
            'Esos',
            'esos',
            'espontaneidad',
            'esta',
            'esta',
            'esta',
            'esta',
            'esta',
            'esta',
            'esta',
            'Está',
            'ésta',
            'estancado',
            'estas',
            'estas',
            'este',
            'este',
            'este',
            'este',
            'este',
            'este',
            'este',
            'estos',
            'estos',
            'estos',
            'éter',
            'excepto',
            'extrañas',
            'extremo',
            'extremo',
            'extremo',
            'falta',
            'finalmente',
            'frente',
            'fuera',
            'fuera',
            'gracia',
            'hábito',
            'hacia',
            'hacia',
            'hacia',
            'hacia',
            'hacia',
            'hacia',
            'hasta',
            'Hasta',
            'Hasta',
            'hasta',
            'hasta',
            'hasta',
            'hasta',
            'hay',
            'Hay',
            'hay',
            'hoy',
            'Hoy',
            'Hoy',
            'IGUALDAD',
            'insospechadamente',
            'intención',
            'interiormente',
            'introvertidamente',
            'izquierda',
            'jamás',
            'junto',
            'Junto',
            'lado',
            'lejos',
            'límite',
            'luego',
            'luego',
            'Luego',
            'luego',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'más',
            'Mayor',
            'mayor',
            'medio',
            'mejor',
            'Mientras',
            'mientras',
            'mientras',
            'mientras',
            'mientras',
            'misma',
            'misma',
            'mismo',
            'momento',
            'muchas',
            'muchos',
            'muy',
            'muy',
            'nada',
            'nada',
            'ni',
            'ni',
            'ni',
            'ni',
            'ningún',
            'ningún',
            'Ningún',
            'Nunca',
            'obstante',
            'oh',
            'Oh',
            'olvidados',
            'operación',
            'operación',
            'orilla',
            'otra',
            'otras',
            'otrora',
            'particular',
            'paso',
            'pautado',
            'Pero',
            'pípila',
            'poco',
            'poco',
            'Por qué',
            'por qué',
            'porque',
            'posibilidades',
            'posible',
            'posible',
            'posible',
            'posición',
            'preciso',
            'preparación',
            'procedencia',
            'Pronto',
            'proporción',
            'proscrita',
            'pues',
            'pues',
            'Qué',
            'Qué',
            'Qué',
            'Qué',
            'quien',
            'quién',
            'quién',
            'quienquiera',
            'real',
            'recién',
            'resto',
            'revolución',
            'según',
            'según',
            'segundo',
            'semejanza',
            'serie',
            'servicio',
            'sexuales',
            'Si',
            'Si',
            'si',
            'Si',
            'Si',
            'Si',
            'sí',
            'si',
            'Si',
            'sí',
            'sí',
            'sí',
            'siempre',
            'siempre',
            'sin',
            'sin',
            'sin',
            'sin',
            'sin',
            'sin',
            'sin',
            'sin',
            'sin embargo',
            'sino',
            'siquiera',
            'siquiera',
            'siquiera',
            'sobre',
            'sobre',
            'Sobre',
            'sobre',
            'sobre',
            'sobre',
            'sobre',
            'sobre',
            'soledad',
            'sólo',
            'Sólo',
            'sólo',
            'sólo',
            'sólo',
            'Sólo',
            'sólo',
            'sólo',
            'sopor',
            'sutilmente',
            'sutilmente',
            'tal',
            'tal ',
            'también',
            'también',
            'tan',
            'tan',
            'tanta',
            'tanta',
            'tanto',
            'tanto',
            'tanto',
            'tempranamente',
            'ternuras',
            'tientas',
            'toda',
            'toda',
            'toda',
            'toda',
            'toda',
            'toda',
            'todas',
            'todas',
            'todo',
            'todo',
            'todo',
            'todo',
            'todo',
            'Todo',
            'Todo',
            'Todo',
            'Todos',
            'todos',
            'todos',
            'todos',
            'todos',
            'todos',
            'todos',
            'tras',
            'tras',
            'último',
            'única',
            'Únicamente',
            'únicamente',
            'Vaivén',
            'verdad',
            'verdad',
            'verdad',
            'vez',
            'vez',
            'vez',
            'vez',
            'vez',
            'vez',
            'vez',
            'vínculos',
            'Ya',
            'ya'
        ];
        this.sustantivos = [
            '1953',
            '20th Century',
            '20th Century Fox',
            'abuelos',
            'acto',
            'admiradores',
            'afecto',
            'agente',
            'agua',
            'agua',
            'agua',
            'agua',
            'agua',
            'agua',
            'águila',
            'aguja',
            'agujas',
            'agujero',
            'agujero',
            'aire',
            'alambre',
            'alambres',
            'alas',
            'alba',
            'alcoba',
            'aldeas',
            'alfabeto',
            'Alfileres',
            'algo',
            'alguien',
            'alguien',
            'alguien',
            'Alguien',
            'alimento',
            'alimento',
            'alma',
            'alma',
            'alma',
            'almuerzo',
            'ALTAMIRA',
            'amanate',
            'ambulancia',
            'América Latina',
            'amor',
            'amor',
            'amor',
            'amor',
            'amor',
            'amor',
            'ángel',
            'ángulo',
            'animal',
            'animales',
            'ánimo',
            'años',
            'anuncios',
            'apartamento',
            'aplausos',
            'aposento',
            'árbol',
            'árboles',
            'árboles',
            'archiduque',
            'Arcilla',
            'arena',
            'arma',
            'armarios',
            'arpón',
            'Arte',
            'asco',
            'ASESINOS',
            'astronáuta',
            'Astros',
            'asuntos',
            'Atlántida',
            'aullidos',
            'autográfos',
            'aves',
            'aves',
            'BABEL',
            'Bailarinas',
            'baile',
            'balanza',
            'balcones',
            'balón',
            'Barbitúrico',
            'barro',
            'barro',
            'baúl',
            'baúl',
            'becerro',
            'bendición',
            'beso',
            'beso',
            'beso',
            'besos',
            'besos',
            'bestia',
            'bestias',
            'billetes',
            'Boca',
            'boca',
            'boca',
            'bocas',
            'bohíos',
            'bolso',
            'bomba',
            'bosque',
            'botella',
            'botella',
            'botones',
            'bronce',
            'brújula',
            'bruma',
            'bruma',
            'buhardilla',
            'burdeles',
            'cabellos',
            'cabeza',
            'cabeza',
            'cabezas',
            'cabezas',
            'cabezas',
            'cabezas',
            'Cacique de Ojo de Perla',
            'cacto',
            'CADAVER',
            'cadaver',
            'calabacines',
            'calabacines',
            'caldera',
            'Caldera',
            'Caldera',
            'calle',
            'calle',
            'calumnia',
            'cama',
            'cámara',
            'camas',
            'camas',
            'Camelia',
            'CAMINO',
            'camino',
            'campo',
            'camposantos',
            'canapé',
            'canto',
            'cara',
            'cara',
            'cara',
            'Cara de Barro',
            'caracol',
            'carne',
            'carne',
            'CARPINTERO',
            'carpintero',
            'casa',
            'casa',
            'Casa',
            'casas',
            'cavernas',
            'ceniza',
            'cenizas',
            'cenizas',
            'centro',
            'cera',
            'Chile',
            'chorro',
            'ciegos',
            'cielos',
            'cine',
            'cinematógrafos',
            'ciudad',
            'ciudad',
            'clave',
            'cobra',
            'coeficiente',
            'columna',
            'comida',
            'compromisos',
            'confesión',
            'contexto',
            'conversaciones',
            'copa',
            'copas',
            'corazón',
            'corazón',
            'corazón',
            'corderos',
            'cordillera',
            'cordilleras',
            'Cortazar',
            'cosa',
            'cosa',
            'cosas',
            'cosas',
            'creencias',
            'Criatura',
            'cruces',
            'cuartitos',
            'cuello',
            'cuello',
            'cuello',
            'cuerpo',
            'cuerpo',
            'Cuerpo',
            'cueva',
            'cueva',
            'Cueva Maldita',
            'culo',
            'Dámaso',
            'dedo',
            'dedo',
            'desierto',
            'destino',
            'destino',
            'detectives',
            'día',
            'día',
            'día',
            'día',
            'día',
            'día',
            'día',
            'diablo',
            'diablos',
            'diablos',
            'Diamante',
            'días',
            'dientes',
            'dientes',
            'dientes',
            'diluvio',
            'Dinamarca',
            'Dinamarca',
            'Dior',
            'Dios',
            'diosa',
            'Director',
            'Directorio',
            'disco',
            'distancia',
            'distancia',
            'documentos',
            'dosis',
            'droga',
            'drogas',
            'dueño',
            'Duque',
            'duquesa',
            'Duquesa',
            'eco',
            'eco',
            'edificio',
            'El TIme',
            'encantamiento',
            'engaños',
            'escena',
            'escepticismo',
            'espectáculo',
            'espejismos',
            'espejuelos',
            'este',
            'Estornino',
            'estrella',
            'estrella',
            'estrellas',
            'estudios',
            'estudios',
            'existencia',
            'existencia',
            'expresión',
            'facciones',
            'fango',
            'ferrocarril',
            'fiebre',
            'fiebre',
            'fin',
            'Flandes',
            'Flandes',
            'Flandes',
            'Flandes',
            'flechas',
            'flirt',
            'flor',
            'flor',
            'flor',
            'fondo',
            'forma',
            'foso',
            'fotógrafos',
            'fragmentos',
            'frases',
            'fruto',
            'frutos',
            'fuego',
            'fuerzas',
            'futuro',
            'ganas',
            'garzas',
            'gatillo',
            'Gatos',
            'generaciones',
            'General',
            'gente',
            'Gerry Mulligan',
            'gesto',
            'gestos',
            'globo',
            'grasas',
            'guijarros',
            'guijarros',
            'hambre',
            'herida',
            'hierba',
            'hierba',
            'hierba',
            'Hijo',
            'hilo',
            'hipógrifo',
            'hipótesis',
            'historia',
            'Historia',
            'hocicos',
            'Höderlin',
            'Hombre',
            'hombre',
            'hombres',
            'hombres',
            'hombros',
            'hongos',
            'honor',
            'hora',
            'hora',
            'hormigas',
            'hornos',
            'huella',
            'huellas',
            'huellas',
            'huellas',
            'huellas',
            'huellas',
            'Huesos',
            'huesos',
            'humo',
            'humo',
            'idea',
            'Iglesia',
            'Iglesia',
            'imagenes',
            'imágenes',
            'impulso',
            'incendio',
            'incendio',
            'incendios',
            'indios',
            'infancia',
            'INFIERNO',
            'infierno',
            'instante',
            'instrumentos',
            'intersticios',
            'invernaderos',
            'invitados',
            'invitados',
            'jefes',
            'Jimmy Guffre',
            'José',
            'juego',
            'jugo',
            'Kerosene',
            'kiosco',
            'labio',
            'lámparas',
            'lana',
            'lápidas',
            'Las Cruces',
            'látigo',
            'lecho',
            'lengua',
            'lengua',
            'lenguaje',
            'letra',
            'libreta',
            'libro',
            'libros',
            'licor',
            'Limpia',
            'lira',
            'lirona',
            'llamas',
            'llanura',
            'Lodo',
            'Los Angeles',
            'Loto',
            'luces',
            'luciérnaga',
            'lugar',
            'lugares',
            'luna',
            'luna',
            'luz',
            'luz',
            'luz',
            'luz',
            'luz',
            'luz',
            'M',
            'Maga',
            'maíz',
            'maleza',
            'manantial',
            'mancha',
            'mancha',
            'mano',
            'mano',
            'mano',
            'mano',
            'mano',
            'mano',
            'manos',
            'manos',
            'manos',
            'manos',
            'mansión',
            'maquillaje',
            'maquillaje',
            'Marcial',
            'Mares',
            'mares',
            'margarita',
            'mariguana',
            'Marilyn Monroe',
            'MARILYN MONROE',
            'mármol',
            'martillos',
            'más',
            'masa',
            'mejilla',
            'mejillas',
            'melodías',
            'memoria',
            'memoria',
            'mensieur',
            'meses',
            'meses',
            'meses',
            'metales',
            'milagros',
            'ministros',
            'mirada',
            'moléculas',
            'monstruo',
            'montaña',
            'Montaña',
            'montaña',
            'montañas',
            'monte',
            'monte',
            'moral',
            'mordisco',
            'moretones',
            'morral',
            'mozo',
            'muchacha',
            'muchacho',
            'muchachos',
            'Mujeres',
            'multitud',
            'mundo',
            'mundo',
            'mundo',
            'mundos',
            'música',
            'nación',
            'Nada',
            'nada',
            'nada',
            'nada',
            'nadie',
            'nadie',
            'nadie',
            'nadie',
            'nadie',
            'nadie',
            'Nadie',
            'nadie',
            'naipes',
            'naranja',
            'Nariz',
            'náufragos',
            'navajas',
            'nave',
            'niebla',
            'niebla',
            'niebla',
            'niña',
            'niña',
            'niña',
            'niña',
            'niño',
            'niño',
            'niño',
            'niño',
            'nivel',
            'noche',
            'noche',
            'noche',
            'noche',
            'noche',
            'noche',
            'noches',
            'nombre',
            'nombre',
            'nombre',
            'nombre',
            'nombres',
            'Norte',
            'nube',
            'nubes',
            'nubes',
            'nubes',
            'nubes',
            'número',
            'número',
            'número',
            'objetos',
            'oceanos',
            'OFENSA',
            'Ofensa',
            'oído',
            'Ojo',
            'Ojo',
            'Ojo',
            'ojo',
            'Ojo',
            'ojos',
            'ojos',
            'ojos',
            'Ojos',
            'ojos',
            'ojos',
            'ojos',
            'ojos',
            'ojos',
            'ojos',
            'oleaje',
            'Oliveira',
            'olor',
            'olvido',
            'Olvido',
            'olvido',
            'Operaciones',
            'oportunidad',
            'Orejas',
            'orilla',
            'orín',
            'oro',
            'oro',
            'osamenta',
            'oscuridad',
            'oscuridad',
            'OTRO',
            'otros',
            'óvulos',
            'padre',
            'página',
            'país',
            'país',
            'país',
            'país',
            'PAISAJE',
            'Paisaje',
            'paisaje',
            'palabra',
            'palabra',
            'palabra',
            'palabras',
            'palabras',
            'palabras',
            'palabras',
            'palabras',
            'palacio',
            'paladar',
            'palmas',
            'palmeras',
            'palmeras',
            'paloma',
            'paloma',
            'pálpito',
            'pan',
            'panal',
            'pandero',
            'pandilla',
            'paredes',
            'Parra',
            'Parra',
            'Parra',
            'Parra',
            'Parra',
            'partícula',
            'pasillo',
            'paso',
            'pasos',
            'pasos',
            'pastillas',
            'pataleta',
            'patatas',
            'patio',
            'patio',
            'patio',
            'patio',
            'patria',
            'pecados',
            'pecho',
            'pedacitos',
            'película',
            'peñascos',
            'periodicos',
            'perrera',
            'perro',
            'perros',
            'personaje',
            'peso',
            'peyote',
            'pez',
            'pezuña',
            'pezuñas',
            'piedra',
            'piedras',
            'piedras',
            'piedras',
            'piedras',
            'piel',
            'piel',
            'piel',
            'piel',
            'piel ',
            'piernas',
            'pies',
            'pies',
            'pies',
            'piezas',
            'pinceles',
            'pinceles',
            'pirámides',
            'pisada',
            'pisadas',
            'pisadas',
            'pisadas',
            'pisos',
            'pisos',
            'pisos',
            'pista',
            'pistola',
            'placenta',
            'planeta',
            'plata',
            'playa',
            'poema',
            'poesía',
            'poesía',
            'Poesía',
            'poeta',
            'poetas',
            'poetas',
            'poetas',
            'policias',
            'pozo',
            'pozo',
            'precipicio',
            'precipicio',
            'prensa',
            'Presidente',
            'Presidente',
            'presidente',
            'Presidente',
            'prisa',
            'problemas',
            'Psicoanálisis',
            'psiquiatra',
            'psiquiatras',
            'puente',
            'puerca',
            'puerta',
            'puerta',
            'pulpa',
            'pupilas',
            'radio',
            'raíz',
            'Raíz',
            'ramaje',
            'rastro',
            'ratas',
            'ratas',
            'RATAS',
            'ratas',
            'ratas',
            'rayo',
            'rayo',
            'raza',
            'realidad',
            'realidad',
            'Récamir',
            'recepción',
            'recuerdo',
            'recuerdos',
            'recuerdos',
            'redioactividad',
            'reflectores',
            'reflectores',
            'región',
            'relámpago',
            'relámpagos',
            'representaciones',
            'residuos',
            'restos',
            'restos',
            'rinoceronte',
            'río',
            'Río',
            'río',
            'río',
            'ríos',
            'ríos',
            'ríos',
            'risas',
            'ritual',
            'rocio',
            'rocío',
            'romana',
            'romances',
            'romano',
            'ropajes',
            'ropajes',
            'ropas',
            'Rosa',
            'rosa',
            'rostro',
            'rostro',
            'rostro',
            'rostros',
            'rueda',
            'rueda',
            'sacrificio',
            'salida',
            'salita',
            'San Lorenzo',
            'San Quintín',
            'sangre',
            'santos',
            'sátiros',
            'script',
            'script',
            'secretos',
            'selva',
            'selva',
            'semana',
            'señal',
            'señal',
            'seno',
            'Señor',
            'señor',
            'señor',
            'Señor',
            'Señor',
            'Señor',
            'señor',
            'señor',
            'Serpientes',
            'set',
            'sexo',
            'siesta',
            'silencio',
            'silencio',
            'silencio',
            'silencio',
            'silla',
            'siluetas',
            'Simiento',
            'Singapur',
            'sirena',
            'sistema',
            'Sociedad Condal del Sueño',
            'sol',
            'sol',
            'sol',
            'sol',
            'sombra',
            'sombra',
            'sombrero',
            'sonámbulos',
            'sótano',
            'sótano',
            'sucesión',
            'sudor',
            'suelo',
            'suelo',
            'sueño',
            'sueño',
            'sueño',
            'sueño',
            'sueño',
            'sueños',
            'sueños',
            'sueños',
            'supermercado',
            'suspiro',
            'sustancia',
            'tango',
            'tardes',
            'teléfono',
            'teléfono',
            'teléfono',
            'teléfono',
            'templo',
            'templo',
            'terciopelo',
            'terremotos',
            'TERRITORIOS',
            'Testimonio',
            'Testimonio',
            'testimonios',
            'tias',
            'tiempo',
            'tienda',
            'tienda',
            'tienda',
            'tierra',
            'tierra',
            'tierra',
            'tierra',
            'tierra',
            'tierra',
            'tierra',
            'tierrales',
            'tierras',
            'tiza',
            'tobillos',
            'Trajes',
            'trajes',
            'trajes',
            'trampas',
            'tranquilizantes',
            'TRATADO',
            'trenes',
            'tribu',
            'trofeo',
            'trozos',
            'truenos',
            'Universo',
            'vaca',
            'vacas',
            'vacas',
            'vagones',
            'valle',
            'venado',
            'veneno',
            'venenos',
            'ventanas',
            'versión',
            'versos',
            'vértigo',
            'vestimenta',
            'viaje',
            'vida',
            'vida',
            'vida',
            'vida',
            'vida',
            'vidas',
            'vidrio',
            'viento',
            'viento',
            'viento',
            'viento',
            'viento',
            'vientre',
            'violincito',
            'visión',
            'visiones',
            'volcán',
            'voz',
            'voz',
            'voz',
            'voz',
            'voz',
            'vuelta',
            'Water de Urgencia',
            'Windsor',
            'Wirikuta',
            'WRONG NUMBER',
            'yate',
            'zaguán',
            'zaguán',
            'Zurita'
        ];
        this.adjetivos = [
            'abierta',
            'abrumados',
            'absurdas',
            'absurdo',
            'Action Painting',
            'activo',
            'adicto',
            'aerostático',
            'afilados',
            'ajenas',
            'alburas',
            'alegres',
            'alta',
            'alto',
            'amarga',
            'amarillos',
            'amenazantes',
            'amiga',
            'amigos',
            'amigos',
            'amigos',
            'aniquiladoras',
            'anonimistas',
            'antecesores',
            'aparente',
            'apartada',
            'apuesto',
            'aseados',
            'Asfixiada',
            'astillados',
            'ausentes',
            'azotados',
            'azul',
            'barato',
            'belleza',
            'bellisimos',
            'blanca',
            'blanca',
            'blancos',
            'borrosas',
            'borroso',
            'brillante',
            'brillante',
            'bruto',
            'caballeresca',
            'caídos',
            'calcinadas',
            'cálida',
            'caníbales',
            'cansado',
            'cansados',
            'capaces',
            'carcelero',
            'carentes',
            'cautivada',
            'ceguera',
            'central',
            'cerrados',
            'cerrados',
            'chistoso',
            'ciega',
            'cinematográfico',
            'clandestinidad',
            'claro',
            'colgada',
            'colores',
            'colorida',
            'Colosal',
            'cómodos',
            'conductor',
            'confusión',
            'contaminado',
            'contiguos',
            'contra',
            'contrariedad',
            'coronada',
            'creciente',
            'crispado',
            'culpable',
            'culpable',
            'debil',
            'definitivas',
            'delicada',
            'derramado',
            'derribados',
            'desacertada',
            'DESAPARECIDO',
            'Descompuestos',
            'desconectado',
            'desconocida',
            'descubierto',
            'desdén',
            'desnuda',
            'desolados',
            'determinada',
            'difícil',
            'disecado',
            'disimulados',
            'dividido',
            'dócil',
            'dulce',
            'dulce',
            'dulces',
            'educados',
            'eléctricos',
            'eléctricos',
            'elegante',
            'elegante',
            'elegidas',
            'emocionante',
            'empleadita',
            'empleadita',
            'empleadita',
            'encendidas',
            'enemigo',
            'enemigo',
            'enemigo',
            'engañador',
            'enlosados',
            'enmascarados',
            'envenenada',
            'envuelto',
            'escudriñadores',
            'espacial',
            'especie',
            'esperma',
            'espumosa',
            'esquizofrénica',
            'estrecho',
            'estruendo',
            'estupida',
            'eterna',
            'evaporados',
            'exagerados',
            'extendida',
            'extraviadas',
            'familiares',
            'feroces',
            'ferocidad',
            'feroz',
            'fiel',
            'fieles',
            'fiera',
            'fieras',
            'filosofal',
            'final',
            'finitos',
            'finitos',
            'firmeza',
            'flacas',
            'flaco',
            'florida',
            'fosforecente',
            'frenética',
            'fría',
            'fritas',
            'fugaz',
            'fugaz',
            'fulgor',
            'furor',
            'gangsters',
            'gastados',
            'gendarmería',
            'geniales',
            'goteante',
            'gran',
            'grande',
            'grandes',
            'grandes',
            'grasientas',
            'Grijarvas',
            'grisáceo',
            'grises',
            'grises',
            'herido',
            'hijos',
            'histéricas',
            'historiadas',
            'horneada',
            'horror',
            'humanas',
            'húmedo',
            'humilde',
            'humildes',
            'huerfanita',
            'ilícito',
            'importadas',
            'impunes',
            'impuntualidad',
            'inciertos',
            'incoherente',
            'inconciente',
            'indecencia',
            'indecible',
            'indiano',
            'indiano',
            'indiano',
            'indiano',
            'Inerte',
            'inexistencias',
            'infinita',
            'infinita',
            'infinito',
            'inmenso',
            'inminente',
            'Inmortales',
            'inmóvil',
            'inmóvil',
            'inmóvil',
            'inmóvil',
            'inmóviles',
            'inmóviles',
            'inmutable',
            'inocente',
            'insalvable',
            'insensible',
            'insospechable',
            'interestelar',
            'interminable',
            'íntimas',
            'intrínseca',
            'Inútil',
            'invisible',
            'irreal',
            'jazzistas',
            'joven',
            'jugoso',
            'juventud',
            'labrado',
            'ladrones',
            'largo',
            'largo',
            'lateral',
            'latigueador',
            'latinoamericanos',
            'lejos',
            'lenta',
            'Leve',
            'libre',
            'lisos',
            'llameante',
            'lodazal',
            'lunar',
            'luto',
            'manchada',
            'manufacturados',
            'materno',
            'medio',
            'medio',
            'mejor',
            'Melancólico',
            'menguante',
            'mental',
            'migraciones',
            'mínima',
            'miserable',
            'misteriosas',
            'monstruosa',
            'mordida',
            'móvil',
            'muchachada',
            'muerta',
            'necesario',
            'necesario',
            'negra',
            'negra',
            'negra',
            'negras',
            'negras',
            'negro',
            'negro',
            'negro',
            'negros',
            'negruzco',
            'nueva',
            'numerosas',
            'numerosas',
            'obediencia',
            'obsenidades',
            'odio',
            'odio',
            'oferta',
            'opuesto',
            'orden',
            'ordenados',
            'oscuridad',
            'palidez',
            'pálido',
            'parda',
            'pasadas',
            'paseantes',
            'pavor',
            'pederasta',
            'pegada',
            'Pegajosos',
            'peligro',
            'peludos',
            'perdidos',
            'peregrinos',
            'perfección',
            'pesada',
            'petrificada',
            'petrificadas',
            'plateada',
            'poca',
            'Poca',
            'poco',
            'podridas',
            'Poética',
            'poética',
            'posible',
            'postrada',
            'potencias',
            'precipitados',
            'prestigio',
            'presurosa',
            'profunda',
            'progresivas',
            'propia',
            'propias',
            'próximo',
            'puntillas',
            'puntual',
            'putrefacta',
            'quebradas',
            'quietos',
            'rancio',
            'raro',
            'remota',
            'remota',
            'remoto',
            'representación',
            'repulsiva',
            'resplandecientes',
            'ridículo',
            'ritual',
            'rodeada',
            'rodeado',
            'rodeados',
            'roja',
            'roja',
            'roto',
            'rubias',
            'sacrificadas',
            'sagradas',
            'salvaje',
            'seguridad',
            'siluetas',
            'psiquiatra',
            'soberbio',
            'sola',
            'solazo',
            'soledad',
            'solemne',
            'solos',
            'solos',
            'soñadores',
            'suavidad',
            'sucios',
            'sudados',
            'Super-Producción',
            'technicolor',
            'temida',
            'tenaces',
            'tenso',
            'tensos',
            'tersas',
            'tibios',
            'trenzadas',
            'tristeza',
            'unidas',
            'usados',
            'vano',
            'veloz',
            'verdadero',
            'verdadero',
            'verdadero',
            'vieja',
            'violada',
            'visible',
            'vivo',
            'vivos',
            'voraz'
        ];
        this.verbos = [
            '¡duerme!',
            '¡duerme!',
            'abrazar',
            'abren',
            'Abrí',
            'abrían',
            'abrían',
            'abrirán',
            'abrirán',
            'acaricia',
            'acaricia',
            'acercarse',
            'acerco',
            'acomodarse',
            'actuar',
            'acumularon',
            'adora',
            'adora',
            'adquiere',
            'advirtió',
            'alarga',
            'alcanzó',
            'aleja',
            'alteraba',
            'alteraban',
            'alzó',
            'amando',
            'amanecer',
            'Andaban',
            'andando',
            'andar',
            'anidaban',
            'apaciguarlos',
            'apagan',
            'Aparece',
            'aparecer',
            'aparecían',
            'apretaban',
            'arados',
            'archiva',
            'ardió',
            'arranco',
            'arrastra',
            'arrastraban',
            'Arrastraban',
            'arrebataban',
            'arrepentimiento',
            'Arrojado',
            'arrullas',
            'ascendiendo',
            'asistir',
            'asperjaba',
            'asumieron',
            'atacamos',
            'ataron',
            'atravesaba',
            'aúllo',
            'auyenta',
            'avanzar',
            'ayudado',
            'bailar',
            'bailara',
            'baja',
            'bajan',
            'bañarse',
            'bebe',
            'beber',
            'besa',
            'besar',
            'bordeando',
            'borrando',
            'brillar',
            'burlaban',
            'busca',
            'buscar',
            'cabalgamos',
            'caen',
            'Caer',
            'caeré',
            'caía',
            'caída',
            'cambiar',
            'camina',
            'camina',
            'caminando',
            'caminar',
            'caminar',
            'caminar',
            'caminar',
            'cancelar',
            'canta',
            'caza',
            'celebrando',
            'chocan',
            'chuzaba',
            'cobren',
            'coito',
            'coloca',
            'coloquen',
            'combate',
            'comenzaban',
            'comenzó',
            'compone',
            'compra',
            'comprender',
            'confundir',
            'confundir',
            'confundirse',
            'conoces',
            'conoces',
            'conocía',
            'conocida',
            'consultado',
            'conté',
            'contesta',
            'Continuando',
            'continuando',
            'continuando',
            'Continuar',
            'Continuar',
            'Continuar',
            'contraen',
            'corren',
            'correr',
            'corría',
            'corría',
            'cortan',
            'corte',
            'crece',
            'Crece',
            'crecía',
            'Creían',
            'Cubre',
            'cubría',
            'Cubrían',
            'cuenta',
            'Cuentan',
            'cuidaste',
            'culparás',
            'da',
            'da',
            'da',
            'da',
            'daban',
            'damos',
            'danza',
            'danza',
            'darían',
            'debe',
            'debo',
            'debo',
            'decaiga',
            'decido',
            'decir',
            'decirte',
            'dedicara',
            'deja',
            'deja',
            'deja',
            'dejan',
            'dejarlo',
            'dejes',
            'derrama',
            'derrumba',
            'desato',
            'descendiendo',
            'desconectaron',
            'descubre',
            'descubrimiento',
            'deshecho',
            'deslizaban',
            'desmayaba',
            'desmontan',
            'desnudarlo',
            'despertar',
            'despertar',
            'destroza',
            'devoraban',
            'Devorando',
            'dice',
            'diga',
            'dije',
            'dijeron',
            'dijeron',
            'dijeron',
            'dijo',
            'diluyendo',
            'dimos',
            'dió',
            'dirás',
            'dirigía',
            'dirigía',
            'discutian',
            'disfrutando',
            'disipado',
            'dispersa',
            'dividirme',
            'dobla',
            'dormir',
            'doy',
            'duerme',
            'Duerme',
            'duerme',
            'Duerne',
            'dura',
            'elije',
            'EMBOSCADA',
            'emigrar',
            'Emiten',
            'empezar',
            'empujo',
            'Encierran',
            'encuentro',
            'ENGENDRAN',
            'Enterrado',
            'enterrar',
            'entiende',
            'era',
            'era',
            'Era',
            'era',
            'era',
            'era',
            'era',
            'era',
            'Éramos',
            'eran',
            'Es',
            'es',
            'es',
            'es',
            'Es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'es',
            'esconderse',
            'escondido',
            'escribimos',
            'escribir',
            'escribir',
            'escrito',
            'escuchar',
            'escupo',
            'esperar',
            'está',
            'está',
            'está',
            'está',
            'está',
            'está',
            'estaba',
            'estaba',
            'estaba',
            'estalla',
            'estamos',
            'estamos',
            'estamos',
            'estamos',
            'están',
            'estar',
            'estás',
            'estiran',
            'estremecerse',
            'evapora',
            'evaporan',
            'evitaré',
            'exclamar',
            'exhiben',
            'expande',
            'Expiamos',
            'expone',
            'exprimo',
            'fabricadas',
            'firmar',
            'floreando',
            'flotar',
            'flotar',
            'fluctuar',
            'fluya',
            'fractura',
            'fue',
            'Fue',
            'fue',
            'fue',
            'fue',
            'fueron',
            'fueron',
            'fuma',
            'gesticulaban',
            'gime',
            'gira',
            'grita',
            'gritaba',
            'gritamos',
            'guardando',
            'ha',
            'ha',
            'ha',
            'ha',
            'ha',
            'ha',
            'habéis',
            'haberte',
            'había',
            'había',
            'Había',
            'habían',
            'habían',
            'habían',
            'habita',
            'Hablar',
            'hablarle',
            'hablo',
            'Hace',
            'hace',
            'hace',
            'hacerlo',
            'hacen',
            'hacer',
            'hacer',
            'hacerlo',
            'hacían',
            'haciendo',
            'haciendo',
            'haciendo',
            'haciendole',
            'hallaron',
            'han',
            'han',
            'Hay',
            'hay',
            'hay',
            'hay',
            'hay',
            'haya',
            'he',
            'he',
            'he ',
            'hecha',
            'hecho',
            'hecho',
            'hecho',
            'hecho',
            'hemos',
            'heredaste',
            'herré',
            'hice',
            'hicieron',
            'hiede',
            'hiere',
            'hizo',
            'hizo',
            'hubiera',
            'hundirá',
            'iba',
            'iba',
            'imaginando',
            'indican',
            'insistiendo',
            'instalé',
            'intentar',
            'intentar',
            'intente',
            'intentó',
            'interpreta',
            'lame',
            'lamente',
            'lanzo',
            'latidos',
            'leer',
            'levanta',
            'limpiaban',
            'llama',
            'llamado',
            'llaman',
            'llaman',
            'llamar',
            'llamar',
            'llamarás',
            'llamaremos',
            'llamarían',
            'llamarlas',
            'llamó',
            'Llegué',
            'llenaremos',
            'llenarse',
            'llevarla',
            'llevarse',
            'Llevo',
            'llorar',
            'lloviendo',
            'lloviendo',
            'llueve',
            'logra',
            'lograse',
            'maquillarse',
            'marcado',
            'marque',
            'masticar',
            'matar',
            'materializar',
            'medida',
            'mira',
            'Mira',
            'mira',
            'mira',
            'miraba',
            'moja',
            'montar',
            'moverme',
            'murieses',
            'observar',
            'ocultaron',
            'Ocultarse',
            'odié',
            'ofrecimos',
            'oído',
            'oímos',
            'oir',
            'Olvidaba',
            'olvidado',
            'olvidado',
            'olvidados',
            'olvidaré',
            'oración',
            'ORACIÓN',
            'oye',
            'oyen',
            'Parece',
            'parece',
            'parece',
            'parloteando',
            'pasado',
            'pasó',
            'pellizcandome',
            'penetrando',
            'perderé',
            'perdernos',
            'perdí',
            'perdido',
            'perdió',
            'Perdónala',
            'Perdoname',
            'perdónanos',
            'perfumea',
            'permanece',
            'PIenso',
            'Pienso',
            'Pienso',
            'Pienso',
            'Pienso',
            'Pienso',
            'pisa',
            'pisar',
            'piso',
            'plantarle',
            'podremos',
            'podría',
            'podríamos',
            'Podríamos',
            'pondríamos',
            'poner',
            'posa',
            'pregunta',
            'preguntaban',
            'preguntáis',
            'pregunto',
            'pregunto',
            'prende',
            'preño',
            'presenta',
            'prodigia',
            'producir',
            'progresando',
            'prometer',
            'propaga',
            'prosiguiendo',
            'publicaron',
            'puede',
            'puede',
            'purificaba',
            'quedaron',
            'quema',
            'quemarte',
            'querido',
            'quiera',
            'quiere',
            'rasca',
            'recibe',
            'recomendó',
            'reconozca',
            'reconstrucción',
            'reconstruirme',
            'reconstruirme',
            'recorren',
            'Recuerda',
            'recuerdo',
            'reemplazaba',
            'registra',
            'reir',
            'reparaste',
            'repartido',
            'repartirme',
            'rescate',
            'resisto',
            'resollasteis',
            'Responderán',
            'reúnen',
            'reunimos',
            'romperme',
            'sabe',
            'sabemos',
            'sabemos',
            'saber',
            'sabía',
            'sabía',
            'Sale',
            'salen',
            'salí',
            'salía',
            'salido',
            'saliendo',
            'salpico',
            'Salta',
            'salto',
            'sé',
            'sé',
            'sé',
            'sé',
            'sé',
            'SED',
            'seguida',
            'separa',
            'ser',
            'ser',
            'ser',
            'ser',
            'ser',
            'ser',
            'será',
            'será',
            'será',
            'sería',
            'sido',
            'sido',
            'siesta',
            'sigue',
            'sirve',
            'sobra',
            'sobreviví',
            'soltaba',
            'somos',
            'somos',
            'somos',
            'somos',
            'son',
            'son',
            'son',
            'son',
            'son',
            'son',
            'son',
            'son',
            'son',
            'soñó',
            'soñó',
            'soñó',
            'soy',
            'Sube',
            'subí',
            'supieron',
            'surge',
            'sustente',
            'taconeo',
            'temblor',
            'temor',
            'tendido',
            'tendría',
            'tener',
            'tenerse',
            'tengo',
            'tengo',
            'tenía',
            'tenía',
            'terminó',
            'tiembla',
            'tiemblo',
            'tienta',
            'Tiraban',
            'tiraban',
            'Tiraban',
            'tomaba',
            'tomada',
            'tomé',
            'torna',
            'trabajado',
            'traga',
            'traje',
            'Trazo',
            'trino',
            'usados',
            'va',
            'van',
            'van',
            'vas',
            've',
            'vender',
            'venga',
            'venía',
            'veo',
            'ver',
            'ver',
            'ver',
            'verle',
            'verle',
            'vieron',
            'vimos',
            'vimos',
            'vine',
            'visto',
            'vistos',
            'vivimos',
            'vivir',
            'vivisteis',
            'Vivisteis',
            'volver',
            'volver',
            'vuela',
            'vuelan',
            'Vuelo',
            'vuelto',
            'vuelve',
            'vuelven',
            'zureo'
        ];
        this.pronombres = [
            'él',
            'él',
            'él',
            'él',
            'él',
            'él',
            'él',
            'él',
            'ella',
            'Ella',
            'Ella',
            'ella',
            'ella',
            'ella',
            'Ella',
            'ellas',
            'ello',
            'Ellos',
            'nosotros',
            'nosotros',
            'nosotros',
            'nosotros',
            'nosotros',
            'nosotros',
            'todos',
            'todos',
            'todos',
            'Tu',
            'Tu',
            'Tu',
            'tu',
            'tu',
            'tu',
            'Tu',
            'Tu',
            'tu',
            'tu',
            'usted',
            'usted',
            'usted',
            'usted',
            'Vosotros',
            'VOSOTROS',
            'yo',
            'Yo',
            'yo',
            'Yo'
        ];
        this.conjunciones = [
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            '&',
            'a',
            'a',
            'A',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'A',
            'a',
            'a',
            'A',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'A',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'A',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'a',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al',
            'al ',
            'con',
            'con',
            'con',
            'con',
            'Con',
            'con',
            'con',
            'Con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'Con',
            'con',
            'con',
            'Con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'con',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'DE',
            'DE',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de',
            'de ',
            'de ',
            'de ',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'DEL',
            'del',
            'del',
            'DEL',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'del',
            'desde',
            'e',
            'e',
            'El',
            'EL',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'El',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el',
            'el ',
            'el ',
            'el ',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en',
            'En',
            'en',
            'en',
            'en',
            'en',
            'en',
            'en ',
            'en ',
            'LA',
            'la',
            'la',
            'LA',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'LA',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'la',
            'la',
            'la',
            'La',
            'la',
            'La',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'La',
            'la',
            'La',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'la',
            'La',
            'la',
            'la',
            'la',
            'LAS',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'Las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'las',
            'le',
            'le',
            'le',
            'le',
            'le',
            'les',
            'les',
            'Lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'Lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'lo',
            'Los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'Los',
            'los',
            'los',
            'los',
            'los',
            'LOS',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'Los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'Los',
            'los',
            'los',
            'los',
            'Los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'Los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los',
            'los ',
            'mi',
            'ni',
            'ni',
            'ni',
            'Ni',
            'ni',
            'Ni',
            'no',
            'no',
            'no',
            'no',
            'no',
            'No',
            'no',
            'No',
            'no',
            'no',
            'no',
            'No',
            'No',
            'no',
            'No',
            'no',
            'no',
            'no',
            'no',
            'no',
            'no',
            'no',
            'No',
            'no',
            'no',
            'no',
            'no',
            'no',
            'no',
            'no',
            'no',
            'no',
            'No',
            'no',
            'no',
            'no',
            'no',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'o',
            'O',
            'o',
            'O',
            'o',
            'OH',
            'para',
            'para',
            'para',
            'para',
            'para',
            'Para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'para',
            'pero',
            'pero',
            'pero',
            'Pero',
            'pero',
            'Pero',
            'Pero',
            'por',
            'POR',
            'por',
            'por',
            'por',
            'por',
            'Por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'Por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'por',
            'porque',
            'Porque',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'Que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que',
            'que ',
            'se',
            'Se',
            'se',
            'se',
            'Se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'Se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'se',
            'si',
            'si',
            'si',
            'si',
            'son',
            'son',
            'son',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'y',
            'Y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'Y',
            'Y',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'Y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y',
            'y ',
            'y ',
            'y ',
            'y ',
            'ya',
            'Ya',
            'ya',
            'ya',
            'ya'
        ];
        this.determinantes_disponibles = this.determinantes.slice();
        this.adverbios_disponibles = this.adverbios.slice();
        this.sustantivos_disponibles = this.sustantivos.slice();
        this.adjetivos_disponibles = this.adjetivos.slice();
        this.verbos_disponibles = this.verbos.slice();
        this.pronombres_disponibles = this.pronombres.slice();
        this.conjunciones_disponibles = this.conjunciones.slice();
        this.armarFrases();
        window.onYouTubeIframeAPIReady = function () {
            console.log("API READY");
            _this.api_loaded = true;
            _this.createPlayer();
        };
        if (window.YT) {
            this.api_loaded = true;
            this.createPlayer();
        }
        var interval = setInterval(function () {
            _this.time = Date.now();
            // console.log( this.time );
        }, 2500);
    };
    MondrigoComponent.prototype.createPlayer = function () {
        var _this = this;
        this.yt_player = new window.YT.Player('yt-player', {
            width: '560',
            height: '315',
            videoId: this.camaras[this.camara_activa].id,
            playerVars: {
                autoplay: 0,
                iv_load_policy: '3',
                rel: '0',
                controls: '0',
                modestbranding: '1',
                fs: 0,
                playsinline: 1
            },
            events: {
                onReady: function (ev) {
                    console.log("Player ready");
                },
                onError: function (ev) {
                    console.log("skipping video with error");
                    _this.next();
                },
                onStateChange: function (ev) {
                    // console.log("State change: ", ev );
                    // console.log( "Today :", this.today.getDate() );
                    if (ev.data == 2) {
                        // this.yt_player.playVideo();
                        if (_this.yt_player.isMuted()) {
                            // this.yt_player.unMute();
                        }
                        else {
                            // this.yt_player.mute();
                        }
                    }
                    if (ev.data == 0) {
                        // this.updateCurrentVideo();
                    }
                    if (ev.data == 1) {
                        // this.show_controls = true;
                    }
                }
            }
        });
    };
    MondrigoComponent.prototype.ngAfterViewInit = function () {
        if (!window.YT) {
            var doc = window.document;
            var playerApi = doc.createElement('script');
            playerApi.type = 'text/javascript';
            playerApi.src = 'https://www.youtube.com/iframe_api';
            doc.body.appendChild(playerApi);
        }
    };
    MondrigoComponent.prototype.start = function () {
        if (!this.playing && this.api_loaded) {
            this.playing = true;
            this.yt_player.playVideo();
        }
    };
    MondrigoComponent.prototype.activarCamara = function () {
        if (this.camaras[this.camara_activa].tipo == 'youtube') {
            this.yt_player.unMute();
            this.yt_player.loadVideoById(this.camaras[this.camara_activa].id);
        }
        else if (this.camaras[this.camara_activa].tipo == 'jpg') {
            this.yt_player.mute();
        }
        else if (this.camaras[this.camara_activa].tipo == 'mjpg') {
            this.yt_player.mute();
        }
    };
    MondrigoComponent.prototype.prev = function () {
        this.armarFrases();
        this.camara_activa = this.camara_activa - 1;
        if (this.camara_activa < 0) {
            this.camara_activa = this.camaras.length - 1;
        }
        this.activarCamara();
    };
    MondrigoComponent.prototype.next = function () {
        this.armarFrases();
        this.camara_activa = this.camara_activa + 1;
        if (this.camara_activa == this.camaras.length) {
            this.camara_activa = 0;
        }
        this.activarCamara();
    };
    MondrigoComponent.prototype.armarFrases = function () {
        var estructura = this.escogerEstructura();
        this.armarFrase(estructura[0]);
    };
    MondrigoComponent.prototype.armarFrase = function (estructura) {
        // console.log("Armando frase con estructura: ", estructura );
        var frase_1 = '';
        var frase_2 = '';
        if (estructura == 1) {
            frase_1 = this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.verbos_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles);
            frase_2 = this.escogerPalabra(this.verbos_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.conjunciones_disponibles) + " " +
                this.escogerPalabra(this.verbos_disponibles) + " " +
                this.escogerPalabra(this.adverbios_disponibles);
        }
        else if (estructura == 2) {
            frase_1 = this.escogerPalabra(this.adverbios_disponibles) + " " +
                this.escogerPalabra(this.determinantes_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.adverbios_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles);
            frase_2 = this.escogerPalabra(this.adverbios_disponibles) + " " +
                this.escogerPalabra(this.adverbios_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.conjunciones_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles);
        }
        else if (estructura == 3) {
            frase_1 = this.escogerPalabra(this.conjunciones_disponibles) + " " +
                this.escogerPalabra(this.pronombres_disponibles) + " " +
                this.escogerPalabra(this.verbos_disponibles) + " " +
                this.escogerPalabra(this.adverbios_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles);
            frase_2 = this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.verbos_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles) + " " +
                this.escogerPalabra(this.sustantivos_disponibles) + " " +
                this.escogerPalabra(this.adjetivos_disponibles);
        }
        this.frase_1 = frase_1;
        this.frase_2 = frase_2;
    };
    MondrigoComponent.prototype.escogerEstructura = function () {
        var opciones = [1, 2, 3];
        opciones.splice(Math.floor(Math.random() * opciones.length), 1);
        var seleccion = this.shuffle(opciones);
        // console.log("Estructura escogida: ", seleccion)
        return seleccion;
    };
    MondrigoComponent.prototype.escogerPalabra = function (tipo) {
        var random = Math.floor(Math.random() * tipo.length);
        var palabra = tipo[random];
        tipo.splice(random, 1);
        // console.log("Opcion elegida: ", palabra );
        // console.log("Opciones restantes: ", tipo );
        if (tipo.length == 0) {
            // console.log("Reiniciando familia");
            this.reiniciarFamilia(tipo);
        }
        return palabra;
    };
    MondrigoComponent.prototype.reiniciarFamilia = function (tipo) {
        if (tipo === this.determinantes_disponibles) {
            this.determinantes_disponibles = this.determinantes.slice();
        }
        if (tipo === this.adverbios_disponibles) {
            this.adverbios_disponibles = this.adverbios.slice();
        }
        if (tipo === this.sustantivos_disponibles) {
            this.sustantivos_disponibles = this.sustantivos.slice();
        }
        if (tipo === this.adjetivos_disponibles) {
            this.adjetivos_disponibles = this.adjetivos.slice();
        }
        if (tipo === this.verbos_disponibles) {
            this.verbos_disponibles = this.verbos.slice();
        }
        if (tipo === this.pronombres_disponibles) {
            this.pronombres_disponibles = this.pronombres.slice();
        }
        if (tipo === this.conjunciones_disponibles) {
            this.conjunciones_disponibles = this.conjunciones.slice();
        }
    };
    MondrigoComponent.prototype.shuffle = function (a) {
        var _a;
        for (var i = a.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            _a = [a[j], a[i]], a[i] = _a[0], a[j] = _a[1];
        }
        return a;
    };
    return MondrigoComponent;
}());
export { MondrigoComponent };
