import { Component, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss']
})
export class BirthdayComponent implements AfterViewInit, OnInit {
  public yt_player;
  active:boolean = true;
  videos:Array<any>;
  today:any;
  today_day:any;
  current_video:string;
  todays_count = 0;
  base_url: string = 'https://www.googleapis.com/youtube/v3';
  key:string = 'AIzaSyAfWD0uxD-vIaaeyX82fsJXLfiAMY7ogck';
  video_data:any;
  seen:Array<any> = [];
  cookie_seen:string = '';
  seen_day:any;
  api_loaded:boolean = false;

  constructor(
    private http: HttpClient,
    private appService: AppService ,
    private cookieService: CookieService,
  ) {

  }

  ngOnInit() {
    this.today = new Date().toLocaleString("en-US", {timeZone: "America/Bogota"});
    this.today = new Date( this.today );
    this.today_day = 14 //this.today.getDate();

    this.videos = [];
    this.videos['14'] = [
      'oZm3Xe3P_xA',
      '4qJPPLxRKhw',
      'fEsPrsUBoPQ',
      'xQ77UmknGUA',
      'obpbn1mCrz0',
      'Ig1vSHkAOtQ',
      'W-0S1baQP-E',
      'ZPc3QTExi7I',
      'hM1BPaz9YVw',
      'hzGuU5Zy_2c',
      'pWq4vtTtkOA',
      '1xNnayH8lrY',
      'tLe_IS6HZcQ',
      'GAaJiqcl5lc',
      'A6KXDQ9wnx4',
      'YhmlqEZF9fE',
      'EQNnM0XFaEQ',
      '0cPkExFkQ-4',
      'wB0VTlQoBhg',
      'jhrY8AEZp74',
      '3dWBIQinFZQ',
      'chVhrK154Vo',
      '5jZ5MkWrehs',
      'WSR9DwM2FkM',
      'JmZOqMF0WWE',
      'M_1fURv7qUs',
      'T1od3WuWiC0',
      '3r_mJz4qudw',
      'USsz1ALlLtU',
      'MAacIEUOB7c',
      '5F45xN5zQQc',
      '_FFdsdP6lAM',
      'wSxxysIgHCM',
      'IsdgkWtHsEk',
      'ECqKOJT_340',
      'ARvf1R4qR6w',
      'tyiY3z86iE4',
      'sogf-eLP6U0',
      'zx288bcfwz8',
      'XJEY8ACcIgc',
      'rPwJyjrq8zc',
      'vOs6A_D5ZWM',
      'c5TLWwKS6yc',
      'OjyvjcWEK64',
      'dznY4fqT6Sc',
      'FtMtheS9KQI',
      'AhLZM9nRBXo',
      'Ac0UeUX6ldc',
      '3A5OhK5S7a4',
      'uDAIoBYSTak',
      'ffk8o0V8PDE',
      'Mn-lY1mxtKI',
      'sMnhMZ_sAT4',
      'oCcgLciUzAs',
      '7Asi1x7j6nk',
      '4hWdotz59YA',
      'IOCKu_R1tEI',
      'K_fSioKvJkg',
      '4izZniS95Go',
      'umRjpcMsm4o',
      'l4myw6M_VTE',
      'CdSsjfKsqpc',
      'GSlhVUijCwM',
      'ac4HDsGxAFY',
      'I4paQt_yjT0',
      '3FXmvizrp4Q',
      'fak5svUgNfM',
      '7S7Tdsntnac',
      '9mOgpQHtwxY',
      'wXaOFVidnYs',
      'YJ_0s-qGZXk',
      'b38v3-TSkn0',
      '_L7-zJYo2Pc',
      'uO7CrxKHsrw',
      'O3GWLLr_UyY',
      'rHD7j1NQOj8',
      'jsqAbzKi4xI',
      'Gk02YAQQa_0',
      'KLMuCs-OnK8',
      'aZH09emEbh8',
      'rS8zJNnGTLg',
      'c3f_jnU5fEo',
      'P4Uqiwv-wWg',
      'WAR1srg-dR0',
      'BeZCH8vcXOw',
      '39hgJ_OJXd4',
      'YqEcJ9V39ms',
      '3vnJutriiKY',
      '0vRO1Tmpfkk',
      '1Yu_UmuCg80',
      '5lw8sncv3qo',
      'FVqN-XJ4JDk',
      'suryKwpRvMw',
      'gAj0tkFcCnI',
      'S_ndjsudFJQ',
      '31bwg9dwWgQ',
      'aq1siTaldok',
      'sh3Yn0QGT3Y',
      't5Ieln21bKU',
      'OcuFw73SZN8',
      'yS7ff1Nl7Q0',
      'SyFr00NcfFs',
      'u49dtR3zSzY',
      'qJOU8q9jTXQ',
      'BuYcxS_gulI',
      'vX8BauD5ypE',
      'kSKyYzijQvo',
      'USvCDHmf9Eg',
      'RO4lae78Qn0',
      'jXZiKJNb6l0',
      'WJ6avvqfwJc',
      'kfxpiXfUSZ4',
      'bqC42-6oQHo',
      'P-AG9aesrI4',
      'ZuEh4Vtj6TA',
      'YryKUzW3anw',
      'ehTKHcveTbY',
      '6_Nbi-1NLl0',
      'ZY-Sgz2jBkY',
      '9Wl5hbusG7A',
      'k1kPIuRrn0c',
      'imDJl0gXSR8',
      '2gbroSInZFM',
      'T38wvkYAZAw',
      'R3Zo1tYQO3A',
      '8kdt7rgX4Bw',
      'Smi2yowd1Ag',
      'QqoXLSsf_Fo',
      'RaZ8da2cRlI',
      '1DrFYyJZGIM',
      'EkuhTOMFjSQ',
      'BDVDIus7_VU',
      'rga4hhBIBew',
      'vuPUIVrrKSY',
      'GcO_C2j2uhA',
      'NMh3ORmjZ44',
      'NaVCwYKSCx0',
      'SdKaE0VJ-kY',
      'hBwO9CW4Qo8',
      'PDUH6d5rEKw',
      'yngPg6nJE-o',
      'yfHGhFYbsPU',
    //];
    //this.videos['15'] = [
      'eXPukGwP6ok',
      '80Py0EdnepQ',
      'b_ouNJ9n8pc',
      '6BTtVWft23o',
      'uu1GAT4CgBg',
      'QFaDeFdVTFY',
      'vInZfOSMRMQ',
      'LwcjBLHBpCM',
      'D0DoeJGgVLk',
      'eQtPgHT5UXA',
      'W_RyrRCnpl4',
      '39t0r5xC4eo',
      'O2q4O31qcFU',
      'PPOp8Q8Q66g',
      '4hI-qTuQuZA',
      'holrNhZ-xfA',
      'HEmUtBFzTko',
      'jKADJQj1i8Q',
      '-D8T6S-3pVA',
      'i0AnAnBWbmo',
      'RoUMCbcamj0',
      'hDUCKFmuQ4U',
      'CzSgLgOXev0',
      '7bkPRDg_bSw',
      'zePcYToLEko',
      '2wFeuQ-HaiU',
      'TV4FhhygoAo',
      '6dr_oDe30Qw',
      '2-O5yaNnR5U',
      'aCzQYdWHo6k',
      'dMJPtRsJrVw',
      'f01X3M7raac',
      'gMOLaG4AagM',
      'UQIj0armPAw',
      'p1V9oojDzd8',
      'UOqFj9QiB1U',
      'w1VPVlyeCrs',
      'nbq34dh8qwI',
      'iLoogHdanzM',
      'HwGdmODpuZE',
      'Jjv7r2oQPmk',
      '4qK21cKuExg',
      'SwRf2O02vug',
      'l5v-N7FvjEk',
      '4At6MDtL3II',
      'xIaUO3tjw8I',
      'BrfxWVjqma0',
      'ztTR60A0Xro',
      'l1lHY1MpHD4',
      'OdBdHf66DP4',
      'LiFpF6ZuJ50',
      '0GhAbHu5NBI',
      'YpI4pVQpV40',
      'EK2ufbN4OaQ',
      'EK2ufbN4OaQ',
      '_ChhHaLLlEo',
      'QyYR2dkqFPU',
      '9vxvB2OfnQk',
      'ePS-gBXTOFM',
      'z5zPRBCNhOo',
      'CXNWKKsjgE8',
      'GBnpNGvQeAA',
      'rf5GmgMDpNA',
      'PVYFVjT-B60',
      'W1K4oA_EYYM',
      'gBvaxHwU53A',
      'iQtNzOon4Yk',
      'nj_1GMsz0MQ',
      '7oREdaAUpG0',
      'QQcFlo1p3SU',
      'QjJeuMKmGd0',
      'Jnt7_SQYUHU',
      'fzkoFvrdxXA',
      'fZ-keRGVSOw',
      'O5sS1FZUwMQ',
      'dfxp-V1ibCQ',
      'YsQpjRy9d2M',
    //];
    //this.videos['16'] = [
      'had3H52brgo',
      'jOnlXhqc1k4',
      '81U1fksbESU',
      'tVElR5_Tgwc',
      'YhoZcqwMD6U',
      'wLSZxi_QGiA',
      '2f3FBtBiUtU',
      '1ogUNGRLK90',
      'XeAe_ajf98U',
      'tiQhVKRGVo0',
      'C5vgfGmdB8c',
      'vqUJONhjon0',
      '4uYobod32gc',
      'LFCr_Wl7jgY',
      '-lkRA2Ak6D8',
      'shTRBxg3oSA',
      'TMSZPkKju04',
      '-TIXhDEviwo',
      'sAJhLLZSQuA',
      'cs3JiJYIFFQ',
      '1U9pIk_L8Fw',
      'hynQep4m6cA',
      'wJtsumkCKk8',
      '-KqOH-a4KUw',
      'hgyMZEnoLj4',
      'KXgt4tskobQ',
      'sHgKgFGPD30',
      '2Mva3kwITis',
      'TXIDYaFko0Y',
      'dkDAMmG_ATo',
      'qGzQlURI23w',
      'Bx6uK50CRvU',
      'EDCV8xhzxNE',
      's4-LmCYEbW0',
      'A5b_3c4kx3s',
      'uLtA5PMQ51c',
      'y-7V08NwMcQ',
      'umvUXywxrLM',
      'F-a0fskVJ1Q',
      'VcHBe5HibB8',
      'qRXwqtHhWTI',
      '8SstSekbcmU',
      'FHX4QahLBe0',
      'ojnJ4qJ8Liw',
      'Okz1i4BFSc8',
      'UJqFEoe-hlc',
      'E9O41bXI89k',
      '0hfgRL8W4LY',
      'LCVOI8xbIJU',
      'QkEZFjuZW28',
      'hEYDBVi5_Dw',
      'TTHPv_XRDyY',
      '0kaq4U0XMiM',
      'S2SUkM8fcso',
      'JeuqViTYI20',
      'KTwEKLVOCyo',
      '2LrfG9i4QhU',
      '5W87Q8TeSr8',
      'JiLiGjhSy_M',
      'lRGS-knvLr4',
      'LdasLDf8hF4',
      'tbEls8SHR3s',
      'Hj6EevnfaZ4',
      'uwaIsQJIYCc',
      '0h4cgzLMUNU',
      'uaCyT4rT8nc',
      'D0z_yD-DmfM',
      '41vAeiJNRXI',
      'alZNFtw5cFQ',
      'WnJL3tacIZI',
      'U3ddmycYMoc',
      '8Vn53GEtsik',
      'UICWggz5anU',
      '_DOuUCb8L38',
      '-cha07lzGD4',
      'l8wERpaD5IM',
      'sGecWeWk6DE',
      'NQ3kqu90fZk',
      'U5I_-5890jQ',
      'Hz_gSKT45TM',
      'lnmPCbiH-l0',
      'BeVb0plq5SY',
      'l6BgSvZyZ1M',
      'oEITJnnC3Xc',
      'Mr3s4lUZ_LE',
      'sgxs7P9ohQo',
      'SDFUx9-PduI',
      'f7hI3jIQdJc',
      'JzNd5SqswxM',
    //];
    //this.videos['17'] = [
      'm9yAvMlZP_0',
      '8IY9TGrNv4Q',
      'NV0ZU2DF1PE',
      '6F7hsZOH33E',
      'qv7LopHi1z8',
      'PXKW5gGosGM',
      'WiLft7ai-60',
      '1_yoeoLddYI',
      'ae8Y6_UWQJo',
      'q0mfU0RwRuc',
      'BxCkCDsFEsg',
      'HfCJFENvTx8',
      'QAQ0uYw0okY',
      'Le7Zg2zO7hI',
      'zXCCvnMhu1k',
      '7X6VoeX4f78',
      '8-PAYan06Ts',
      'gJFzsehESKs',
      'AYIDx9D6MVY',
      'HfCJFENvTx8',
      '7oREdaAUpG0',
      '72Da-qYLuI0',
      '5kDDLjYnQaA',
      'BoqtRPDWKiU',
      'b1-rdU33ytA',
      'QY8D8y2uNmE',
      'ow7EYymStng',
      'wy2eyuuw7rI',
      'SLZ4pb4f-nw',
      'jzMqhosWRhM',
      'uoUMD2mXUSo',
      'MhqbhS6ENFE',
      'CluHpw3fWcQ',
      'XX7IhMoEZLo',
      'oivsGfTR1GA',
      'CXV-I0JtWao',
      '4T7t503OE_o',
      'tqLLvZ_aV2E',
      'cLO9WKJ2TPc',
      'hoOkBafTCIs',
      'GJ9KWUR-PSc',
      'j1hLoeNjZJU',
      'nro3c-GUtdg',
      'Kmhn0bT1CQE',
      'yChag9jC2qs',
      '5lsORhQv9TU',
      '9C5OvTXYQoo',
      'ZKfl5H6Q0Zs',
      'DZbE01Ky5dE',
      '4OwIYcbs2Hk',
      'FWjio8ZHUuU',
      'rpAZ1BGCNZI',
      'Kqal4_VTocA',
      'ZNaLBTyOMAQ',
      'PONDim-UXhI',
      'Hwq39TUle0k',
      'xWFYzHq8iyA',
      'KD5LUCQGG5M',
      'm-OEbYf_Qtk',
      '_W3XqroJ4hM',
      'zJq_KEG7YzE',
      'KbHVhsXFTq4',
      '3g2yE6tcqjA',
      'WfM6--tjnZA',
      '5bd9dUioKYU',
      '4jxphInT1T0',
      '0OdCwYEFm0k',
      'TnWOYtcNe1s',
      'cHp6DqjJC38',
      'h8AzQyiWnzQ',
      '3mKF8ZGijBM',
      'RjaSKZaD94M',
      'uCl9Wyhnhf4',
      'V8z91lF7WMM',
      'RbicuL8BUoQ',
      'lR2sq74rHdQ',
      'rwtzxhmKyrw',
      'DTGFapVPoXk',
      '1ks_aoCmD_U',
      'tUv2R0cQGI4',
      'W6-BIEW3h04',
      'w-2XhpVOqwY',
      'd3FSYnfQX2g',
      'FCR2zrpUBQE',
      '2V76zxpO0Os',
      'n_7Ddtz5DgA',
      '8gyBfqbwQH0',
      '2SGv_WdKLzc',
      'wgZkSDEKOXs',
      '4Ipo7OBb5-Q',
      'YZeNyQp6ooU',
      'VNeq6GI8tEY',
      'nZa1h3rh-Ig',
      'uyIqaXL_s7w',
    //];
    //this.videos['18'] = [
      'T1-VlOThQLo',
      'oCVzr9iAS3g',
      'uRu1__hHeCM',
      'uvXzu0xFlu0',
      'bP6Ulyvr3Is',
      '9-Gi_I_xWH0',
      'YzUSGavfKbQ',
      'yZDB-Q1hRNY',
      'A-ueR2bGCdU',
      'ZpqGjIlqUjY',
      'kg1GfNTyHPk',
      'TxOY5O0YEp4',
      '01uq4Bsrtf8',
      'SQaCne2ruDI',
      'CCmMATln-ac',
      'lpTwnCjtr8U',
      'jv3B85pi9Ec',
      'x7EP6aA0M_A',
      'GzMn8mnqztI',
      'gdLtSQzK9aw',
      'fFXiwg3CWXc',
      'HHxmZZ5-Oic',
      'WawTTsqTyE4',
      'MN8MccULdJ8',
      'E1JBi9XyO3o',
      '4EBvXjTwfrQ',
      'TtmSA0VK3Tg',
      'CticGBdAjGc',
      'CFbyaE0VBXc',
      'Q6_Zfk1fMqU',
      'SYrftCTkUos',
      'RNtPWZeF_uk',
      'Soxy0EwoOEg',
      'bshBu52xPLw',
      '4zFTcG8RWws',
      '4gKKpFzMQpk',
      '9LOGYX_9dr4',
      '4Uw-m7cpBPw',
      'dx5jyvHDIvI',
      '1STcXmjUS60',
      'vEJBnYHbprQ',
      'pamPO-YVveA',
      'h2AS0Pb7lJc',
      'ZFK_toiRPgo',
      'X5bEeqOasb0',
      'X5rKA2a34p4',
      'Dd68Upm3mJE',
      'ZIkDnayPvAA',
      'jHxbkjG3TKI',
      'wMHpU5zcV2Q',
      'hGstm2vd0G0',
      'PR4tfbMcPCQ',
      'j5u-je0fSOA',
      't-rc5EMhOP0',
      'GkXApD2d8wA',
      'MY7b-wylb7E',
      '1sQZjJ0bXJA',
      '0FFJjRLscmk',
      'M0dZ44iR-KA',
      'vnCwoLMn6IQ',
      'B97t8Xnf_go',
      'z9MYL53VX0Y',
      'kNHD6ZIxMOI',
      'Qo20ZRQw7NA',
      'u8lUTe-47Go',
      't7wwlqU3ZCE',
      'Yr7Hxail23g',
      '96WPvP24zds',
      'K-H6UqqZmuk',
      'kxuUVwoXgSQ',
      'ovsk_O0QzW4',
      'WdjqhWsw_7k',
      'kEny8mtjtxA',
      'FWb99Blv9tI',
      'A9wCjt6mpf0',
      'efWYZlxTeZg',
      'Uw5WzQjYgtQ',
      'EVF5GksRYkk',
      'kAV0FN4NTj4',
      'TQPHT4DhBBQ',
      '4DGBuKbJeCM',
      'TlO5vD5yXm0',
      'tLu3laT4JkU',
      'CBqmr8RqJ5w',
      '301RenZp9d4',
      'P6JUWnMucio',
      '4mKWA4g-kgs',
      'xr1_lkUcoPU',
    //];
    //this.videos['19'] = [
      'Jf0x1vouHSE',
      '3kY1kgsXhMU',
      'IVJRc0E6aVY',
      'mLJ-hCZeIDs',
      'BTfRPZU-Zvo',
      'SRRrRw0e6jg',
      '9-VgeB6MybA',
      'pTi0EXIEDKU',
      '9n2TX8n4Ngo',
      'cLjper-23ng',
      'l7V1RkgdhYM',
      'dXyF9Px--B0',
      'dx74KnmetWU',
      'mHib_ffhd00',
      '8n71ed6w8ko',
      'SzosOHXeTAg',
      'mo1LRoegpBA',
      '_jLVWK3pK8U',
      'FWmESiSfh1M',
      '82oYBzLQd6M',
      'hTUy4uIY_8g',
      '77V1UC3-0vw',
      'njx1WHnPRQY',
      'xYpagfJ8gbU',
      'ErfXztGqOCM',
      'kxZY6uV5QVQ',
      'himnOWAGqug',
      'TZHylOK6nq0',
      '2LdtL0is0Nk',
      'hQm7NFECQi4',
      'u9GUKWUecRI',
      'eJVGZEUxu9A',
      '-CY4njhOObQ',
      'z_0mfe0ySSU',
      'cMM9PStNh6g',
      'YxFshHdkZHg',
      'l4ymOvru7KA',
      'WTwAyjqRGNs',
      'LSxhiPHCGLo',
      'LRak1qk8DI4',
      'OInhSl9M0KQ',
      'cDpbzVec0HQ',
      'XiavmlxDrEA',
      '-AM5OYeZuWM',
      'NzUnKuvzHIU',
      'MJyybfz-kDs',
      'sNCgNbvKqvc',
      'tu9hLKD3yfg',
      'Au-I7zrEaL4',
      'VENSLtPkUXw',
      'BQXi1lU2o5A',
      'JrnZucZCXxY',
      'YPtxLx0gEmg',
      'qjZ-S9EqDUQ',
      'cRP1_aXXMko',
      'Hj324SKHHEs',
      'IUNI75QUPJ0',
      'EizTxo34yJI',
      'MI6Elmw3jCk',
      'tdsGc7acI60',
      'rgq81tNX9dw',
      '1GztagubGz8',
      'TZHylOK6nq0',
      'locaRRBz1Dk',
      'hQm7NFECQi4',
      'Obcf2BFUnOo',
      'QyFgehyzMME',
      'd587tSOS1Fw',
      'SAkbx_hMico',
      'WINjQEk3aZg',
      'IO9zpVUnI7g',
      'ZgV5goNxfr4',
      'ljqDUE6NUsc',
      'Prn5IdvDuvo',
      '7I9BUXiPgo8',
      'oo2nVqRZUSE',
      'Z6mGhn9bQkc',
      'UtK-7NKYn0I',
      'PJ4me3DPtcE',
      '_tYS5VemlDE',
      'UQIJaf2sIOw',
      'sP4Ol1jpEV4',
      'mV2HoW1kJ0I',
      'JJYY7USNbCg',
      'etlekvf-28s',
    // ];
    // this.videos['20'] = [
      'NoHKcn8x03E',
      'YqAc32ESJyQ',
      'kwWkuGjiOgw',
      'kFBW9ltw2NM',
      'wVleVKLF6Hc',
      'NoHKcn8x03E',
      'yCTZqT_dUj0',
      'Cs6y33ixWzQ',
      'eA30-fv_Pis',
      'LBXHTcvIu_o',
      'IGvu3j0t2hg',
      'ipalAO7BipA',
      'V83gox3HlI4',
      'vVGdYYfaA9c',
      'wumIfMq-IB8',
      'yggPUMFvlKQ',
      'xsXIbQyMig4',
      'YlKAmePbmKE',
      'OIxrRSSS-mI',
      'xtYXJV5z6YE',
      'ilEKrrP46T8',
      'h7CLPoZBiao',
      'DzeqZWdZkU0',
      '3zcHN8IwvCg',
      'ivOG53cqOFs',
      'huS5vt9oe-Q',
      'J3F3ZJCJHkU',
      'yqpVbA-HLCQ',
      '-ah3tTXUv_8',
      'Sdatt6dV8ZE',
      'OEvD-aEzvjI',
      'xxqBNVKRsKU',
      'fViHR7KYmjs',
      'Pg28Es5Dx4o',
      'qg4OmAFdTkI',
      'JsSSxM07UKA',
      'JPs8ewdcswY',
      'WZ9e8klTvak',
      'SC-pCVg1qmQ',
      'C5vI2DjsjAY',
      '6t-q_69dqcQ',
      'u12tQOgX-JQ',
      'NasH4TTlDks',
      'I8CjhPKDrA8',
      'Q7FCHZ0d9Sk',
      'FgUKuxkI6k4',
      'Bs2sfi98fME',
      'X5Awkrfc_fw',
      'dMeMxWK-tCk',
      '0Q-plVGEGIs',
      '-lxsnhlGMh8',
      'ZyrK4jI1l2Y',
      '1zHKYQMhHj4',
      'uH0kHfl1biA',
      'IadDjgTs-go',
      'OqUV12zsyJg',
      '2PJ-NiVHnsw',
      'H0rj1WsCBSc',
      'yqZlzR2jYqA',
      'rjY-QQOVcKQ',
      'QxDWIsV1s3o',
      'jOy1AjJ8uXY',
      'JKGVGzDVnhE',
      'qpzIe9QbLto',
      'CfTn3pUdj1U',
      '-37HR-JwVAU',
      'LB1zjiEC1lE',
      'ItWxGIkkAGE',
      'EJtqBdhNinw',
      'vFD2Nugn3_Y',
      '1doqVGjYFWE',
      'GtWMdu_45tk',
      'E7-9tKWYtYo',
      'vZLwUtXqs0Q',
      'G9Md1l949vU',
      '2LkPdDIZMVc',
      'LEGnnGPbHLs',
      '3PE_NCvFlTM',
      'EvNSo2axomg',
      'icQ7yuixABE',
      'WdN7GV_T61o',
      'uE2vUmLuSYE',
      'uratgqIbj-Q',
      'zBMt8oW7Qww',
      '4mx90pJPTfU',
      'MHqbxnSk6qI',
      'ZecFUBYJsgI',
      'kvk_uYOzkpk',
      '4bpCWFHnjJ0',
      'Laa5Z1ZgpDk',
      '9qqo-prkLaQ',
      'XPhJEhN-kxg',
      'Yksp6ROeZms',
      'SuHU3O7jbEc',
      '1iPXhqfpl20',
      'sQxhY4UPvU0',
      'JfOnQRqWNQw',
      'vf2EgAiWXGI',
      'VtkLh-l4LfY',
      'EqnlSLpnROE',
      'bvubZfC5_t0',
      '_1DDOnxwsKA',
      'jaV9sdt4R80',
      'UCrJdt3CSIU',
      'VdLgxC5_3F0',
      'Lvjq7AD0GJc'
    ];

    if( this.cookieService.check('day') ){
      this.seen_day = this.cookieService.get('day');
    }else{
      this.seen_day = this.cookieService.set('day', this.today_day );
      // console.log('Day set:', this.cookieService.get('day') );
    }

    if( this.seen_day < this.today_day ){
      this.seen_day = this.cookieService.set('day', this.today_day );
      // console.log('Day set:', this.cookieService.get('day') );
      this.seen = [];
      this.cookie_seen = JSON.stringify( this.seen );
      this.cookieService.set('seen', this.cookie_seen);
    }

    if( this.cookieService.check('seen') ){
      // console.log("Cookie seen exists.");
      this.cookie_seen = this.cookieService.get('seen');
      this.seen = JSON.parse( this.cookie_seen );
      // console.log("Decoded cookie value as:", this.seen );
    }else{
      // console.log("Cookie seen dosen't.");
      this.cookie_seen = JSON.stringify( this.seen );
      this.cookieService.set('seen', this.cookie_seen);
      // console.log("Initial cookie value set as:", this.cookie_seen );
    }

	(window as any).onYouTubeIframeAPIReady = () => {
		// console.log("API READY");
    this.api_loaded = true;
		this.createPlayer();
	};

  if( (window as any).YT ){
    this.api_loaded = true;
    this.setInitialVideo();
    this.createPlayer();
  }

  }

  ngAfterViewInit() {
	  if( !(window as any).YT ){
		let doc = window.document;
    	let playerApi = doc.createElement('script');
    	playerApi.type = 'text/javascript';
    	playerApi.src = 'https://www.youtube.com/iframe_api';
    	doc.body.appendChild(playerApi);
	  }

		this.setInitialVideo();
	}

  setInitialVideo(){
    if( this.videos[ 14 ].length > 0 ){
      let rand = Math.floor(Math.random()*this.videos[ this.today_day ].length );
      this.todays_count = rand;
      this.current_video = this.videos[ this.today_day ][ rand ];
      // console.log("Initial video set: ", this.current_video );
      if( this.videos[ this.today_day ].length == this.seen.length ){
        // console.log("All seen restarting");
        this.seen = [];
        this.cookie_seen = JSON.stringify( this.seen );
        this.cookieService.set('seen', this.cookie_seen);
        this.setInitialVideo();
      }else if( this.seen.indexOf( this.current_video ) > -1 ){
        // console.log("already seen: ", this.current_video );
        this.setInitialVideo();
      }else{
        this.updateData( this.current_video );
      }
    }else{
      // console.log("Date inactive");
      this.active = false;
    }
    if( (window as any).YT ){
      this.createPlayer();
    }
  }

  updateSeen( id ){
    // console.log("Updating seen with ID: ", id );
    this.seen.push( id );
    this.cookie_seen = JSON.stringify( this.seen );
    this.cookieService.set('seen', this.cookie_seen);
  }

  updateCurrentVideo(){
    this.today = new Date().toLocaleString("en-US", {timeZone: "America/Bogota"});
    this.today = new Date( this.today );
    if( this.videos[ this.today_day ].length > 0 ){
      //if( this.today_day < this.today.getDate() ){
        // console.log("New day");
      //  this.seen = [];
      //  this.cookie_seen = JSON.stringify( this.seen );
      //  this.cookieService.set('seen', this.cookie_seen);
      //  this.today_day = this.today.getDate();
      //  this.todays_count = 0;
      //  this.current_video = this.videos[ this.today_day ][ 0 ];
      //}else{
        if( this.todays_count < this.videos[ this.today_day ].length ){
          this.todays_count = this.todays_count+1;
          this.current_video = this.videos[ this.today_day ][ this.todays_count ];
        }else{
          this.todays_count = 0;
          this.current_video = this.videos[ this.today_day ][ 0 ];
        }
      //}
      if( this.videos[ this.today_day ].length == this.seen.length ){
        // console.log("All seen restarting");
        this.seen = [];
        this.cookie_seen = JSON.stringify( this.seen );
        this.cookieService.set('seen', this.cookie_seen);
        this.updateCurrentVideo();
      }else if( this.seen.indexOf( this.current_video ) > -1 ){
        // console.log("already seen: ", this.current_video );
        this.updateCurrentVideo();
      }else{
        this.updateData( this.current_video );
        this.yt_player.loadVideoById( this.current_video );
      }
    }else{
      // console.log("Date inactive");
      this.active = false;
    }
  }

  createPlayer() {
    this.yt_player = new (window as any).YT.Player('yt-player', {
			width: '560',
			height: '315',
  videoId: this.current_video,
			playerVars: {
				autoplay: 1,
				iv_load_policy: '3',
				rel: '0',
	controls: '0',
	modestbranding: '1'
			},
			events: {
	onReady: (ev) => {
	  // console.log("Player ready");
	  this.yt_player.playVideo();
	},
        onError: (ev) => {
          console.log("skipping video with error");
          this.updateCurrentVideo();
        },
        onStateChange: (ev) => {
				// console.log("State change: ", ev );
	  // console.log( "Today :", this.today.getDate() );
	  if( ev.data == 2 ){
		this.yt_player.playVideo();
		if( this.yt_player.isMuted() ){
		  this.yt_player.unMute();
		}else{
		  this.yt_player.mute();
		}
	  }

	  if( ev.data == 0 ){
		this.updateCurrentVideo();
	  }

    if( ev.data == 1 ){
      this.updateSeen( this.current_video );
    }
				}
			}
		});
	}

  getVideoData( id ): Observable<any> | any {
    return this.http.get( this.base_url+'/videos?id='+id+'&key='+this.key+'&part=snippet' );
  }

  updateData( id ){
    this.getVideoData( id ).subscribe( (data) => {
      this.video_data = data;
      // console.log("Got video data: ", this.video_data );
    });
  }

}
