import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-waitingroom',
  templateUrl: './waitingroom.component.html',
  styleUrls: ['./waitingroom.component.scss']
})
export class WaitingroomComponent implements OnInit {
	@ViewChild('audioplayer') audioplayer: any;
	@Input()
    set active(active: boolean) {
      this.isActive = active;
	  console.log("Changed state");
	  if( !this.isActive ){
		  this.audioplayer.nativeElement.stop();
		  console.log("Stopped audio");
	  }
    }
  isActive: any
  audio:any;
  started:boolean = false;
  api_loaded:boolean = false;
  show_controls:boolean = false;
  songs:Array<any> = [];
  titles:Array<any> = [];
  played:Array<any> = [];
  videos:Array<any> = [];
  current_video:any;
  current_song:any;
  yt_player:any;
  timeout_init:any;
  timeout_hide:any;
  marquee_display:boolean = false;

  constructor() { }

  ngOnInit() {
  	let AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
  	let audioCtx = new AudioContext();
	this.songs = [
		'song1',
		'song2',
		'song3',
		'song4',
		'song5',
		'song6',
		'song7',
		'song8',
		'song9',
		'song10',
		'song11',
		'song12',
		'song13',
		'song14',
		'song15',
		'song16',
    'song17',
    'song18'
	];
	this.titles = [
		'Agar Agar - I Am That Guy',
		'Everybody Loves The Sunshine - Roy Ayers Ubiquity',
		'Spaceships - Tank and the bangas',
		'France Gall - Les yeux bleus',
		'No sense song - Charles Chaplin',
		'El Vistado - Juana Molina',
		'Tindersticks - Trouble Every Day',
		'Angèle - Je veux tes yeux',
		'Daniel Norgren - Howling Around My Happy Home',
		'Daniel Norgren - Moonshine Got Me',
		'Julio Jaramillo - Desde que te fuiste',
		'Delinquent habits - Tres delincuentes',
		'CYRIUS - YOKOHAMA',
		'Guadalupe Pineda - Historia de una amor',
		'João Gilberto - Maria Ninguém',
		'Roberto Carlos - Qué será de ti'
	];
	this.videos = [
    'IisHeJRXU3M',
    'RXvojVo8DDI',
    'W8TWCJ8ulAQ',
    'NVCi9yYwRCY',
    'z5F1a7_dsrs',
    'hWbt2eVCEe8',
    'xoLY8-WU-Wo',
    'ZOn7EVp9248',
    'eMe89SxutTU',
    'aaA8T2G79fk'
	];
	this.current_video = 0;
	this.current_song = this.getRandom();

	(window as any).onYouTubeIframeAPIReady = () => {
		console.log("API READY");
		this.api_loaded = true;
		// this.createPlayer();
	};

  }

  setTimers(){
	  this.timeout_init = setTimeout( () => {
  		if( !this.marquee_display ){
  			this.marquee_display = true;
  			this.timeout_hide = setTimeout( () => {
  				if( this.marquee_display ){
  					this.marquee_display = false;
					this.setTimers();
  				}
  			}, 30000);
  		}
  	}, 45000 );
  }

  ngAfterViewInit() {
	if( !(window as any).YT ){
		let doc = window.document;
		let playerApi = doc.createElement('script');
		playerApi.type = 'text/javascript';
		playerApi.src = 'https://www.youtube.com/iframe_api';
		doc.body.appendChild(playerApi);
	}
	this.audioplayer.nativeElement.addEventListener("ended", () => {
		this.audioplayer.nativeElement.currentTime = 0;
		this.randomAudio();
		console.log("ended");
	});
	// this.setInitialVideo();
  }

  playAudio( id ){
	this.audioplayer.nativeElement.src = "https://45sna.com/canciones/"+this.songs[ id ]+".mp3";
	this.audioplayer.nativeElement.load();
	this.audioplayer.nativeElement.play()
	this.played.push( id );
  }

  randomAudio(){
	  if( this.played.length == this.songs.length ){
		  this.played = [];
	  }
	  let random = this.getRandom();
	  if( this.played.indexOf( random ) > -1 ){
		  this.randomAudio();
	  }else{
		  this.current_song = random;
		  this.playAudio( this.current_song );
	  }
  }

  getRandom(){
	  let rand = Math.floor( Math.random()*this.songs.length - 1 );
	  return rand;
  }

  start(){
	  console.log("Starting... ");
	  if( (window as any).YT ){
		    this.started = true;
			this.createPlayer();
	  }else{
		  console.log("Can't find YT");
	  }
  }

  createPlayer() {
    this.yt_player = new (window as any).YT.Player('yt-player', {
			width: '560',
			height: '315',
  			videoId: this.videos[ this.current_video ],
			playerVars: {
				autoplay: 0,
				iv_load_policy: '3',
				rel: '0',
				controls: '0',
				modestbranding: '1',
				fs: 0,
				playsinline: 1
			},
			events: {
				onReady: (ev) => {
				  console.log("Player ready");
				  this.yt_player.playVideo();
				  this.playAudio( this.current_song );
				},
        onError: (ev) => {
          console.log("skipping video with error");
          this.next();
        },
				onStateChange: (ev) => {
				// console.log("State change: ", ev );
				  // console.log( "Today :", this.today.getDate() );
				  if( ev.data == 2 ){
					// this.yt_player.playVideo();
					if( this.yt_player.isMuted() ){
					 // this.yt_player.unMute();
					}else{
					 // this.yt_player.mute();
					}
				  }

	  if( ev.data == 0 ){
		// this.updateCurrentVideo();
	  }

    if( ev.data == 1 ){
      this.show_controls = true;
	  this.setTimers();
    }
				}
			}
		});
	}

	prev(){
		console.log("Prev");
		this.current_video = this.current_video - 1;
		if( this.current_video < 0 ){
			this.current_video = this.videos.length - 1;
		}
		this.yt_player.loadVideoById( this.videos[ this.current_video ] );
		this.randomAudio();
	}

	next(){
		console.log("Next");
		this.current_video = this.current_video + 1;
		if( this.current_video > this.videos.length - 1){
			this.current_video = 0;
		}
		this.yt_player.loadVideoById( this.videos[ this.current_video ] );
		this.randomAudio();
	}

	randomSong(){}

}
