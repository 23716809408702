/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exposicion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../pipes/translate.pipe";
import * as i5 from "../google.services";
import * as i6 from "../app.service";
import * as i7 from "../pipes/keep-html.pipe";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./exposicion.component";
import * as i10 from "../wpbackend.service";
var styles_ExposicionComponent = [i0.styles];
var RenderType_ExposicionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExposicionComponent, data: {} });
export { RenderType_ExposicionComponent as RenderType_ExposicionComponent };
function View_ExposicionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.caption)))); _ck(_v, 2, 0, currVal_0); }); }
function View_ExposicionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "li", [], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "expo_single": 0, "about_open": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_3)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["class", "about_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ((_v.context.$implicit.parent = !_v.context.$implicit.parent) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Sobre el proyecto "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "flecha"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2193"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "about_content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(17, 1)], function (_ck, _v) { var currVal_1 = _ck(_v, 2, 0, true, _v.context.$implicit.parent); _ck(_v, 1, 0, currVal_1); var currVal_4 = _ck(_v, 5, 0, ("/exposicion/" + _v.context.$implicit.slug)); _ck(_v, 4, 0, currVal_4); var currVal_6 = _v.context.$implicit.acf.timecodes; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = ("expoid-" + _v.context.$implicit.id); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 4).target; var currVal_3 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.title.rendered)))); _ck(_v, 6, 0, currVal_5); var currVal_7 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.content.rendered)); _ck(_v, 16, 0, currVal_7); }); }
function View_ExposicionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "exposiciones_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ExposicionComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.exposiciones$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ExposicionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["id", "nosound"], ["src", "../../assets/images/nosound.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activar_sonido() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_ExposicionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["id", "player"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["myVideo", 1]], null, 4, "video", [["autobuffer", ""], ["autoplay", ""], ["height", "100%"], ["id", "myvideo"], ["loop", ""], ["width", "100%"]], [[8, "muted", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "source", [["type", "video/webm"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "source", [["type", "video/ogg"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support HTML5 video."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.muted; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.muted; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.expo$.acf.url_de_video + ".mp4"); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.expo$.acf.url_de_video + ".webmhd.webm"); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.expo$.acf.url_de_video + ".oggtheora.ogv"); _ck(_v, 6, 0, currVal_4); }); }
function View_ExposicionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_ExposicionComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "br", [], null, null, null, null, null))], null, null); }
function View_ExposicionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.seekTo(_v.context.$implicit.tiempo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.doble_espacio == "Activar"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_v.context.$implicit.doble_espacio == "Activar"); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.caption)))); _ck(_v, 2, 0, currVal_0); }); }
function View_ExposicionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "expo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [["class", "titulo"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_7)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.expo$.acf.timecodes; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.expo$.title.rendered)))); _ck(_v, 2, 0, currVal_0); }); }
export function View_ExposicionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TranslatePipe, [i5.GoogleService, i6.AppService]), i1.ɵpid(0, i7.EscapeHtmlPipe, [i8.DomSanitizer]), i1.ɵqud(671088640, 1, { myVideo: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExposicionComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.exposicion; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.expo$; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.expo$; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ExposicionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-exposicion", [], null, null, null, View_ExposicionComponent_0, RenderType_ExposicionComponent)), i1.ɵdid(1, 4308992, null, 0, i9.ExposicionComponent, [i3.ActivatedRoute, i3.Router, i10.WpbackendService, i6.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExposicionComponentNgFactory = i1.ɵccf("app-exposicion", i9.ExposicionComponent, View_ExposicionComponent_Host_0, {}, {}, []);
export { ExposicionComponentNgFactory as ExposicionComponentNgFactory };
