import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cine',
  templateUrl: './cine.component.html',
  styleUrls: ['./cine.component.scss']
})
export class CineComponent implements OnInit {
  api_loaded:boolean = false;
  yt_player:any;
  player_ready:boolean = false;
  started:boolean = false;
  show_mask:boolean = false;
  fondo:any = 1;
  h:any;
  m:any;
  s:any;
  cs:any;

  constructor() { }

  ngOnInit() {
	  (window as any).onYouTubeIframeAPIReady = () => {
		  console.log("API READY");
		  this.api_loaded = true;
		  this.createPlayer();
	  };

	  if( (window as any).YT ){
		  this.api_loaded = true;
		  this.createPlayer();
	  }
	  this.fondo = this.getRandomArbitrary(1, 6);
  }

  ngAfterViewInit() {
	if( !(window as any).YT ){
		let doc = window.document;
		let playerApi = doc.createElement('script');
		playerApi.type = 'text/javascript';
		playerApi.src = 'https://www.youtube.com/iframe_api';
		doc.body.appendChild(playerApi);
	}
	let interval = setInterval( () => {
		this.startTime();
	}, 15);
  }

  start(){
	  if( !this.started && this.player_ready ){
		  this.started = true;
		  this.yt_player.playVideo();
		  this.yt_player.seekTo( this.getRandomArbitrary( 1, 1620 ) );
	  }
  }

  getRandomArbitrary(min, max) {
	return Math.floor( Math.random() * (max - min) + min );
  }

  createPlayer() {
    this.yt_player = new (window as any).YT.Player('yt-player', {
			width: '560',
			height: '315',
  			videoId: '9EIC1jqGsCM',
			playerVars: {
				autoplay: 0,
				iv_load_policy: '3',
				rel: '0',
				controls: '0',
				modestbranding: '1',
				fs: 0,
				playsinline: 1
			},
			events: {
				onReady: (ev) => {
				  console.log("Player ready");
				  this.player_ready = true;
				   this.yt_player.playVideo();
				  // this.playAudio( this.current_song );
				},
				onStateChange: (ev) => {
				// console.log("State change: ", ev );
				  // console.log( "Today :", this.today.getDate() );
          if( ev.data == 1 ){
            this.started = true;
          }
				  if( ev.data == 2 ){
					// this.yt_player.playVideo();
					if( this.yt_player.isMuted() ){
					 // this.yt_player.unMute();
					}else{
					 // this.yt_player.mute();
					}
				  }

	  if( ev.data == 0 ){
		this.yt_player.playVideo();
	  }

    if( ev.data == 1 ){
      this.show_mask = true;
	  // this.setTimers();
    }
				}
			}
		});
	}

	checkTime(i) {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	startTime() {
		let today = new Date();
		let h = today.getHours();
		let m = today.getMinutes();
		let s = today.getSeconds();
		let cs = Math.floor( today.getMilliseconds() / 15 );
		// add a zero in front of numbers<10
		this.h = this.checkTime(h);
		this.m = this.checkTime(m);
		this.s = this.checkTime(s);
		this.cs = this.checkTime(cs);
	}

}
