
<div id="popup_container" *ngIf="visible">
  <div id="popup">
    <div id="popup_topbar">
      <span class="close_button" (click)="toggle()">
       <img src="../assets/images/popup_close.png"></span>
    </div>
    <div id="popup_content">
        <img class="alert" src="../assets/images/popup_alert.png">
      {{ messages[ messages_shown - 1 ] | translate | async }}
      <div id="popup_buttons">
        <a (click)="toggle()">{{ 'ok' | translate | async }}</a>
        <a href="https://es.wikipedia.org/wiki/Principio_cosmol%C3%B3gico" target="_blank">{{ 'info' | translate | async }}</a>
        <a [routerLink]="['/oddhorizons']" (click)="toggle()">{{ 'Viajar' | translate | async }}</a>
      </div>
    </div>
  </div>
</div>
