<div id="app_container" [ngClass]="class">
<app-persiana></app-persiana>
<app-debora></app-debora>

  <app-cursores></app-cursores>
  <app-popup></app-popup>
  <!-- <div *ngIf="class != 'negro reves'" id="lang_select_cont">

     <select id="lang-select" (change)="onChangeLang($event)">
      <option value=es>Español</option>
      <option [attr.selected]="current_lang == 'af' ? true : null" value=af>Afrikaans</option>
      <option [attr.selected]="current_lang == 'sq' ? true : null" value=sq>Albanian</option>
      <option [attr.selected]="current_lang == 'am' ? true : null" value=am>Amharic</option>
      <option [attr.selected]="current_lang == 'ar' ? true : null" value=ar>Arabic</option>
      <option [attr.selected]="current_lang == 'hy' ? true : null" value=hy>Armenian</option>
      <option [attr.selected]="current_lang == 'az' ? true : null" value=az>Azerbaijani</option>
      <option [attr.selected]="current_lang == 'eu' ? true : null" value=eu>Basque</option>
      <option [attr.selected]="current_lang == 'be' ? true : null" value=be>Belarusian</option>
      <option [attr.selected]="current_lang == 'bn' ? true : null" value=bn>Bengali</option>
      <option [attr.selected]="current_lang == 'bs' ? true : null" value=bs>Bosnian</option>
      <option [attr.selected]="current_lang == 'bg' ? true : null" value=bg>Bulgarian</option>
      <option [attr.selected]="current_lang == 'ca' ? true : null" value=ca>Catalan</option>
      <option [attr.selected]="current_lang == 'ceb' ? true : null" value=ceb>Cebuano</option>
      <option [attr.selected]="current_lang == 'ny' ? true : null" value=ny>Chichewa</option>
      <option [attr.selected]="current_lang == 'zh-CN' ? true : null" value=zh-CN>Chinese</option>
      <option [attr.selected]="current_lang == 'co' ? true : null" value=co>Corsican</option>
      <option [attr.selected]="current_lang == 'hr' ? true : null" value=hr>Croatian</option>
      <option [attr.selected]="current_lang == 'cs' ? true : null" value=cs>Czech</option>
      <option [attr.selected]="current_lang == 'da' ? true : null" value=da>Danish</option>
      <option [attr.selected]="current_lang == 'nl' ? true : null" value=nl>Dutch</option>
      <option [attr.selected]="current_lang == 'en' ? true : null" value=en>English</option>
      <option [attr.selected]="current_lang == 'eo' ? true : null" value=eo>Esperanto</option>
      <option [attr.selected]="current_lang == 'et' ? true : null" value=et>Estonian</option>
      <option [attr.selected]="current_lang == 'tl' ? true : null" value=tl>Filipino</option>
      <option [attr.selected]="current_lang == 'fi' ? true : null" value=fi>Finnish</option>
      <option [attr.selected]="current_lang == 'fr' ? true : null" value=fr>French</option>
      <option [attr.selected]="current_lang == 'fy' ? true : null" value=fy>Frisian</option>
      <option [attr.selected]="current_lang == 'gl' ? true : null" value=gl>Galician</option>
      <option [attr.selected]="current_lang == 'ka' ? true : null" value=ka>Georgian</option>
      <option [attr.selected]="current_lang == 'de' ? true : null" value=de>German</option>
      <option [attr.selected]="current_lang == 'el' ? true : null" value=el>Greek</option>
      <option [attr.selected]="current_lang == 'gu' ? true : null" value=gu>Gujarati</option>
      <option [attr.selected]="current_lang == 'ht' ? true : null" value=ht>Haitian Creole</option>
      <option [attr.selected]="current_lang == 'ha' ? true : null" value=ha>Hausa</option>
      <option [attr.selected]="current_lang == 'haw' ? true : null" value=haw>Hawaiian</option>
      <option [attr.selected]="current_lang == 'iw' ? true : null" value=iw>Hebrew</option>
      <option [attr.selected]="current_lang == 'hi' ? true : null" value=hi>Hindi</option>
      <option [attr.selected]="current_lang == 'hmn' ? true : null" value=hmn>Hmong</option>
      <option [attr.selected]="current_lang == 'hu' ? true : null" value=hu>Hungarian</option>
      <option [attr.selected]="current_lang == 'is' ? true : null" value=is>Icelandic</option>
      <option [attr.selected]="current_lang == 'ig' ? true : null" value=ig>Igbo</option>
      <option [attr.selected]="current_lang == 'id' ? true : null" value=id>Indonesian</option>
      <option [attr.selected]="current_lang == 'ga' ? true : null" value=ga>Irish</option>
      <option [attr.selected]="current_lang == 'it' ? true : null" value=it>Italian</option>
      <option [attr.selected]="current_lang == 'ja' ? true : null" value=ja>Japanese</option>
      <option [attr.selected]="current_lang == 'jw' ? true : null" value=jw>Javanese</option>
      <option [attr.selected]="current_lang == 'kn' ? true : null" value=kn>Kannada</option>
      <option [attr.selected]="current_lang == 'kk' ? true : null" value=kk>Kazakh</option>
      <option [attr.selected]="current_lang == 'km' ? true : null" value=km>Khmer</option>
      <option [attr.selected]="current_lang == 'ko' ? true : null" value=ko>Korean</option>
      <option [attr.selected]="current_lang == 'ku' ? true : null" value=ku>Kurdish (Kurmanji)</option>
      <option [attr.selected]="current_lang == 'ky' ? true : null" value=ky>Kyrgyz</option>
      <option [attr.selected]="current_lang == 'lo' ? true : null" value=lo>Lao</option>
      <option [attr.selected]="current_lang == 'la' ? true : null" value=la>Latin</option>
      <option [attr.selected]="current_lang == 'lv' ? true : null" value=lv>Latvian</option>
      <option [attr.selected]="current_lang == 'lt' ? true : null" value=lt>Lithuanian</option>
      <option [attr.selected]="current_lang == 'lb' ? true : null" value=lb>Luxembourgish</option>
      <option [attr.selected]="current_lang == 'mk' ? true : null" value=mk>Macedonian</option>
      <option [attr.selected]="current_lang == 'mg' ? true : null" value=mg>Malagasy</option>
      <option [attr.selected]="current_lang == 'ms' ? true : null" value=ms>Malay</option>
      <option [attr.selected]="current_lang == 'ml' ? true : null" value=ml>Malayalam</option>
      <option [attr.selected]="current_lang == 'mt' ? true : null" value=mt>Maltese</option>
      <option [attr.selected]="current_lang == 'mi' ? true : null" value=mi>Maori</option>
      <option [attr.selected]="current_lang == 'mr' ? true : null" value=mr>Marathi</option>
      <option [attr.selected]="current_lang == 'mn' ? true : null" value=mn>Mongolian</option>
      <option [attr.selected]="current_lang == 'my' ? true : null" value=my>Myanmar (Burmese)</option>
      <option [attr.selected]="current_lang == 'ne' ? true : null" value=ne>Nepali</option>
      <option [attr.selected]="current_lang == 'no' ? true : null" value=no>Norwegian</option>
      <option [attr.selected]="current_lang == 'ps' ? true : null" value=ps>Pashto</option>
      <option [attr.selected]="current_lang == 'fa' ? true : null" value=fa>Persian</option>
      <option [attr.selected]="current_lang == 'pl' ? true : null" value=pl>Polish</option>
      <option [attr.selected]="current_lang == 'pt' ? true : null" value=pt>Portuguese</option>
      <option [attr.selected]="current_lang == 'pa' ? true : null" value=pa>Punjabi</option>
      <option [attr.selected]="current_lang == 'ro' ? true : null" value=ro>Romanian</option>
      <option [attr.selected]="current_lang == 'ru' ? true : null" value=ru>Russian</option>
      <option [attr.selected]="current_lang == 'sm' ? true : null" value=sm>Samoan</option>
      <option [attr.selected]="current_lang == 'gd' ? true : null" value=gd>Scots Gaelic</option>
      <option [attr.selected]="current_lang == 'sr' ? true : null" value=sr>Serbian</option>
      <option [attr.selected]="current_lang == 'st' ? true : null" value=st>Sesotho</option>
      <option [attr.selected]="current_lang == 'sn' ? true : null" value=sn>Shona</option>
      <option [attr.selected]="current_lang == 'sd' ? true : null" value=sd>Sindhi</option>
      <option [attr.selected]="current_lang == 'si' ? true : null" value=si>Sinhala</option>
      <option [attr.selected]="current_lang == 'sk' ? true : null" value=sk>Slovak</option>
      <option [attr.selected]="current_lang == 'sl' ? true : null" value=sl>Slovenian</option>
      <option [attr.selected]="current_lang == 'so' ? true : null" value=so>Somali</option>
      <option [attr.selected]="current_lang == 'es' ? true : null" value=es>Spanish</option>
      <option [attr.selected]="current_lang == 'su' ? true : null" value=su>Sundanese</option>
      <option [attr.selected]="current_lang == 'sw' ? true : null" value=sw>Swahili</option>
      <option [attr.selected]="current_lang == 'sv' ? true : null" value=sv>Swedish</option>
      <option [attr.selected]="current_lang == 'tg' ? true : null" value=tg>Tajik</option>
      <option [attr.selected]="current_lang == 'ta' ? true : null" value=ta>Tamil</option>
      <option [attr.selected]="current_lang == 'te' ? true : null" value=te>Telugu</option>
      <option [attr.selected]="current_lang == 'th' ? true : null" value=th>Thai</option>
      <option [attr.selected]="current_lang == 'tr' ? true : null" value=tr>Turkish</option>
      <option [attr.selected]="current_lang == 'uk' ? true : null" value=uk>Ukrainian</option>
      <option [attr.selected]="current_lang == 'ur' ? true : null" value=ur>Urdu</option>
      <option [attr.selected]="current_lang == 'uz' ? true : null" value=uz>Uzbek</option>
      <option [attr.selected]="current_lang == 'vi' ? true : null" value=vi>Vietnamese</option>
      <option [attr.selected]="current_lang == 'cy' ? true : null" value=cy>Welsh</option>
      <option [attr.selected]="current_lang == 'xh' ? true : null" value=xh>Xhosa</option>
      <option [attr.selected]="current_lang == 'yi' ? true : null" value=yi>Yiddish</option>
      <option [attr.selected]="current_lang == 'yo' ? true : null" value=yo>Yoruba</option>
      <option [attr.selected]="current_lang == 'zu' ? true : null" value=zu>Zulu</option>
    </select>
  </div> -->

  <app-postit *ngIf="show_postit"></app-postit>

<div id="header">
  <!-- <label for="lang-select">choose language: <span class="has_tooltip" [tooltip]="'Traducido automáticamente por Google' | translate | async" [options]="tooltip_opts">*</span> </label> -->
  <div *ngIf="class == 'negro reves'" id="lang_select_cont">
    <select id="lang-select" (change)="onChangeLangReves($event)">
      <option [attr.selected]="current_lang == 'en' ? true : null" value="en">English</option>
      <option [attr.selected]="current_lang == 'es' ? true : null" value="es">Spanish</option>
    </select>
  </div>

  <div class="imagesvg bringfront" id="logo_45sna"  routerLinkActive="active" [routerLink]="['/']" (click)="randomTitle2();" [inlineSVG]="inline_title2"></div>
  <div class="bringfront" id="logo_alcaldia" ><a href="https://bogota.gov.co/" target="_blank"><img src="../assets/images/alcaldia_logo.svg" ></a></div>
  <div class="imagesvg bringfront" id="logos" ><a href="http://www.mincultura.gov.co/Paginas/default.aspx" target="_blank"><img src="../assets/images/min_cultura.svg" ></a></div>

  <div id="redes" >
    <a href="https://www.facebook.com/45SNA/" target="_blank"><img src="../assets/images/fb.svg" ></a>
    <a href="https://www.instagram.com/45salonnacionaldeartistas/" target="_blank" ><img src="../assets/images/instagram.svg"  ></a>
    <a href="https://twitter.com/45sna_" target="_blank" ><img  class="tw" src="../assets/images/tw.svg"  ></a>
  </div>

  <div id="counter" *ngIf="counter != 0">
       <div class="ojito" [inlineSVG]="'../assets/images/ver.svg'"></div> <p> <span>{{ 'Este sitio web ha sido visto' | translate | async }}</span> {{ counter }} <span>{{ 'veces.' | translate | async }}</span> </p>
  </div>

</div>

  <router-outlet></router-outlet>

  <app-singlestreaming *ngIf="streamingactive"></app-singlestreaming>

<input id="burger" type="checkbox" [checked]="burger_check.checked" (click)="menuToggle()"/>
<div id="menu_bar">
  <!-- <div class="logo_r" (click)="goHome();" [inlineSVG]="'../assets/images/logo.svg'"></div> -->


  <label id="burgermenu" for="burger">

    <div  id="titulo" >{{ 'menú' | translate | async }}</div>
   <!-- <div class="imagesvg bringfront"   id="titulo"  (click)="setColor('negro');randomTitle();menuToggle();" [routerLink]="['/']" [inlineSVG]="inline_title"></div> -->
    <span></span>
    <span></span>
    <span></span>
  </label>
    <label  id="closeNav" for="burger"></label>
   <div id="infosalon">
       <p>{{ '45 salón nacional de artistas | bogotá, colombia' | translate | async }}</p>
  </div>


</div>

<nav for="burger">
	<ul id="menu" >

	    <li><span class="subtitulo">{{ '45 salón nacional' | translate | async }} </span></li>
	           <li><span><a routerLinkActive="active" [routerLink]="['/45sna']" (click)="randomTitle();menuToggle();">{{ 'sobre este salón' | translate | async }}</a></span></li>
	             <li><span><a routerLinkActive="active" [routerLink]="['/reves']" (click)="randomTitle();menuToggle();">{{ 'el revés de la trama' | translate | async }}</a></span></li>
               <li><span><a routerLinkActive="active" [routerLink]="['/equipo-curatorial']" (click)="randomTitle();menuToggle();">{{ 'equipo curatorial (11)' | translate | async }}</a></span></li>
               <li><span><a routerLinkActive="active" [routerLink]="['/sedes']" (click)="randomTitle();menuToggle();">{{ 'sedes' | translate | async }}</a></span></li>
               <li><span><a routerLinkActive="active" [routerLink]="['/creditos']" (click)="randomTitle();menuToggle();">{{ 'créditos' | translate | async }}</a></span></li>
               <li><span><a routerLinkActive="active" [routerLink]="['/socios']" (click)="randomTitle();menuToggle();">{{ 'socios' | translate | async }}</a></span></li>

<div class="division"></div>


          <li><span class="subtitulo"><a routerLinkActive="active" [routerLink]="['/artistas']"  class="subtitulo" (click)="randomTitle();menuToggle();">{{ 'artistas (169)' | translate | async }}</a></span></li>

<div class="division"></div>


          <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos']"  class="subtitulo" (click)="randomTitle();menuToggle();">{{ 'exposiciones y proyectos (13)' | translate | async }}</a></span>
               <li>
                       <!-- <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos']" (click)="randomTitle();menuToggle();">{{ 'exposiciones y proyectos' | translate | async }}</a></span></li> -->
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/la-fabula-de-aracne']" (click)="randomTitle();menuToggle();">{{ 'la fábula de aracne' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/universos-desdoblados']" (click)="randomTitle();menuToggle();">{{ 'universos desdoblados' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/contrainformacion']" (click)="randomTitle();menuToggle();">{{ 'contrainformación' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/llamitas-al-viento']" (click)="randomTitle();menuToggle();">{{ 'llamitas al viento' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/arquitecturas-narrativas']" (click)="randomTitle();menuToggle();">{{ 'arquitecturas narrativas' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/mitopia']" (click)="randomTitle();menuToggle();">{{ 'mitopía' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/instancias']" (click)="randomTitle();menuToggle();">{{ 'instancias' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/lenguajes-de-la-injuria']" (click)="randomTitle();menuToggle();">{{ 'lenguajes de la injuria' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/antes-del-amanecer']" (click)="randomTitle();menuToggle();">{{ 'antes del amanecer' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/espacios-de-interferencia']" (click)="randomTitle();menuToggle();">{{ 'espacios de interferencia' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/pastas-el-gallo']" (click)="randomTitle();menuToggle();">{{ 'pastas el gallo' | translate | async }}</a></span></li>
                       <li><span><a routerLinkActive="active" [routerLink]="['/exposiciones-y-proyectos/redireccion']" (click)="randomTitle();menuToggle();">{{ 'redirección' | translate | async }}</a></span></li>
                       <li><span><a href="https://45sna.com/wp-content/uploads/2020/02/guias_del_espacio.pdf" target="_blank" (click)="randomTitle();menuToggle();">{{ 'Guías del Espacio' | translate | async }}</a></span></li>


<div class="division"></div>



       <li><span class="subtitulo">{{ 'eventos' | translate | async }}</span>   </li>
           <li><span><a routerLinkActive="active" [routerLink]="['/calendario']" (click)="randomTitle();menuToggle();">{{ 'calendario' | translate | async }}</a></span></li>
             <li><span><a routerLinkActive="active" [routerLink]="['/catedra-performativa']" (click)="randomTitle();menuToggle();">{{ 'cátedra performativa ' | translate | async }}</a></span></li>
             <li><span><a routerLinkActive="active" [routerLink]="['/el-45sna-se-toma-la-biblioteca-nacional']" (click)="randomTitle();menuToggle();">{{ 'biblioteca nacional' | translate | async }}</a></span></li>
             <li><span><a routerLinkActive="active" [routerLink]="['/talleres']" (click)="randomTitle();menuToggle();">{{ 'talleres' | translate | async }}</a></span></li>

<div class="division"></div>


      <li><span class="subtitulo">{{ 'descargas' | translate | async }}</span>   </li>
             <li><span><a href="https://45sna.com/wp-content/uploads/2020/01/GUÍA45sna_compressed.pdf" target="_blank" (click)="randomTitle();menuToggle();">{{ 'guía / guide (eng - esp)' | translate | async }}</a></span></li>
            <li><span><a href="https://45sna.com/wp-content/uploads/2020/01/Catálogo45SNA_compressed.pdf" target="_blank" (click)="randomTitle();menuToggle();">{{ 'catálogo' | translate | async }}</a></span></li>

<div class="division"></div>



     <li><span class="subtitulo">{{ 'proyectos web' | translate | async }} </span>   </li>
      	          <li><span><a routerLinkActive="active" [routerLink]="['/redireccion/intro']" (click)="randomTitle();menuToggle();">{{ 'redirección - aarea' | translate | async }}</a></span></li>
                    <li><span><a routerLinkActive="active" [routerLink]="['/cuadernos-de-la-violencia']" (click)="randomTitle();menuToggle();">{{ 'cuadernos de la violencia' | translate | async }}</a></span></li>
                    <li><span><a target="_blank" href="http://puertassalon.com/" (click)="randomTitle();menuToggle();">{{ 'Puertas Salón  - ' | translate | async }}<span>Enar de Dios Rodríguez</span></a></span></li>
      			        <!-- <li><span><a target="_blank" href="https://www.youtube.com/watch?v=oKU87jRPS7c" (click)="randomTitle();menuToggle();">{{ 'Guías del Espacio - ' | translate | async }}<span>Enar de Dios Rodríguez</span></a></span></li> -->
                    <li><span><a routerLinkActive="active" [routerLink]="['/oddhorizons']" (click)="randomTitle();menuToggle();">{{ 'odd horizons - ' | translate | async }}<span>Angie Rengifo</span></a></span></li>
      			        <li><span><a routerLinkActive="active" [routerLink]="['/futuro']" (click)="randomTitle();menuToggle();">{{ 'El futuro es cosa del pasado - ' | translate | async }}<span>Alejandro Martín</span></a></span></li>
                    <li><span><a routerLinkActive="active" [routerLink]="['/proponemos']" (click)="randomTitle();menuToggle();">{{ 'manifiesto' | translate | async }}</a></span></li>
                    <li><span><a routerLinkActive="active" [routerLink]="['/muro']" (click)="randomTitle();menuToggle();">{{ 'el muro' | translate | async }}</a></span></li>
                    <li><span><a routerLinkActive="active" [routerLink]="['/sobre-este-sitio-web']" (click)="randomTitle();menuToggle();">{{ 'sobre este sitio web' | translate | async }}</a></span></li>

<div class="division"></div>

      <li><span class="subtitulo">{{ 'medios' | translate | async }}</span>   </li>
            <li><span><a routerLinkActive="active" [routerLink]="['/prensa-y-critica']" (click)="randomTitle();menuToggle();">{{ 'prensa y crítica' | translate | async }}</a></span></li>
            <li><span><a routerLinkActive="active" [routerLink]="['/comunicados']" (click)="randomTitle();menuToggle();">{{ 'comunicados y entrevistas' | translate | async }}</a></span></li>
            <li><span><a routerLinkActive="active" [routerLink]="['/videos-y-streamings']" (click)="randomTitle();menuToggle();">{{ 'videos y streamings' | translate | async }}</a></span></li>
            <li><span><a routerLinkActive="active" [routerLink]="['/sobre-la-censura-al-mural-de-powerpaola-y-lucas-ospina']" (click)="randomTitle();menuToggle();">{{ 'sobre la censura al mural de Powerpaola y Lucas Ospina' | translate | async }}</a></span></li>

<div class="division"></div>

      <li><span class="subtitulo">{{ 'contexto' | translate | async }} </span>   </li>
              <li><span><a routerLinkActive="active" [routerLink]="['/historia']" (click)="randomTitle();menuToggle();">{{ 'historia del salón y su revés' | translate | async }}</a></span></li>
              <li><span><a routerLinkActive="active" [routerLink]="['/linea-de-tiempo']" (click)="randomTitle();menuToggle();">{{ 'línea de tiempo' | translate | async }}</a></span></li>


 	    <!-- <li class="contact"> <span><a routerLinkActive="active" [routerLink]="['/contacto']" (click)="randomTitle();menuToggle();">{{ 'contacto' | translate | async }}</a></span>
	    </li> -->

	  </ul>

</nav>
