/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./agua.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./agua.component";
import * as i4 from "../../wpbackend.service";
var styles_AguaComponent = [i0.styles];
var RenderType_AguaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AguaComponent, data: {} });
export { RenderType_AguaComponent as RenderType_AguaComponent };
function View_AguaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["id", "portada"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.start() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" AGITANDO EL AGUA DURMIENTE"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["alejandra jaramillo"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["src", "./../../assets/images/cine/player.png"]], null, null, null, null, null))], null, null); }
function View_AguaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "mask"]], null, null, null, null, null))], null, null); }
function View_AguaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "pdfobject_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["class", "embed-responsive-item"], ["height", "480"], ["src", "https://drive.google.com/file/d/18oY2q8XYQnsCLoEKnJUCQggMoA0evRYC/preview"], ["width", "640"]], null, null, null, null, null))], null, null); }
export function View_AguaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AguaComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AguaComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["id", "yt-player-container"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "visible": 0, "invisible": 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["id", "yt-player"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AguaComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.started && _co.streamingactive); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.show_mask && _co.streamingactive); _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _co.streamingactive, !_co.streamingactive); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.streamingactive; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_AguaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-agua", [], null, null, null, View_AguaComponent_0, RenderType_AguaComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AguaComponent, [i4.WpbackendService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AguaComponentNgFactory = i1.ɵccf("app-agua", i3.AguaComponent, View_AguaComponent_Host_0, {}, {}, []);
export { AguaComponentNgFactory as AguaComponentNgFactory };
