<app-newscolumn></app-newscolumn>
<!-- <div id="prensa">
  <a id="prensa_base" routerLinkActive="active" [routerLink]="['/prensa']" >
  <span>prensa</span></a>
</div>   -->

<div id="jackpot_container" (window:resize)="onResize()">
  <div id="jackpot" (click)="startJackpot()" >
    <div class="columna" *ngIf="active_columns[0]">
      <ul #columna_1 id="columna_1">
          <li *ngFor="let artista of artistas" [ngStyle]="{'background-color': colores[artista.id]  }"  ><a   [routerLink]="['/artistas', artista.slug ]" [innerHTML]="artista.title.rendered"></a></li>
      </ul>
    </div>

    <div class="columna" *ngIf="active_columns[1]">
      <ul #columna_2 id="columna_2">
          <li *ngFor="let artista of artistas" [ngStyle]="{'background-color': colores[artista.id]  }" ><a [routerLink]="['/artistas', artista.slug ]" [innerHTML]="artista.title.rendered"></a></li>
      </ul>
    </div>

    <div class="columna" *ngIf="active_columns[2]">
      <ul #columna_3 id="columna_3">
          <li *ngFor="let artista of artistas" [ngStyle]="{'background-color': colores[artista.id]  }" ><a   [routerLink]="['/artistas', artista.slug ]" [innerHTML]="artista.title.rendered"></a></li>
      </ul>
    </div>

    <div class="columna" *ngIf="active_columns[3]">
      <ul #columna_4 id="columna_4">
          <li *ngFor="let artista of artistas" [ngStyle]="{'background-color': colores[artista.id]  }" ><a [routerLink]="['/artistas', artista.slug ]" [innerHTML]="artista.title.rendered"></a></li>
      </ul>
    </div>
  </div>
</div>
