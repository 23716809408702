import { Component, OnInit, ElementRef, QueryList, ViewChildren, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable, of } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-columnas',
  templateUrl: './columnas.component.html',
  styleUrls: ['./columnas.component.scss']
})
export class ColumnasComponent implements OnInit, OnDestroy {
  @ViewChildren('allcolumns') columns: QueryList<any>;
  post_type:string;
  slug:string;
  posts$: Observable<any[]>;
  artistas$: Observable<any[]>;
  curadurias$: Observable<any[]>;
  isClicked: Array<any> = [];
  colores:Array<any> = [];
  links:Array<any> = [];
  colores_fonts:Array<any> = [];
  colores_over:Array<any> = [];
  ordered:Array<any> = [];
  posts:Array<any> = [];
  crumb:string;
  query: string;
  artquery: string;
  show_index:boolean = false;
  selected:any;
  private sub: any;
  id:string;
  interval:any;
  scrolling:boolean = false;
  loading:boolean = true;
  show_lightbox:boolean = false;
  lightbox_url:string = '';

  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService
  ) {
    this.post_type = route.snapshot.data['type'];
	  this.slug = route.snapshot.data['slug'];
    this.posts$ = this.wp.getList( this.post_type );
	  this.curadurias$ = this.wp.getList( 'curadurias' );
    this.artistas$ = this.wp.getList( 'artistas' );
  }

  ngOnInit() {
	  this.route.paramMap.pipe(
		  switchMap((params: ParamMap) =>
		  of(params.get('id'))
	  )).subscribe((d) => {
		  this.id = d;
		  // console.log( "Got ID: ", this.id );
		  this.scrollToColumn( this.id );
	  });
    if( this.post_type == 'lineatiempo' ){
      this.appService.set_global_color('negro blog lineatiempo columnas');
      this.crumb = 'línea de tiempo';
    }else if( this.post_type == 'curadurias' ){
      this.appService.set_global_color('negro blog curadurias columnas');
      this.crumb = 'exposiciones y proyectos';
      this.show_index = true;
  }else if( this.post_type == 'catedras' ){
    this.appService.set_global_color('negro blog catedras columnas');
    this.crumb = 'cátedras';
    this.show_index = false;
  }else if( this.post_type == 'artistas' ){
      this.appService.set_global_color('negro blog artistas columnas');
      this.crumb = 'artistas';
      this.show_index = true;
	  this.scrolling = true;
  }else if( this.post_type == 'columnistas' ){
      this.appService.set_global_color('negro blog columnistas columnas');
      this.crumb = 'columnistas';
      this.show_index = true;
	  this.scrolling = true;
  }else if( this.post_type == 'equipo' ){
        this.appService.set_global_color('negro blog equipo columnas');
        this.crumb = 'equipo curatorial';
        this.show_index = false;
  	  this.scrolling = false;
      }else{
	    this.posts$ = this.wp.getNews();
        this.appService.set_global_color('negro blog noticias columnas');
        this.crumb = 'prensa y crítica';
    }


	this.interval = setInterval( () => {
      this.incrementScroll();
  	}, 70);

    if( this.post_type == 'curadurias' ){
      this.artistas$.subscribe((data) => {
          this.ordered = data.sort((a,b)=>a.title.rendered.localeCompare(b.title.rendered));
      });
      this.posts$.subscribe((data) => {
          this.posts = data;
      });
    }else{
      this.posts$.subscribe((data) => {
          this.posts = data;
          this.ordered = data.sort((a,b)=>a.title.rendered.localeCompare(b.title.rendered));
          // console.log("Ordered: ", this.ordered);
          if ( this.id ){
            this.scrollToColumn( this.id );
          }
          if( !this.show_index ){
            clearInterval( this.interval );
          }
      });
    }

	this.curadurias$.subscribe((data) => {
      // console.log("Got curadurias: ", data);
      data.forEach( (value) => {
		  if( value.acf.color_fondo ){
        if( value.acf.artistas ){
          value.acf.artistas.forEach(
            ( artista ) => {
              this.colores[ artista.ID ] = value.acf.color_fondo;
              this.colores_fonts[ artista.ID ] = value.acf.color_fuente;
			        this.colores_over[ artista.ID ] = '#000';
              this.links[ artista.ID ] = value.slug;
            }
          );
        }
			  // console.log("colores: ", this.colores);
		  }
	  });
    });
    let pagecont = document.getElementsByClassName("posts_list");
  	if( pagecont[0] != undefined ){
  		pagecont[0].scrollTop = 0;
  	}
  }

  setOverColor( id ){
	  // console.log("setting color for id: ", this.colores[ id ]);
	  this.colores_over[ id ] = this.colores[ id ];
  }

  restoreColor( id ){
	  // console.log("restoring color for id: ", id);
	  this.colores_over[ id ] = '#000';
  }

  showLightbox( url ){
    this.show_lightbox = true;
    this.lightbox_url = url;
  }

  incrementScroll(){

    if( this.show_index && this.scrolling ){
	  let index = document.getElementById('main_index');
		  if( index != undefined ){
			  index.scrollTop = index.scrollTop+1
		  }else{
			  return;
		  }
	  }else{
		// nothing to scroll or scroll paused
		return;
	  }
  }

  enter(){
	 this.scrolling = false;
  }

  leave(){
	 this.scrolling = true;
  }

  onContentClick(e: MouseEvent) {
    console.log("Click link host: ", e.target );
    if( e.target instanceof HTMLImageElement ){
      this.showLightbox(  e.target.src );
    }else if (e.target instanceof HTMLAnchorElement && e.target.host === location.host) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname);
    }
  }

  scrollToColumn( id ){
	  let container = document.getElementById( "column_list_container" );
	  if( id ){
			  setTimeout( () => {
				  let selected_column = document.getElementById( this.id );
				  if( selected_column ){
					  let offsetleft = selected_column.offsetLeft;
					  let offsettop = selected_column.offsetTop;
					  // console.log("Column offset left: ", offsetleft );
					  // console.log( "scrolling to column: ", selected_column );
            		  // console.log( "selected index: ", selected_column.dataset.index );
            		  this.selected = +selected_column.dataset.index+1;
					  container.scrollTo({ left: offsetleft, top: offsettop, behavior: 'smooth'});
				  }else{
            		  this.selected = false;
					  // console.log( "Couldn't select column: ", selected_column );
				  }
			  }, 100);
	  }else{
		  // console.log("Attempted scroll without ID: ", id );
      	  this.selected = false;
		  container.scrollTo({ left: 0, top: 0, behavior: 'smooth'});
	  }
  }

  toggle(i, $event){
    if( !this.isClicked[i] ){this.isClicked = []; this.isClicked[i] = true;}else{this.isClicked[i] = !this.isClicked[i] }
    // CLose all before opening a new one.
    // console.log("Event: ", $event );
    let wait = setTimeout(()=>{
      $event.target.parentElement.scrollIntoView({ behavior: "smooth" });
    },100);
  }

  backtoindex( $event, type ){
    this.router.navigate( ['/'+type] );
    $event.stopPropagation();
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    this.columns.changes.subscribe(t => {
      this.ngForRendred();
    })
  }

  ngForRendred() {
    // console.log('NgFor is Rendered');
    if( this.id ){
      this.scrollToColumn( this.id );
    }
    if( this.columns.length > 0 ){
      this.loading = false;
    }
  }

  prev(){
    let index = +this.selected-2;
    let prev:any = document.querySelectorAll("[data-index='"+index+"']")[0];
    prev.click()
  }

  next(){
    let index = 0;
    if( this.selected ){
      index = +this.selected;
    }
    let next:any = document.querySelectorAll("[data-index='"+index+"']")[0];
	if( next != undefined ){
		next.click()
	}
  }

}
