import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../../wpbackend.service';
import { Observable, of } from 'rxjs';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-agua',
  templateUrl: './agua.component.html',
  styleUrls: ['./agua.component.scss']
})
export class AguaComponent implements OnInit {
	pdfroute:string = '/assets/pdf/ScriptAgitandoelaguadurmiente.pdf';
	pdfobject:any;
	streamingactive:boolean = false;
	api_loaded:boolean = false;
    yt_player:any;
    player_ready:boolean = false;
    started:boolean = false;
    show_mask:boolean = false;
    fondo:any = 1;
	id:any;
	options$:any;
	option:any = 'CCBYH5Y0JuA';
	now:any;
	bg_images:any;
	current_image:any;

  constructor(
	  private wp: WpbackendService
  ) { }

  ngOnInit() {

	  this.pdfobject = "<object data='" + this.pdfroute + "' type='application/pdf' class='embed-responsive-item'>" + "Object " + this.pdfroute + " failed" + "</object>";

	  this.bg_images = [
       'https://45sna.com/assets/images/esquina/006am.jpg',
       'https://45sna.com/assets/images/esquina/6am12.jpg',
       'https://45sna.com/assets/images/esquina/126pm.jpg',
       'https://45sna.com/assets/images/esquina/6pm00.jpg'

		  // 'https://test.45sna.com/assets/images/esquina/quina.png#1',
		  // 'https://test.45sna.com/assets/images/esquina/quina.png#2',
		  // 'https://test.45sna.com/assets/images/esquina/quina.png#3',
		  // 'https://test.45sna.com/assets/images/esquina/quina.png#4'
	  ];

	  (window as any).onYouTubeIframeAPIReady = () => {
		  console.log("API READY");
		  this.api_loaded = true;
		  this.createPlayer();
		  this.options$ = this.wp.getOptions().subscribe( ( data ) => {
			  // console.log("got options from backend: ", data.url_streaming_esquina );
			  this.option = data.url_streaming_agua;
			  // this.streamingactive = data.streaming_redireccion_active;
		  });
	  };

	  if( (window as any).YT ){
		  this.api_loaded = true;
		  this.createPlayer();
	  }

	  this.check_time();

      setInterval(()=>{
        this.check_time();
      },12000);
  }

  ngAfterViewInit() {
	if( !(window as any).YT ){
		let doc = window.document;
		let playerApi = doc.createElement('script');
		playerApi.type = 'text/javascript';
		playerApi.src = 'https://www.youtube.com/iframe_api';
		doc.body.appendChild(playerApi);
	}
  }

  start(){
	  if( !this.started && this.player_ready ){
		  this.started = true;
		  this.yt_player.playVideo();
	  }
  }

  check_time(){
	  this.now = new Date();
	  let hour = this.now.getUTCHours();
	  console.log("Current UTC hour is: ", hour);
	  if( hour >= 5 && hour < 11 ){ // de media noche a 6am
		  this.current_image = this.bg_images[0];
      this.streamingactive = false;
	  }else if( hour >= 11 && hour < 17 ){ // de 6am a medio dia
		  this.current_image = this.bg_images[1];
      this.streamingactive = true;
	  }else if( hour >= 17 && hour < 23 ){ // de medio dia a 6pm
		  this.current_image = this.bg_images[2];
      this.streamingactive = true;
	  }else if( hour >= 23 || hour < 5 ){ // de 6pm a media noche
      this.current_image = this.bg_images[1];
      this.streamingactive = false;
	  }
  }

  createPlayer() {
	this.options$ = this.wp.getOptions().subscribe( ( data ) => {
		// console.log("got options from backend: ", data.url_streaming_esquina );
		this.option = data.url_streaming_agua;
		this.streamingactive = data.streaming_redireccion_active;

		this.yt_player = new (window as any).YT.Player('yt-player', {
				width: '560',
				height: '315',
	  			videoId: this.option,
			  	playerVars: {
					autoplay: 0,
					iv_load_policy: '3',
					rel: '0',
					controls: '0',
					modestbranding: '1',
					fs: 0,
					playsinline: 1
				},
				events: {
					onReady: (ev) => {
					  console.log("Player ready");
					  this.player_ready = true;
					  // this.yt_player.playVideo();
					  // this.playAudio( this.current_song );
					},
					onStateChange: (ev) => {
					// console.log("State change: ", ev );
					  // console.log( "Today :", this.today.getDate() );
					  if( ev.data == 2 ){
						// this.yt_player.playVideo();
						if( this.yt_player.isMuted() ){
						 // this.yt_player.unMute();
						}else{
						 // this.yt_player.mute();
						}
					  }

		  if( ev.data == 0 ){
			  console.log("stream stopped, apparently");
			  this.started = false;
			  this.show_mask = false;

			this.yt_player.playVideo();
		  }

		    if( ev.data == 1 ){
		      this.show_mask = true;
			  // this.setTimers();
		    }
					}
				}
		});
	});


	}

}
