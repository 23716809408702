<!-- imgs fondo 1 al 5 .jpg -->
<div *ngIf="!started" id="portada" [ngStyle]="{'background-image': 'url( ../../../assets/images/cine/'+ fondo +'.jpg )'}" (click)="start()">
  <div class="content">
    CINE CONTINUADO<br>
    <span>IVÁN CANDEO</span><br>
    <img src="./../../assets/images/cine/player.png" >
  </div>
</div>
<div id="mask" *ngIf="show_mask"></div>
<div id="yt-player"></div>
<div id="reloj"><span>{{ h }}</span> : <span>{{ m }}</span>  : <span>{{ s }}</span>  : <span>{{ cs }}</span> </div>
