/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seneb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./seneb.component";
var styles_SenebComponent = [i0.styles];
var RenderType_SenebComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SenebComponent, data: {} });
export { RenderType_SenebComponent as RenderType_SenebComponent };
export function View_SenebComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { myVideo: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["id", "play_button"], ["type", "button"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["id", "player"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["myVideo", 1]], null, 2, "video", [["autobuffer", ""], ["height", "100%"], ["id", "myvideo"], ["poster", "../../../assets/images/seneb/seneb.jpg"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "source", [["src", "http://45sna.com/videos/hypnosis_excite01.mp4"], ["type", "video/mp4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your browser does not support HTML5 video. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.texto_boton; _ck(_v, 1, 0, currVal_0); }); }
export function View_SenebComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-seneb", [], null, null, null, View_SenebComponent_0, RenderType_SenebComponent)), i1.ɵdid(1, 114688, null, 0, i2.SenebComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SenebComponentNgFactory = i1.ɵccf("app-seneb", i2.SenebComponent, View_SenebComponent_Host_0, {}, {}, []);
export { SenebComponentNgFactory as SenebComponentNgFactory };
