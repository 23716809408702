/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./futuro.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./futuro.component";
var styles_FuturoComponent = [i0.styles];
var RenderType_FuturoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuturoComponent, data: {} });
export { RenderType_FuturoComponent as RenderType_FuturoComponent };
export function View_FuturoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "finished": 0, "contenedor": 1 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "pie"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Medell\u00EDn \u2013 Bogot\u00E1 \u00A0\u00A0 6 de octubre 2019 \u00A0\u00A0 5 p.m."])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["futuro"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.finished, true); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, _co.final_gradient); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FuturoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-futuro", [], null, null, null, View_FuturoComponent_0, RenderType_FuturoComponent)), i1.ɵdid(1, 114688, null, 0, i3.FuturoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FuturoComponentNgFactory = i1.ɵccf("app-futuro", i3.FuturoComponent, View_FuturoComponent_Host_0, {}, {}, []);
export { FuturoComponentNgFactory as FuturoComponentNgFactory };
