/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./esquina.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./esquina.component";
import * as i4 from "../../wpbackend.service";
var styles_EsquinaComponent = [i0.styles];
var RenderType_EsquinaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EsquinaComponent, data: {} });
export { RenderType_EsquinaComponent as RenderType_EsquinaComponent };
function View_EsquinaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "portada"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.start() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "background-image": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "./../../assets/images/cine/player.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (("url(" + _co.current_image) + ")")); _ck(_v, 1, 0, currVal_0); }, null); }
function View_EsquinaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "mask"]], null, null, null, null, null))], null, null); }
export function View_EsquinaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EsquinaComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EsquinaComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["id", "yt-player"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.started; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.show_mask; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_EsquinaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-esquina", [], null, null, null, View_EsquinaComponent_0, RenderType_EsquinaComponent)), i1.ɵdid(1, 4308992, null, 0, i3.EsquinaComponent, [i4.WpbackendService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EsquinaComponentNgFactory = i1.ɵccf("app-esquina", i3.EsquinaComponent, View_EsquinaComponent_Host_0, {}, {}, []);
export { EsquinaComponentNgFactory as EsquinaComponentNgFactory };
