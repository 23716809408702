import { Component, OnInit } from '@angular/core';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-postit',
  templateUrl: './postit.component.html',
  styleUrls: ['./postit.component.scss']
})
export class PostitComponent implements OnInit {
  show_postit:boolean = true;
  show_streaming:boolean = true;
  show_lightbox:boolean = false;
  lightbox_url:string = '';
  posts$: Observable<any[]>;
  content: any;

  constructor( private backendService: WpbackendService ) {
    this.posts$ = this.backendService.getPostit('posts');
  }

  ngOnInit() {
	  this.posts$.subscribe((data) => {
	    this.content = data;
	  });
  }

  showLightbox( url ){
    this.show_lightbox = true;
    this.lightbox_url = url;
  }

}
