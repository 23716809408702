/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mapa.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mapa.component";
var styles_MapaComponent = [i0.styles];
var RenderType_MapaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapaComponent, data: {} });
export { RenderType_MapaComponent as RenderType_MapaComponent };
export function View_MapaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "iframe", [["frameborder", "0"], ["src", "../assets/pdf/programacion.pdf#toolbar=0"]], null, null, null, null, null))], null, null); }
export function View_MapaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mapa", [], null, null, null, View_MapaComponent_0, RenderType_MapaComponent)), i1.ɵdid(1, 114688, null, 0, i2.MapaComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapaComponentNgFactory = i1.ɵccf("app-mapa", i2.MapaComponent, View_MapaComponent_Host_0, {}, {}, []);
export { MapaComponentNgFactory as MapaComponentNgFactory };
