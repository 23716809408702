<ul class="pages_list" *ngIf="!page">
  <li class="page_single" *ngFor="let page of pages$ | async">
    <h2><a [routerLink]="['/page/'+page.slug]" [innerHTML]="page.title.rendered | keepHtml | translatehtml | async"></a></h2>
  </li>
</ul>

<div class="page" *ngIf="page$ && !page$.acf.mostrar_logos">
  <div class="page_inner">
    <h1 *ngIf="!page$.acf.titulo_pagina_columnas" [innerHTML]="page$.title.rendered | keepHtml | translatehtml | async"></h1>
    <div class="page_content" *ngIf="!page$.acf.titulo_pagina_columnas" [innerHTML]="page$.content.rendered | keepHtml | translatehtml | async">
    </div>
    <div class="imagen_agradecimientos" *ngIf="page$.acf.imagen_agradecimientos"><img [src]="page$.acf.imagen_agradecimientos"/></div>

    <h1 class="titulo_columna"  >{{ page$.acf.titulo_pagina_columnas | translate | async }} </h1>
      <div class="page_content" *ngIf="page$.acf.titulo_pagina_columnas" [innerHTML]="page$.content.rendered | keepHtml | translatehtml | async"></div>
  	<div class="contenedor_columnas" *ngIf="page$.acf.titulo_pagina_columnas">
		  	<div class="izquierda" [innerHTML]="page$.acf.columna_izquierda | keepHtml | translatehtml | async" >
			  </div>
		    <div class="derecha" [innerHTML]="page$.acf.columna_derecha | keepHtml | translatehtml | async" >
		    </div>
  	</div>

  </div>
</div>


<!-- agradecimientos -->
<div class="page" *ngIf="page$.acf.mostrar_logos">
  <div class="page_inner2 agradecimientos">
  Un proyecto de <br>

    <div class="columna">
      <img src="../assets/logos/mincultura.svg" >
      <img src="../assets/logos/alcaldia.svg" >
    </div>

<br><br>  En asocio con <br>
<div class="columna">
  <img src="../assets/logos/arteria.svg" >
</div>
<br><br>  Nuestras sedes <br>
<div class="columna">
  <img src="../assets/logos/redgsf.svg" >
  <img src="../assets/logos/cinemateca.svg" >
    <img src="../assets/logos/mambo.svg" >
      <img src="../assets/logos/utadeo.svg" >
    <img src="../assets/logos/colombo.svg" >
      <img src="../assets/logos/espacioodeon.svg" >
      <img src="../assets/logos/laboratorio.svg" >

        <img src="../assets/logos/parqueadero.svg" >
        <img src="../assets/logos/salasab.svg" >
</div>
<br><br>  Con el apoyo de  <br>
<div class="columna">
  <img src="../assets/logos/minminer.svg" >
    <img src="../assets/logos/cafam.svg" >
      <img src="../assets/logos/crepes.svg" >
        <img src="../assets/logos/elbosque.svg" >

          <img src="../assets/logos/javeriana.svg" >
            <img src="../assets/logos/maestria.svg" >
              <img src="../assets/logos/simbionte.svg" >
                <img src="../assets/logos/petrobras.svg" >

                  <img src="../assets/logos/redideartes.svg" >
                    <img src="../assets/logos/biblioteca.svg" >
                      <img src="../assets/logos/padre_cafe_bar.svg" >

</div>
<br><br>  Aliados gubernamentales  <br>
<div class="columna">
  <img src="../assets/logos/alcaldiamedellin.svg" >
    <img src="../assets/logos/prohelvetia.svg" >
      <img src="../assets/logos/usembassy.svg" >
        <img src="../assets/logos/narinio.svg" >
          <img src="../assets/logos/cali.svg" >
          <img src="../assets/logos/cauca.svg" >
            <img src="../assets/logos/sre.svg" >
            <img src="../assets/logos/peru.svg" >
              <img src="../assets/logos/brasil.svg" >
              <img src="../assets/logos/espania.svg" >
</div>
<br><br>  Aliados mediáticos <br>
<div class="columna">
  <img src="../assets/logos/arcadia.svg" >
      <img src="../assets/logos/artnexus.svg" >
        <img src="../assets/logos/cc.svg" >
          <img src="../assets/logos/canal_capital.svg" >

</div>

<br><br> Agradecimientos<br><br>
Banco Agrario de Colombia<br>
Banco de la República<br>
BibloRed<br>
CK:\Web<br>
Congreso de la República<br>
Departamento de Arte, Universidad de los Andes<br>
Facultad de Artes y Humanidades, Universidad de los Andes<br>
Festival Eureka<br>
Hotel Tequendama<br>
Huerta del Castillo<br>
Huerta Santa Helena<br>
Jardín Botánico<br>
Maestría en Escritura Creativa del Instituto Caro y Cuervo<br>
Museo de Antioquia<br>
Museo de Arte de Pereira<br>
Museo Histórico Fiscalía General de la Nación<br>
Museo La Tertulia<br>
Museo Nacional de Colombia<br>
Museo Zenú de Arte Contemporáneo<br>
Piedra Tijera Papel<br>
Plataforma Bogotá<br>
Plaza de mercado de la Perseverancia
</div>
</div>
