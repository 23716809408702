import { Component, OnInit, ViewChildren } from '@angular/core';
import { AppComponent } from '../app.component';
import { AppService } from '../app.service';
import { WpbackendService } from '../wpbackend.service';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})

export class CalendarioComponent implements OnInit {
  calendar: any;
  lang: any;
  days: Array<any> = new Array();
  weeks: Array<any> = new Array();
  showing: number;
  day_active: any;
  month_active: any;
  i:any = false;
  scrolled:boolean = false;

  @ViewChildren('someName') someDivs;

  constructor( private app: AppComponent, private appService: AppService, private wp: WpbackendService ) { }

  ngOnInit() {
    this.appService.set_global_color('negro calendario ');

    this.lang = 'en';
    this.get_calendar();

    this.days["fr"] = new Array();
    this.days["en"] = new Array();

    this.days["fr"]["1"] = 'Lun';
    this.days["fr"]["2"] = 'Mar';
    this.days["fr"]["3"] = 'Mer';
    this.days["fr"]["4"] = 'Jeu';
    this.days["fr"]["5"] = 'Ven';
    this.days["fr"]["6"] = 'Sam';
    this.days["fr"]["7"] = 'Dim';

    this.days["en"]["1"] = 'Lun';
    this.days["en"]["2"] = 'Mar';
    this.days["en"]["3"] = 'Mie';
    this.days["en"]["4"] = 'Jue';
    this.days["en"]["5"] = 'Vie';
    this.days["en"]["6"] = 'Sab';
    this.days["en"]["7"] = 'Dom';

    this.weeks["en"] = new Array();
	this.weeks["en"]["9"] = new Array();
    this.weeks["en"]["10"] = new Array();
    this.weeks["en"]["11"] = new Array();
    this.weeks["en"]["12"] = new Array();

    this.weeks["fr"] = new Array();
	this.weeks["fr"]["9"] = new Array();
    this.weeks["fr"]["10"] = new Array();
    this.weeks["fr"]["11"] = new Array();
    this.weeks["fr"]["12"] = new Array();

	this.weeks["en"]["9"]["35"] = "Semana 35";
	this.weeks["en"]["9"]["36"] = "Semana 36";
	this.weeks["en"]["9"]["37"] = "Semana 37";
	this.weeks["en"]["9"]["38"] = "Semana 38";
	this.weeks["en"]["9"]["39"] = "Semana 39";
	this.weeks["en"]["9"]["40"] = "Semana 40";
	this.weeks["en"]["10"]["41"] = "Semana 41";
    this.weeks["en"]["10"]["42"] = "Semana 42";
    this.weeks["en"]["10"]["43"] = "Semana 43";
    this.weeks["en"]["10"]["44"] = "Semana 44";
    this.weeks["en"]["11"]["44"] = "Semana 45";
    this.weeks["en"]["11"]["45"] = "Semana 46";
    this.weeks["en"]["11"]["46"] = "Semana 47";
    this.weeks["en"]["11"]["47"] = "Semana 48";
    this.weeks["en"]["11"]["48"] = "Semana 49";
    this.weeks["en"]["12"]["48"] = "Semana 50";
    this.weeks["en"]["12"]["49"] = "Semana 51";
    this.weeks["en"]["12"]["50"] = "Semana 52";
    this.weeks["en"]["12"]["51"] = "Semana 53";

	this.weeks["fr"]["9"]["35"] = "Semana 35";
	this.weeks["fr"]["9"]["36"] = "Semana 36";
	this.weeks["fr"]["9"]["37"] = "Semana 37";
	this.weeks["fr"]["9"]["38"] = "Semana 38";
	this.weeks["fr"]["9"]["39"] = "Semana 39";
	this.weeks["fr"]["9"]["40"] = "Semana 40";
	this.weeks["fr"]["10"]["41"] = "Semana 41";
    this.weeks["fr"]["10"]["42"] = "Recherche artistique : outils et strategies";
    this.weeks["fr"]["10"]["43"] = "Mondialité et relation ";
    this.weeks["fr"]["10"]["44"] = "Savoirs autochtones";
    this.weeks["fr"]["11"]["44"] = "&nbsp;<br />&nbsp;";
    this.weeks["fr"]["11"]["45"] = "Identite et biopolitique";
    this.weeks["fr"]["11"]["46"] = "Hospitalité et cosmopolitisme";
    this.weeks["fr"]["11"]["47"] = "Traduction culturelle";
    this.weeks["fr"]["11"]["48"] = "Savoirs en partage";
    this.weeks["fr"]["12"]["48"] = "&nbsp;<br />&nbsp;";
    this.weeks["fr"]["12"]["49"] = "Economies alternatives";
    this.weeks["fr"]["12"]["50"] = "Ecologie et décolonialisme";
    this.weeks["fr"]["12"]["51"] = "&nbsp;<br />&nbsp;";
  }

  get_calendar(){
    this.wp.getCalendar().subscribe(response => {
		let thirdquarter = [];
		let lastquarter = [];
	  thirdquarter = response['2019'].elements['3'].elements;
	  lastquarter = response['2019'].elements['4'].elements;
    this.calendar = {
      elements: {
        9: thirdquarter[9],
        10: lastquarter[10],
        11: lastquarter[11]
      }
    }
    // console.log("Discarted cal: ", thirdquarter );
	  // console.log("Resulting cal: ", this.calendar );
    }, error => {
      console.log('Options Error: ', error);
    })
  }

  show_event( id, day, month, content ){
    if( this.showing == id || content == "" ){
      this.day_active = null;
      this.month_active = null;
      this.showing = null;
    }else{
      this.day_active = day;
      this.month_active = month;
      this.showing = id;
    }
  }

  closeLightbox(){
    this.day_active = null;
    this.month_active = null;
    this.showing = null;
  }

  flatten(data) {
    let chartData = [];

    for (let i in data) {
        let item = data[i];
        let outer = [];
        // skip over items in the outer object that aren't nested objects themselves
        if (typeof item === "object") {
            for (let j in item) {
                let temp = [];
                temp.push(j);
                temp.push(item[j]);
                outer.push(temp);
            }
        }
        if (outer.length) {
            chartData.push(outer);
        }
    }
    return chartData;
  }

  ngAfterViewInit() { // or some event handler
    if ( !this.scrolled ){
      this.someDivs.changes.subscribe(elements => {
        if ( !this.scrolled ){
          elements.last.nativeElement.querySelector('.notpassed').scrollIntoView({block: "start", behavior: "smooth"});
          this.scrolled = true;
        }
      });
    }
  }
}
