<div class="flex-container" id="new_calendar">
  <div id="gradient"></div>

  <div id="past">{{ 'PASADO' | translate | async }}</div>

  <div id="future">{{ 'FUTURO' | translate | async }}</div>


  <div class="hours_header">
    <ul>

      <li class="hour_header">9</li>
      <li class="hour_header">10</li>
      <li class="hour_header">11</li>
      <li class="hour_header">12</li>
      <li class="hour_header">13</li>
      <li class="hour_header">14</li>
      <li class="hour_header">15</li>
      <li class="hour_header">16</li>
      <li class="hour_header">17</li>
      <li class="hour_header">18</li>
      <li class="hour_header">19</li>
      <li class="hour_header">20</li>
      <li class="hour_header">21</li>
      <li class="hour_header">22</li>
      <li class="hour_header">23</li>
    </ul>
  </div>

  <div #someName *ngIf="calendar" id="events_container" class="flex-item">
    <ul class="month" *ngFor="let month of calendar.elements | keys">
      <li class="week" *ngFor="let week of month?.elements | keys">
        <h3 class="month-{{month.value}} week-{{week.value}}"></h3>
        <ul>
          <li class="day" *ngFor="let day of week?.elements | keys" [ngClass]="{'passed': day.data.has_passed, 'notpassed': !day.data.has_passed}">
            <div class="day_container">
              <h3><span>{{ days[lang][day.data.day_name] | translate | async }} <br />{{ day.value }} / {{ month.value }}</span></h3>
              <ul class="day_events">
                <li
                  class="day_event length-{{event.duration}} start-{{event.start_hour}}"
                  *ngFor="let event of day?.data.events | keys"
                  [ngStyle]="{'background-color': event.color_alfa, 'border': '2px solid' + event.color_alfa, 'width': event.duration*6+'vw', 'left': (event.start_hour-9)*6+'vw', 'border':  event.color_alfa }"
                  (click)="show_event( event.post.ID, day.value, month.value, event.post.post_content )"
                  [ngClass]="{'active':  event.post.ID == showing && day_active == day.value && month_active == month.value, 'multiday': event.multiday, 'nocontent': event.post.post_content == ''  }">
                    <div class="time_container" *ngIf="!event.multiday">
                      <span *ngIf="showing != event.post.ID || day_active != day.value || month_active != month.value" class="time" id="fecha_inicial">{{ event.fecha_inicial | date:'shortTime' }}</span>
                       - <span *ngIf="showing != event.post.ID || day_active != day.value || month_active != month.value" class="time" id="fecha_final">{{ event.fecha_final | date:'shortTime' }}</span>
                    </div>
                    <h2 *ngIf="showing != event.post.ID || day_active != day.value || month_active != month.value" [innerHTML]="event.post.post_title | translate | async" [ngStyle]="{'color': event.color_fuente  }"></h2>
                    <a class="close" *ngIf="showing == event.post.ID && day_active == day.value && month_active == month.value">{{ 'Cerrar' | translate | async }} X</a>
                </li>
              </ul>
            </div>
            <div *ngFor="let event of day?.data.events | keys" class="day_content_details">
              <div class="details_content_container" *ngIf="showing == event.post.ID && day_active == day.value && month_active == month.value" [ngStyle]="{'border':  event.color_alfa , 'background-color': event.color_fondo }">
                  <a class="close" (click)="closeLightbox()">{{ 'Cerrar' | translate | async }} X</a>

                  <div class="event_columns">  <div class="event_content">
                    <h2 [innerHTML]="event.post.post_title | translate | async"></h2>
                    <span [innerHTML]="event.rendered | keepHtml | translatehtml | async"></span>
                    </div>
                    <div *ngIf="event.right_column != ''" class="event_content_right"  [innerHTML]="event.right_column | keepHtml | translatehtml | async"></div>
                  </div>

              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
