import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-cuadernos',
  templateUrl: './cuadernos.component.html',
  styleUrls: ['./cuadernos.component.scss']
})
export class CuadernosComponent implements OnInit {
  numbers:any;

  constructor() {
    this.numbers = Array(380).fill(0).map((x,i)=>this.lpad(i+1,3));
    this.numbers.splice( 2, 1 );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  lpad(value, padding) {
    let zeroes = new Array(padding+1).join("0");
    return (zeroes + value).slice(-padding);
  }

  reportended( event, number ){
    if( number == 380 ){
        number = 1;
    }

    if( number == 2 ){
      number = 3;
    }
    // console.log( "play event: ", event);
    // console.log( "play number: ", number);
    let next:any = document.getElementById('audio'+this.lpad(parseInt(number)+1, 3));
    // console.log("next: ", next);
    next.play();
  }

  reportplay( event ){
    // console.log( "play event: ", event);
    event.target.scrollIntoView({behavior: "smooth"});
  }

  onlyPlayOneIn(container) {
    container.addEventListener("play", function(event) {
    // console.log("detected play");
    let audio_elements = container.getElementsByTagName("audio")
      for(let i=0; i < audio_elements.length; i++) {
        let audio_element = audio_elements[i];
        if (audio_element !== event.target) {
          audio_element.pause();
        }
      }
    }, true);
  }



}
