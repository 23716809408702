import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WpbackendService {
  posts:any;

  constructor( private http: HttpClient ) { }

	getOptions(): Observable<any> | any {
		// console.log("getting options from wp wpbackend");
		return this.http.get<any[]>( environment.apiurl+'wp-json/wp/v2/acf/options', {
	      params: {
	        per_page: '99',
			custom_per_page: '1000'
	      }
	    });
	}

  getPosts(): Observable<any[]> {
    return this.http.get<any[]>( environment.apiurl+'wp-json/wp/v2/media?author=11', {
      params: {
        per_page: '99',
		custom_per_page: '1000'
      }
    });
  }

  getNews(): Observable<any[]> {
	  return this.http.get<any[]>( 'https://45sna.com/news.json' );
  }

  getTerms( taxonomy ): Observable<any[]> {
     return this.http.get<any[]>( 'https://45sna.com/wp-json/wp/v2/'+taxonomy );
  }

  getPostit( type ): Observable<any[]> {

    return this.http.get<any[]>( environment.apiurl+'wp-json/wp/v2/'+type+'?_embed', {
      params: {
        per_page: '1',
        categories: '2,8'
      }
    });
  }

  getList( type ): Observable<any[]> {
    let orderby = 'date';
    if( type == 'curadurias' || type == 'artistas' || type == 'equipo'){
      orderby = 'rand';
    }
    return this.http.get<any[]>( environment.apiurl+'wp-json/wp/v2/'+type+'?_embed', {
      params: {
        per_page: '99',
        orderby: orderby
      }
    });
  }

  getCounter(): Observable<any[]> {
    return this.http.get<any[]>( environment.apiurl+'counter.php' );
  }

  getSingle( slug, type ): Observable<any[]> {
    return this.http.get<any[]>( environment.apiurl+'wp-json/wp/v2/'+type, {
      params: {
        slug: slug
      }
    });
  }

  getCalendar(): Observable<any> | any {
    return this.http.get( 'https://45sna.com/calendar.json' );
  }

  shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

}
