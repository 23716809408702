import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-jackpot',
  templateUrl: './jackpot.component.html',
  styleUrls: ['./jackpot.component.scss']
})
export class JackpotComponent implements OnInit {
  @ViewChild("columna_1") columna_1: ElementRef;
  @ViewChild("columna_2") columna_2: ElementRef;
  @ViewChild("columna_3") columna_3: ElementRef;
  @ViewChild("columna_4") columna_4: ElementRef;
  posts$: Observable<any[]>;
  curadurias$: Observable<any[]>;
  curadurias:Array<any> = [];
  artistas:Array<any> = [];
  artistas_vistos:Array<any> = [];
  artistas_disponibles:Array<any> = [];
  scroll_1:any = 0;
  active_columns:Array<any> = [];
  colores:Array<any> = [];
  links:Array<any> = [];
  running:boolean = false;

  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService
  ) {
    this.posts$ = this.wp.getList( 'artistas' );
	  this.curadurias$ = this.wp.getList( 'curadurias' );
  }


  ngOnInit() {
    this.appService.set_global_color('jackpot negro home');
    this.active_columns = [
      true,
      true,
      true,
      true
    ];
    this.posts$.subscribe((data) => {
      // console.log("Got artistas: ", data);
      this.artistas = data;
      this.artistas_disponibles = data;
      setTimeout(() => { this.startJackpot(); }, 250);
    });
	this.curadurias$.subscribe((data) => {
      // console.log("Got curadurias: ", data);
      data.forEach( (value) => {
		  if( value.acf.color_fondo ){
        if( value.acf.artistas ){
          value.acf.artistas.forEach(
            ( artista ) => {
              this.colores[ artista.ID ] = value.acf.color_fondo;
			  this.links[ artista.ID ] = value.slug;
            }
          );
        }
			  //console.log("links: ", this.links);
		  }
	  });
    });
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  startJackpot(){
  //  $('#jackpot').css('cursor','url("../../assets/images/icon_ruleta2.svg") 0 0, pointer');
  //  $('#jackpot').css('cursor','-webkit-image-set(url("../../assets/images/icon_ruleta2.svg") 1x, url("../../assets/images/icon_ruleta2.svg") 2x) 0 0, pointer');

    if( !this.running ){
      this.running = true;
      if( this.active_columns[0] ){
        this.columna_1.nativeElement.scrollTop = 0;
        this.scrollTo( this.columna_1.nativeElement, this.columna_1.nativeElement.scrollHeight+10, this.getRandomArbitrary(150, 300), 1, 1);
      }
      if( this.active_columns[1] ){
        this.columna_2.nativeElement.scrollTop = 0;
        this.scrollTo( this.columna_2.nativeElement, this.columna_2.nativeElement.scrollHeight+10, this.getRandomArbitrary(150, 300), 1, 1);
      }
      if( this.active_columns[2] ){
        this.columna_3.nativeElement.scrollTop = 0;
        this.scrollTo( this.columna_3.nativeElement, this.columna_3.nativeElement.scrollHeight+10, this.getRandomArbitrary(150, 300), 1, 1);
      }
      if( this.active_columns[3] ){
        this.columna_4.nativeElement.scrollTop = 0;
        this.scrollTo( this.columna_4.nativeElement, this.columna_4.nativeElement.scrollHeight+10, this.getRandomArbitrary(150, 300), 1, 1);
      }
    }else{
      this.running = false;
      setTimeout( () => {
        this.startJackpot();
      }, 250);
    }
  }

  scrollTo(element, to, duration, speed, iteration){
    if( !this.running ){ return };
    if( iteration === 1 ){
      //console.log("Third, now choose");
      element.scrollTop = 0;
      this.selectOne( element );
      return;
    }
    if (duration <= 10 && iteration < 4) {
      console.log("Ended 1, 2");
      setTimeout(() => { element.scrollTop = 0; this.scrollTo(element, to, this.getRandomArbitrary(1100, 1200)*speed, speed+0.0001, iteration+1 );}, 10);
    }else{
      let difference = to - element.scrollTop;
      let perTick = difference / duration * 100;
      // console.log("attempted scroll ", perTick);
      setTimeout(() => {
        if( iteration == 4 ){
          element.scrollTop = element.scrollTop + perTick;
        }else{
          element.scrollTop = element.scrollTop + perTick;
        }
        if (element.scrollTop === to  && iteration == 4) {
          console.log("Arrived");
          return;
        }
        if( iteration == 4 ){
          // console.log("Arriving to: ", to);
          this.scrollTo(element, to, (duration - 10), speed, iteration);
        }else if( iteration > 1){
          this.scrollTo(element, to, (duration - 10)*speed, speed+0.0001, iteration);
        }else{
          this.scrollTo(element, to, duration - 10, speed, iteration);
        }
      }, 100);
    }
  }

  selectOne( columna ){
    columna.scrollTop = 0;
    let max = this.artistas.length;
    let chosen = Math.round( this.getRandomArbitrary(1, max) );
    let elements= columna.children
    // console.log( "SelectOne: ", chosen );
    // console.log("Chosen child: ", elements.item( chosen ) );
    // console.log("Chosen offset: ", elements.item( chosen ).offsetTop );
    // this.scrollTo( columna, elements.item( chosen ).offsetTop, 1000, 1, 4);
	columna.scrollTo({ left: 0, top: 0, behavior: 'auto' });
	columna.scrollTo({ left: 0, top: elements.item( chosen ).offsetTop, behavior: 'smooth' });
  }

  onResize() {
    this.checkActive( );
  }

  checkActive(  ){
    const w = window.innerWidth;
    if (w >= 1200) {
      this.active_columns = [
        true,
        true,
        true,
        true
      ];
    } else if (w >= 860) {
      this.active_columns = [
        true,
        true,
        true,
        false
      ];
    } else if (w >= 600) {
      this.active_columns = [
        true,
        true,
        false,
        false
      ];
    }else{
      this.active_columns = [
        true,
        false,
        false,
        false
      ];
    }
  }

  ngAfterViewInit() {

    this.checkActive()
    if( this.active_columns[0] ){
      this.columna_1.nativeElement.scrollTop = 0;
    }
    if( this.active_columns[1] ){
      this.columna_2.nativeElement.scrollTop = 0;
    }
    if( this.active_columns[2] ){
      this.columna_3.nativeElement.scrollTop = 0;
    }
    if( this.active_columns[3] ){
      this.columna_4.nativeElement.scrollTop = 0;
    }
  }
}
