/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../pipes/translate.pipe";
import * as i5 from "../google.services";
import * as i6 from "../app.service";
import * as i7 from "./popup.component";
var styles_PopupComponent = [i0.styles];
var RenderType_PopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupComponent, data: {} });
export { RenderType_PopupComponent as RenderType_PopupComponent };
function View_PopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["id", "popup_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["id", "popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["id", "popup_topbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "close_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "../assets/images/popup_close.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 19, "div", [["id", "popup_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["class", "alert"], ["src", "../assets/images/popup_alert.png"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵppd(8, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 14, "div", [["id", "popup_buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [["href", "https://es.wikipedia.org/wiki/Principio_cosmol%C3%B3gico"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵppd(17, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggle() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(21, 1), (_l()(), i1.ɵted(22, null, ["", ""])), i1.ɵppd(23, 1), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_5 = _ck(_v, 21, 0, "/oddhorizons"); _ck(_v, 20, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform(i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.messages[(_co.messages_shown - 1)])))); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 14).transform(i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 0), "ok")))); _ck(_v, 12, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 18).transform(i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent, 0), "info")))); _ck(_v, 16, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 20).target; var currVal_4 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 24).transform(i1.ɵunv(_v, 22, 0, _ck(_v, 23, 0, i1.ɵnov(_v.parent, 0), "Viajar")))); _ck(_v, 22, 0, currVal_6); }); }
export function View_PopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TranslatePipe, [i5.GoogleService, i6.AppService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-popup", [], null, null, null, View_PopupComponent_0, RenderType_PopupComponent)), i1.ɵdid(1, 114688, null, 0, i7.PopupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupComponentNgFactory = i1.ɵccf("app-popup", i7.PopupComponent, View_PopupComponent_Host_0, {}, {}, []);
export { PopupComponentNgFactory as PopupComponentNgFactory };
