import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { WpbackendService } from '../wpbackend.service';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';

@Component({
  selector: 'app-newscolumn',
  templateUrl: './newscolumn.component.html',
  styleUrls: ['./newscolumn.component.scss']
})
export class NewscolumnComponent implements OnInit {
  news$: Observable<any[]>;
  show:boolean = false;
  interval:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wp: WpbackendService,
    private appService: AppService
  ) {
    this.news$ = this.wp.getNews();
	  this.show = false;
  }

  toggle(){
    this.show = !this.show;
  }

  ngOnInit() {
    console.log("scrolltop", document.getElementById('news_column_list').scrollTop );
    // this.interval = setInterval( () => {
    //   this.incrementScroll();
    // }, 35);
  }

  incrementScroll(){
    if( this.show ){
      document.getElementById('news_column_list').scrollTop = document.getElementById('news_column_list').scrollTop+1
    }
  }

}
