/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sedes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sedes.component";
import * as i3 from "../app.service";
var styles_SedesComponent = [i0.styles];
var RenderType_SedesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SedesComponent, data: {} });
export { RenderType_SedesComponent as RenderType_SedesComponent };
export function View_SedesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "timeline_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "images"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [["height", "480"], ["src", "https://www.google.com/maps/d/u/2/embed?mid=1z2R3D9iUC5V4N5C8i7Moc6BCOw7mj2Bs"], ["width", "640"]], null, null, null, null, null))], null, null); }
export function View_SedesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sedes", [], null, null, null, View_SedesComponent_0, RenderType_SedesComponent)), i1.ɵdid(1, 114688, null, 0, i2.SedesComponent, [i3.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SedesComponentNgFactory = i1.ɵccf("app-sedes", i2.SedesComponent, View_SedesComponent_Host_0, {}, {}, []);
export { SedesComponentNgFactory as SedesComponentNgFactory };
