/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./streaming.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./streaming.component";
import * as i4 from "@angular/router";
import * as i5 from "../wpbackend.service";
import * as i6 from "../app.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common/http";
var styles_StreamingComponent = [i0.styles];
var RenderType_StreamingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StreamingComponent, data: {} });
export { RenderType_StreamingComponent as RenderType_StreamingComponent };
function View_StreamingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "post_single"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], [[1, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "iframe", [["allowFullScreen", "true"], ["allowTransparency", "true"], ["frameborder", "0"], ["height", "350px"], ["scrolling", "no"], ["style", "border:none;overflow:hidden"], ["width", "95%"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SetStreamingURL(_v.parent.parent.context.$implicit.acf.stream, _v.parent.parent.context.index, _v.parent.parent.context.$implicit.acf.proveedor); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.parent.context.index; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.urlArray[_v.parent.parent.context.index]; _ck(_v, 3, 0, currVal_2); }); }
function View_StreamingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "li_inner_container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StreamingComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit._embedded["wp:term"][0][0].slug == _v.parent.parent.context.$implicit.slug); _ck(_v, 2, 0, currVal_0); }, null); }
function View_StreamingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "li_container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StreamingComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit._embedded; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StreamingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "ul", [["class", "posts_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StreamingComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.posts$)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_StreamingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["class", "tipovideo_list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_StreamingComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.categories$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StreamingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-streaming", [], null, null, null, View_StreamingComponent_0, RenderType_StreamingComponent)), i1.ɵdid(1, 114688, null, 0, i3.StreamingComponent, [i4.ActivatedRoute, i4.Router, i5.WpbackendService, i6.AppService, i7.DomSanitizer, i8.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StreamingComponentNgFactory = i1.ɵccf("app-streaming", i3.StreamingComponent, View_StreamingComponent_Host_0, {}, {}, []);
export { StreamingComponentNgFactory as StreamingComponentNgFactory };
