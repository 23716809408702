import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-motta',
  templateUrl: './motta.component.html',
  styleUrls: ['./motta.component.scss']
})
export class MottaComponent implements OnInit {
  today:any;
  today_start:any;
  minutes_passed:any;
  interval:any;
  prev:any;
  next:any;
  src:any = 'https://45sna.com/archivos/motta/0000.png';
  prev_src:any = 'https://45sna.com/archivos/motta/0000.png';
  next_src:any = 'https://45sna.com/archivos/motta/0000.png';
  reloj_src:any = 'https://45sna.com/archivos/motta/reloj000.png';
  reloj_prev_src:any = 'https://45sna.com/archivos/motta/reloj000.png';
  reloj_next_src:any = 'https://45sna.com/archivos/motta/reloj000.png';
  sec:any;
  visible:boolean = false;

  set_1:Array<string>;
  set_2:Array<string>;
  set_3:Array<string>;
  set_4:Array<string>;

  set_1_disponibles:Array<string>;
  set_2_disponibles:Array<string>;
  set_3_disponibles:Array<string>;
  set_4_disponibles:Array<string>;

  current_frase: any;

  constructor() {

  }

  ngOnInit() {
	  this.interval = setInterval( () => {
		  //console.log("Updating time...");
		  this.updateTime();
	  }, 1000 );

	  this.set_1 = [
		  '<strong>Wampâna nsèngo, kunkâmbi kwè ngâtu bwè isadila yo ko</strong><br />Se você me dá uma enxada, não me diga onde ou como eu devo usá-la.<br>Si me das un azadón, no me digas dónde o cómo debo usarlo.<br>If you give me a hoe, don\'t tell me where or how I should use it.',
		  '<strong>Kânda diansânsa, kânda isânsa</strong><br />A comunidade cuidou de mim; eu cuidarei dessa comunidade.<br>La comunidad me cuidó; yo cuidaré de ella.<br>The community took care of me; I will take care of it.',
		  '<strong>Ku kânda dia mbadio mpaka (ntantani) ze\’ ko; nga ziswèmi mu dia diâku zazèyi?</strong><br />Existem muitos conflitos dentro de determinada comunidade; você imagina quantos existem na sua?<br>Existen muchos conflictos dentro de una determinada comunidad; ¿Te imaginas cuántos hay en la tuya?<br>There are many conflicts within a given community; Can you imagine how many are in yours?',
		  '<strong>Lumbu-ki bisikânda, mbazi bakulu ba kânda</strong><br />Hoje somos os membros da comunidade; amanhã seremos os ancestrais da comunidade.<br>Hoy somos los miembros de la comunidad; mañana seremos los antepasados de la comunidad.<br>Today we are the members of the community; Tomorrow we will be the ancestors of the community.',
		  '<strong>N\’samu mia kânda miale\’ bulungi, variante N\’samu mia kânda ka mivwîdi bulungi ko: mia ndo ka ndo</strong><br />Os problemas da comunidade não têm aniversário; elas acontecem a qualquer hora.<br>Los problemas de la comunidad no cumplen años; suceden en cualquier momento.<br>The problems of the community do not turn years; They happen at any time.',
		  '<strong>Makani ma kânda, mwâna mu ntûnda; ka matewanga nkûmbu ko</strong><br />Planos da comunidade são uma criança no útero de sua mãe; não possuem nome.<br>Los planes de la comunidad son un niño en el útero de su madre; no tienen nombre.<br>The community plans are a child in his mother\'s womb; They have no name.',
		  '<strong>V\’imo v\’epya</strong><br />Ventre é campo. (Sabes o que semeias, não sabes o que colhes.)<br>Vientre es campo. (Sabes lo que siembras, no sabes lo que cosechas).<br>Womb is field. (You know what you sow, you don\'t know what you reap).',
		  '<strong>Ohombo ikula l\’olombinga</strong><br />O cabrito cresce conjuntamente com os chifres.<br>La cabra crece junto con los cuernos.<br>The goat grows along with the horns.',
		  '<strong>Owi watwa, hotanhako</strong><br />Luar claro não é sol.<br>Luz de luna clara no es sol.<br>Clear moonlight is not sun.',
		  '<strong>Kisikanda, vo ka butukila ko (mu) bukwangi </strong><br />Por nascimento ou acolhimento, faz-se parte da comunidade.<br>Por nacimiento o por acogida, se hace parte de la comunidad.<br>By birth or by welcome, one becomes part of the community.',
		  '<strong>Kiasola kanda ko; kanda diansola</strong><br />Não escolhi a comunidade que me escolheu.<br>No elegí la comunidad que me eligió.<br>I did not choose the community that chose me.',
		  '<strong>Mu kanda, babo longa ye longwa</strong><br />Na comunidade, todos ensinam e são ensinados.<br>En la comunidad, todos enseñan y se les enseña.<br>In the community, everyone teaches and is taught.',
		  '<strong>Ondaka ya tete, oyo uveli wetwi</strong><br />A primeira palavra é a porta do ouvido.<br>La primera palabra es la puerta del oído.<br>The first word is the door of the ear.',
		  '<strong>Nwa, nwa p\’ohanda: omol\’ombambi olilongisa okutongela</strong><br />Olha bem para a pedra de pisar: o cabritinho aprende a saltar.<br>Mira bien el camino de piedra: la cabrita aprende a saltar.<br>Look closely at the stone path: the little goat learns to jump.',
		  '<strong>Owi k\’epya l\’okuteya atemo, ondaka ivi l\’okuteya utima</strong><br />Raiz do campo a partir enxada, palavras amargas a quebrar corações.<br>Raíz del campo parte el azadón, palabras amargas que rompen el corazón.<br>Root from the field breaks the hoe, bitter words that break the heart.',
		  '<strong>Okulivala l\’onhengo kulongola onganji, elimi lyonjuka liteya akepa</strong><br />Sossegar com raiva modera as palavras, palavra mansa corta ossos.<br>Sosegar con rabia aplaca las palabras,  palabra mansa corta huesos. <br>Soothing with anger appeases the words, meek word cuts bones.',
		  '<strong>Ondalu hokuluko l\’ovava, ochiyo chyapita p\’okati</strong><br />O fogo não é mais forte que a água, a panela é que pôs barreira.<br>El fuego no es más fuerte que el agua, fue la olla la que puso una barrera.<br>Fire is not stronger than water, it was the pot that put a barrier.',
		  '<strong>Wandiwa, kasonginhawa k\’ondalu</strong><br />Ao que apanhou chuva não é preciso aconselhar-lhe o calor do fogo.<br>Al que con lluvia se ha mojado no es necesario aconsejarle el calor del fuego.<br>To the one who has got wet with rain it is not necessary to advise the heat of the fire.',
		  '<strong>Nda olonda fetikila sila lyuti</strong><br />Se sobes, começa na base da árvore.<br>Si subes, comienza en la base del árbol.<br>If you go up, start at the base of the tree.',
		  '<strong>Nda olonga omol\’ove olondungue, olongela v\’onjo</strong><br />Se ensinares ao teu filho, prosperidade terá em sua casa.<br>Si le enseñas a tu hijo, prosperidad habrá en tu casa.<br>If you teach your child, prosperity will be in your house.',
		  '<strong>China kwalile kalikunali v\’imo – (ekunde kwalile...)</strong><br />O que não comeste, não te provocas dores de estômago.<br>Lo que no comiste, no te causa dolor de estómago.<br>What you did not eat does not cause you a stomachache.',
		  '<strong>Okulonga omol\’ohombo utwe v\’ombuya</strong><br />Ensinar o cabrito, só com a cabeça na panela. (O indócil só com cabeçadas aprende.)<br>Enseñarle al niño, solo con la cabeza en el sartén. (El indócil sólo a cabezazos aprende) <br>Teach the child, only with the head in the pan. (The disobedient only learn by hitting themselves on the head).',
		  '<strong>Silongwa wenda l\’ombuya k\’uavala</strong><br />Quem não aceita conselhos, faz casamentos ruins.<br>Quien no acepta consejos, celebra malos matrimonios.<br>Who does not accept advice, celebrates bad marriages.',
		  '<strong>Walyanga k\’uti wolosywa, ofela wayisya k\’onhima </strong><br />Correu a colher os frutos da arvore, mas esqueceu-se do vento.<br>Corrió a recoger los frutos del árbol, pero se olvidó del viento.<br>One ran to collect the fruits of the tree, but forgot the wind.',
		  '<strong>Ochimbenje kachilitapi</strong><br />A cabaça não se enche a si própria.<br>La calabaza no se llena sola.<br>The calabash does not fill itself.'
	  ];

	  this.set_2 = [
		  '<strong>Wasînga kânda ukisîngidi.</strong><br />Se você ofende a comunidade, você ofende a si mesmo.<br>Si ofendes a la comunidad, te ofendes a ti mismo.<br>If you offend the community, you offend yourself.',
		  '<strong>Kânda diafûka nza yifûkidi.</strong><br />Se o conceito de comunidade for destruído, o mundo será destruído.<br>Si el concepto de comunidad es destruido, el mundo será destruido.<br>If the concept of community is destroyed, the world will be destroyed.',
		  '<strong>Dièla dia kânda m\’bikudi.</strong><br />A sabedoria da comunidade profetiza. (A comunidade enxerga mais longe do que pode um indivíduo. Qualquer um que aprende a enxergar por meio dos olhos da comunidade é uma pessoa brilhante.)<br>La sabiduría de la comunidad profetiza. (La comunidad ve más allá de lo que un individuo puede. Cualquiera que aprende a ver a través de los ojos de la comunidad es una persona brillante).<br>The wisdom of the community prophesies. (The community sees beyond what an individual can. Anyone who learns to see through the eyes of the community is a brilliant person.)',
		  '<strong>Olosapo vyakuka, nd\’uwalo wafundala, echi wavita vyalinga vyokaliye</strong><br />Histórias velhas, como roupa enxovalhada, ao contá-las, tornam-se novas.<br>Las viejas historias, como la ropa deshilachada, al contarlas, se vuelven nuevas. <br>Old stories, like frayed clothes, when told, become new.',
		  '<strong>Hulukãyi wachipopya, walitenga v\’onetekela</strong><br />A velha firma suas palavras misturando-se com a terra.<br>La anciana firma sus palabras mezclándose con la tierra.<br>The old woman undersigns her words by mingling with the earth.',
		  '<strong>Okahulukãyi nda wakweta kukolemele</strong><br />Se a velhinha te quer bater, não a detenhas.<br>Si la anciana quiere golpearte, no la detengas. <br>If the old woman wants to hit you, don\'t stop her.',
		  '<strong>Ohuluwa wotunda owele, wanda l\’ulongo l\’ofeka</strong><br />Se a velha ganhou cabelo branco, andou com juízo na terra.<br>Si la anciana adquirió cabellos blancos, eso es que anduvo con juicio en la tierra.<br>If the old woman pulled out white hair, it was that she walked with judgment on the earth.',
		  '<strong>Kakala kanhanga</strong><br />Quem não permanece, não colhe.<br>Quien no permanece, no cosecha.<br>Who does not remain, does not reap.',
		  '<strong>Kalunga k\’okufa nda weya, tutilila PI? Evela lyavela </strong><br />A morte quando vier, onde nos esconderemos? O superior é superior.<br>Cuando llegue la muerte, ¿dónde nos esconderemos? Lo superior es superior.<br>When death arrives, where will we hide? The superior is superior.',
		  '<strong>Ekosi kalimoleha, ukwene okilimwila</strong><br />O pescoço não se vê, outro o vê por ti.<br>El cuello no se ve, otro lo ve por ti.<br>The neck is not seen, another sees it for you.',
		  '<strong>Twakulu nd\’ono, twasyahulu, nd\’iputa</strong><br />Velho como a nascente, antigo como o pirão.<br>Viejo como a naciente, antiguo como caldo.<br>Old as the fountain, ancient as the broth.',
		  '<strong>Ukulu wa nende k\’omela kuyweta</strong><br />Velha rola tem o bico mole. (A velhice é uma segunda infância).<br>La vieja tórtola tiene el pico blando. (La vejez es una segunda infancia).<br>The old turtledove has a soft beak. (Old age is a second childhood).',
		  '<strong>Ikumba-kumba vya ndumba kachyembi ongolo okunhwa ovava</strong><br />Os urros do leão não impedem a zebra de beber água.<br>Los rugidos del león no impiden que la cebra tome agua.<br>The lion\'s roars do not prevent the zebra from drinking water.',
		  '<strong>V\’onjo muli uta, hamo mwingingila ongwe</strong><br />Casa que tem espingarda, nela entra o leopardo. (Uma coisa atrai a outra.)<br>A casa con escopeta, llega el leopardo. (Una cosa atrae la otra). <br>In a home with a shotgun, the leopard enters. (One thing attracts the other).',
		  '<strong>Chipopya ongoma yevelela k\’ochlengi, chipopya omanu limbuka k\’ondaka apa ipandekela</strong><br />O que diz o tambor, ouve-o no som; o que dizem as pessoas, ouve-o na direção do vento.<br>Lo que dice el tambor, se escucha en el sonido; lo que dice la gente se escucha en la dirección del viento.<br>What the drum says is heard in the sound; What people say is heard in the direction of the wind.',
		  '<strong>Kanda diazungulwa lusunga lwa kimfumu-dikanda luzimbale</strong><br />Liderança perdida, comunidade oprimida.<br>Liderazgo perdido, comunidad oprimida.<br>Lost leadership, oppressed community.',
		  '<strong>V\’omela mwochita chyolondaka, hamwochita chyovayo</strong><br />Na boca há centenas de palavras, não há centenas de dentes.<br>En la boca hay cientos de palabras, no hay cientos de dientes.<br>In the mouth there are hundreds of words, there are not hundreds of teeth.',
		  '<strong>Ondalu iwa p\’okuyota, ivi p\’okupya</strong><br />O fogo é bom para nos aquecer, mau para nos queimar.<br>El fuego es bueno para calentarnos, malo para quemarnos.<br>Fire is good for warming us, bad for burning us.',
		  '<strong>Ondaka ondindi, ondindi, onhingili ukanda</strong><br />Palavra é pássaro de cauda comprida, a escrita é um passarinho<br>La palabra es un pájaro de cola larga, la escritura es un pajarito.<br>The word is a long-tailed bird, the writing is a little bird.',
		  '<strong>Ondaka eondombo ivangwiwa v\’okwenhe, ondaka yokwenhe ivangwiwa v\’ondombo</strong><br />As chuvas discutem-se no tempo seco, o tempo seco discutem-se nas chuvas.<br>La lluvia se discute en clima seco, el clima seco se discute en la lluvia.<br>Rain is discussed in dry weather, dry weather is discussed in rain.',
		  '<strong>Omota ywve, hanimako yochimbanda</strong><br />O colar do doente não é pagamento para o feiticeiro. (Não se paga um grande favor com um pequeno.)<br>El collar del enfermo no es recompensa para el brujo. (No pagas un gran favor con uno pequeño).<br>The patient\'s collar is not a payment for the shaman. (You don\'t pay a big favor with a small one).',
		  '<strong>Umbumbulu syuvundila v\’ombya</strong><br />A minha pequenez não escondo na panela.<br>Mi pequeñez no escondo en el sartén.<br>I don\'t hide my littleness in a pot.',
		  '<strong>Mbewu kalondi k\’ochisingi, omanu vokapamo</strong><br />A tartaruga não sobe em árvore, os homens é que a puseram lá. (Não há efeito sem causa.)<br>Tortuga no sube en árbol, fueron los hombres quienes la pusieron allí. (No hay efecto sin causa).<br>The turtle does not climb the tree, it was the men who put it there. (There is no effect without cause).',
		  '<strong>Olombinga vyombambi kavikutiwa v\’onanga</strong><br />Chifres de cabra não se escondem num pano.<br>Los cuernos de la cabra no se esconden en una tela.<br>The goat\'s horns do not hide in a cloth.',
		  '<strong>Ombundu yok\’ombaka, ombela yok\’onano</strong><br />Nevoeiro no mar, chuva no planalto. (O que se passa ao longe tem consequências aqui.)<br>Neblina en el mar, lluvia en la meseta. (Lo que sucede en la distancia tiene consecuencias aquí).<br>Fog in the sea, rain on the plateau. (What happens in the distance has consequences here).'
	  ];

	  this.set_3 = [
		  '<strong>Mbûngi a kânda va kati kwa nsi ye yulu.</strong><br />O centro da comunidade está localizado entre o mundo de cima e o mundo de baixo.<br>El centro de la comunidad está localizado entre el mundo de arriba y el mundo de abajo.<br>The center of the community is located between the world above and the world below.',
		  '<strong>Olukema; tukema; Kalunga watunga onjo ko kalundu</strong><br />Fazer curativos, fazemos; mas a morte fez casa no cemitério.<br>Curativos, hacemos; pero la muerte ya hizo su hogar en el cementerio. <br>Healing making, we do; but death made its home in the cemetery.',
		  '<strong>Okalwi watomba, hako kakutwala</strong><br />Rio que desprezaste, ele te levou.<br>Río que despreciaste, río que te llevó.<br>River that you despised, river that took you.',
		  '<strong>Chyafa lavi, vachikenda lavi</strong><br />Quem morreu mal, enterra-se mal.<br>Quien mal murió, mal se enterró. <br>Who died badly, badly was buried.',
		  '<strong>Okufa kwovakulu, okutangala kuolonenge</strong><br />O morrer é dos velhos, o desaparecer é dos jovens.<br>El morir es de los viejos, el desaparecer es de los jóvenes. <br>Dying is of the old, disappearing is of the young.',
		  '<strong>Wafa kalikendi eye mwele; okwoko kusyla posamwa</strong><br />Quem morreu não se enterra a si próprio; o braço ficará de fora.<br>Quien murió no se entierra a sí mismo; el brazo le quedará por fuera. <br>Who died does not bury itself; its\' arm will be left out.',
		  '<strong>Olongupa kavipwi p\’osi, ondaka ivi kayipwi k\’utima</strong><br />O amendoim não acaba na terra, a palavra ruim não acaba no coração.<br>El maní no termina en la tierra, la palabra vil no acaba en el corazón.<br>Peanuts do not end on earth, the word vile does not end in the heart.',
		  '<strong>Ondele emaluvo, umbanda ututu</strong><br />O espírito do doente é fala, do médico é ocultar.<br>El espíritu del enfermo es hablar, el del médico callar.<br>The spirit of the sick is to speak, that of the doctor is to hide.',
		  '<strong>Ochihamba nda ukwama, olingavo ochihamba</strong><br />Se acompanhas o feiticeiro, feiticeiro se tornará.<br>Si caminas junto al brujo, brujo te volverás. <br>If you walk next to the sorcerer, sorcerer you will become.',
		  '<strong>Kandingili walivanga k\’eleva, kapingala walivanga k\’onjembo</strong><br />O desprezado foi à frente para a caverna, o que herdaria foi à frente para a sepultura. (A morte não faz distinção.)<br>O desprezado foi à frente para a caverna, o que herdaria foi à frente para a sepultura (A morte não faz distinção). Trad literal: El despreciado fue adelante hacia la cueva, lo que heredaría era ir adelante hacia la tumba (La muerte no hace distinciones.)<br>The rejected one that went in front, of the cave, would inherit to go in front of the grave (Death does not make distinctions.)',
		  '<strong>Ungungu walikuta, kalunga wapambekela</strong><br />O rico cruzou os braços, mas a morte pôs a flecha no arco. (Da morte ninguém escapa.)<br>El hombre rico se cruzó de brazos, pero la muerte puso su flecha en el arco. (De la muerte nadie escapa).<br>The rich man crossed his arms, but death put the arrow in the bow. (No one escapes death).',
		  '<strong>Ia ndindima yiloka eveke</strong><br />Chuva que troveja antes de cair só molha o tolo.<br>Lluvia que truena antes de caer solo moja al tonto. <br>Rain that thunders before falling just wets the fool.',
		  '<strong>kuvanja kuombua, kutima kuvala</strong><br />Olhar só como um cão, enquanto doi o coração.<br>Mirar solo como un perro, mientras_duele el corazón. <br>To watch alone like a dog while your heart hurts.',
		  '<strong>Ove O yongola Ondalu O tala Ohali l\'owisi</strong><br />Tu que queres o fogo, tens que aguentar o fumo.<br>Si_quieres fuego, tienes que aguantar el humo.<br>If you want fire, you have to put up with the smoke.',
		  '<strong>Etumba we lilonga halio liu ku lia</strong><br />Ele está se fritando na própria gordura. <br>Se está friendo en su propia grasa. <br>He is frying himself in his own fat.',
		  '<strong>Uosi o yongola chiosi, chiosi chi nyelela</strong><br />Quem tudo quer, tudo perde.<br>Quien todo quiere, todo pierde.<br>Who wants everything, everything loses.',
		  '<strong>O anda lika, O a muile echi chiu lia, ka muile echi chiu sapuila</strong><br />Aquele que viajou sozinho viu o que lhe devorou, não aquele que poderia ter lhe salvado. <br>El que viajó solo vio lo que lo devoró, no lo que podría haberlo salvado.<br>The one who traveled alone saw what devoured him, not what could have saved him.',
		  '<strong>Esalamiho liulume ka li enda enda no posi</strong><br />O suor de um homem não cai ao chão à toa. <br>El sudor de un hombre no cae al suelo vanamente.<br>A man\'s sweat does not fall to the ground in vain.',
		  '<strong>Ba nkua n\'sanga malaka nkatu; ba nkua malaka n\'sanga nkatu (bena ye n\'sanga malaka nkatu; bena ye malaka n\'sanga nkatu)</strong><br />Aqueles que possuem colares às vezes não tem pescoço. <br>Esos que poseen collares a veces no tienen cuello.<br>Those who have necklaces sometimes have no neck.',
		  '<strong>Nga kumi kuani diodio dia nkaka zengele u lend\'e?</strong><br />Se não consegues carregar os primeiros dez paus, como carregará os próximos dez? <br>Si no puedes cargar los primeros diez palos, ¿cómo cargarás los diez que siguen? <br>If you cannot load the first ten sticks, how will you load the next ten?',
		  '<strong>Bungudi ku die longo luami ko. Longo ku die bungudi buami ko</strong><br />Solidariedade não arruinará meu casamento. <br>La solidaridad no arruinará mi matrimonio. <br>Solidarity will not ruin my marriage.',
		  '<strong>Dia fuafua dia nkulu, kansi ku die diambu dia nkulu ko</strong><br />Faça o que quiser com as posses do morto, o seu tesouro está nas palavras. <br>Haz lo que quieras con las posesiones del muerto, tu tesoro está en las palabras.<br>Do what you want with the possessions of the dead, your treasure is in the words.',
		  '<strong>Diambu wu n\'ta ye nkua ntona, nguba wu n\'dia ye nkua tandi</strong><br />Discuta com quem tem bom senso, e coma amendoins com alguém que tem dentes. <br>Discute con quién tenga buen sentido común y come maní con alguien que tenga dientes.<br>Discuss with whom has a good common sense and eat peanuts with someone who has teeth.',
		  '<strong>Ukwene nda otehã ove liseya momo ngolo la ngolo ka calisokele</strong><br />Se o outro pula, tu te arrastre, porque os joelhos não são iguais.<br>Si el otro salta, tu te arrastras, porque las rodillas no son iguales.<br>If the other jumps, you crawl, because the knees are not the same.',
		  '<strong>U kuendi laye kakukutila ko epunda</strong><br />Não prepara a mala de quem contigo não viaja.<br>No prepares la maleta de quien contigo no viaja.<br>Do not prepare the suitcase of those who do not travel with you.'
	  ];

	  this.set_4 = [
		  '<strong>Kânda i (mbûndani a) bafwa ye bamôyo.</strong><br />A comunidade é a união entre os ancestrais e os vivos.<br>La comunidad es la unión entre los antepasados y los vivos.<br>The community is the union between the ancestors and the living.',
		  '<strong>Kalunga nkoko unene, lungila meso, k\'ulungila ntambi ko</strong><br />Calunga é um grande rio que se pode percorrer com os olhos, mas não com as pernas.<br>Calunga es un gran río que se puede recorrer con los ojos, pero no con las piernas.<br>Calunga is a great river that can be traveled with the eyes, but not with the legs.',
		  '<strong>Ekumbi lyateleka asola, oloneke vitilamo ovava, ofela ipepelela p\’iko</strong><br />O sol cozinhou os grãos de milho, os dias despejaram água sobre eles, o vento assopra o fogo.<br>El sol cocinó los granos de maíz, los días vertieron agua sobre ellos, el viento sopla el fuego.<br>The sun cooked the corn kernels, the days poured water on them, the wind blows the fire.',
		  '<strong>Ekumbi oku lyenda kuli akulu, oku litunda kuli akulu</strong><br />Para onde vai o sol, há velhos: donde vem, velhos há.<br>Hacia donde va el sol, hay personas mayores: de dónde viene, personas mayores hay.<br>Where the sun goes, there are older people: where it comes from, older people are there.',
		  '<strong>Okwila kunhola ofeka, uhasi unhola omanu</strong><br />O êxodo estraga a terra, a riqueza estraga os homens. <br>El éxodo arruina la tierra, la riqueza arruina a los hombres.<br>Exodus ruins the earth, wealth ruins men.',
		  '<strong>Kanda ka ditungwanga va lweka lwa fu kia nsi ko</strong><br />A comunidade, assim como a nação, não se constrói fora do seu sistema.<br>La comunidad, así como la nación, no se construye fuera de su sistema.<br>The community, as well as the nation, is not built outside its system.',
		  '<strong>Wasinga kanda ukisingidi</strong><br />Ofender a comunidade é ofender a si.<br>Ofender a la comunidad es ofenderse a sí mismo.<br>To offend the community is to offend yourself.',
		  '<strong>Kanda nkasa ye nome/niosi</strong><br />Comunidade é veneno e mel.<br>Comunidad es veneno y miel.<br>Community is poison and honey.',
		  '<strong>Omemba vopilia kuyela</strong><br />Barro branco busca-se em terra branca. (“Omemba” a argila sagrada usada no corpo nos ritos de puberdade é símbolo da inocência e da pureza do mundo espiritual.)<br>Arcilla blanca en tierra blanca. ("Omemba", la arcilla sagrada utilizada en el cuerpo en los ritos de la pubertad es un símbolo de la inocencia y de la pureza del mundo espiritual).<br>White clay on white ground. ("Omemba", the sacred clay used in the body in the rites of puberty is a  symbol of the innocence and purity of the spiritual world).',
		  '<strong>Mbungi a kanda va kati kwa nsi ye yulu</strong><br />O vão originário da comunidade, no centro, entre a terra e o céu.<br>El vacío originario de la comunidad está en el centro, entre la tierra y el cielo.<br>The originative void of the community is in the center, between the earth and the sky.',
		  '<strong>Bandu dia kanda nsang\’a a muntu ye n\’toto</strong><br />A base da comunidade é o humano e a terra.<br>La base de la comunidad es el ser humano y la tierra.<br>The base of the community is the human being and the earth.',
		  '<strong>Wakatuka mu n\’kingu mia fu kia kanda kitukidi mapeka ye wungani</strong><br />Abandonar os princípios do sistema da comunidade é errância.<br>Abandonar los principios del sistema de la comunidad es erróneo.<br>Abandoning the principles of the community system is wrong.',
		  '<strong>Mu kanda ka mwena nzaku (za n\’toto) ko</strong><br />Dentro da comunidade, não há fronteiras.<br>Dentro de la comunidad, no hay fronteras.<br>Within the community, there are no borders.',
		  '<strong>Ovindele visoneha olondaka v\’amikanda, etu tuvisonehela v\’olukolo</strong><br />Os brancos escrevem livros, nós escrevemos no peito.<br>Los blancos escriben libros, nosotros escribimos en el pecho.<br>The white write books, we write on the chest.',
		  '<strong>Nzambi mu kanda (kena)</strong><br />A Totalidade-Ancestral-Sempre-Presente é na comunidade.<br>La Totalidad-Ancestral-Siempre-Presente existe en la comunidad.<br>The Ancestral-Totality-Always-Present is in the community.',
		  '<strong>Vo zeyi kanda, zeyi Nzambi</strong><br />Se conhece a comunidade, conhece a Totalidade-Ancestral-Sempre-Presente.<br>Si conoces a la comunidad, conoces la Totalidad Ancestral-Siempre-Presente.<br>If you know the community, you know the Ancestral-Totality-Always-Present.',
		  '<strong>Untèla n\’kingu miankulu (mia kanda) kidi yazaya miampa/ Wata diampa teka ta diankulu</strong><br />Princípios antigos, para compreender os novos.<br>Principios antiguos, para entender los nuevos.<br>Old principles, to understand the new ones.',
		  '<strong>Kanda mukutu/ Kanda mutu</strong><br />Antes, a comunidade.<br>Antes, la comunidad.<br> The community first.',
		  '<strong>Kapasui okuokuo, olondunge visuapo</strong><br />Onde não cabe o braço, a inteligência resolve.<br>Donde no cabe el brazo, la inteligencia resuelve.<br>Where the arm does not fit, intelligence resolves.',
		  '<strong>Unene ua ngandu kovava</strong><br />A força do jacaré está na água.<br>La fuerza del caimán está en el agua.<br>The strength of the alligator is in the water.',
		  '<strong>Epute liukuene kaliukuvala</strong><br />Cada ferida dói a quem a tem<br>Cada herida le duele a quien la tiene<br>Each wound hurts those who have it.',
		  '<strong>Cimboto t\'ulemela okuyuela, puãi oku sonama kuaye muenle</strong><br />Ao sapo pedimos apenas que não faça barulho. (É nulo pedir que deixe de andar cócoras; por acaso, ele sabe andar de outro modo?)<br>Al sapo solo le pedimos que no haga ruido. (Es inútil pedirle que deje de ponerse en cuclillas; ¿acaso sabe cómo caminar de otra manera?)<br>We only ask the toad not to make noise. (It is useless to ask it to stop squatting; does it know any other way of walking?)',
		  '<strong>Ocitungu walikutila kacilemi</strong><br />O fardo que amarraste a ti mesmo não é pesado. <br>La carga que tu mismo te ataste no es pesada.<br>The burden you tied to yourself is not heavy.',
		  '<strong><a href="https://www.youtube.com/watch?v=UKJuHmtMRKk" target="_blank">Aparecida - "Todo mundo é preto"</a></strong><br /><a href="https://www.youtube.com/watch?v=UKJuHmtMRKk" target="_blank">Aparecida - "Todo mundo é preto"</a><br><a href="https://www.youtube.com/watch?v=UKJuHmtMRKk" target="_blank">Aparecida - "Todo el mundo es negro"</a><br><a href="https://www.youtube.com/watch?v=UKJuHmtMRKk" target="_blank">Aparecida - "Everyone is black"</a>',
		  '<strong><a href="https://www.youtube.com/watch?v=r3zY7-wJVCc" target="_blank">Clementina de Jesus "Na linha do mar""</a></strong><br /><a href="https://www.youtube.com/watch?v=r3zY7-wJVCc" target="_blank">Clementina de Jesus "Na linha do mar""</a><br><a href="https://www.youtube.com/watch?v=r3zY7-wJVCc" target="_blank">Clementina de Jesus "En la línea del mar"</a><br><a href="https://www.youtube.com/watch?v=r3zY7-wJVCc" target="_blank">"On the line of the sea"</a>'
	  ];

	  this.set_1_disponibles = this.set_1.slice();
	  this.set_2_disponibles = this.set_2.slice();
	  this.set_3_disponibles = this.set_3.slice();
	  this.set_4_disponibles = this.set_4.slice();
  }

  toggle(){
	  this.visible = false;
  }

  show(){
	  if( this.minutes_passed >= 0 && this.minutes_passed <  360 ){
		  this.current_frase = this.chooseFrase( this.set_1_disponibles );
	  }else if( this.minutes_passed >= 360 && this.minutes_passed < 720 ){
		  this.current_frase = this.chooseFrase( this.set_2_disponibles );
	  }else if( this.minutes_passed >= 720 && this.minutes_passed < 1080 ){
		  this.current_frase = this.chooseFrase( this.set_3_disponibles );
	  }else if( this.minutes_passed >= 1080 && this.minutes_passed < 1440 ){
		  this.current_frase = this.chooseFrase( this.set_4_disponibles );
	  }

	  this.visible = true;
  }

  chooseFrase( tipo ){
	  let random = Math.floor(Math.random()*tipo.length);
      let palabra = tipo[ random ];
      tipo.splice(random, 1);
      // console.log("Opcion elegida: ", palabra );
      // console.log("Opciones restantes: ", tipo );
      if( tipo.length == 0 ){
        // console.log("Reiniciando familia");
        this.reiniciarFamilia( tipo );
      }
      return palabra;
  }

  reiniciarFamilia( tipo ){
	  if( tipo === this.set_1_disponibles ){
        this.set_1_disponibles = this.set_1.slice();
      }
	  if( tipo === this.set_2_disponibles ){
        this.set_2_disponibles = this.set_2.slice();
      }
	  if( tipo === this.set_3_disponibles ){
        this.set_3_disponibles = this.set_3.slice();
      }
	  if( tipo === this.set_4_disponibles ){
        this.set_4_disponibles = this.set_4.slice();
      }
  }

  pad(num, size) {
	let s = "000000000" + num;
	return s.substr(s.length-size);
  }

  updateTime(){
	  this.today = new Date();
	  let today_time = Math.floor( this.today.getTime() / 1000 );
	  let year = this.today.getFullYear();
	  let month = this.today.getMonth();
	  let day = this.today.getDate();
	  let hour = this.today.getHours();
	  let minutes = this.today.getMinutes();
	  this.sec = this.today.getSeconds();
	  this.today_start = Math.floor( new Date( year, month, day, 0, 0, 0, 0 ).getTime()/1000 );
	  // let today_elapsed = Math.floor( (today_time - this.today_start)/60 ) - 300;
	  let today_elapsed = ( (hour * 60) + minutes ) - 360;
	  if( today_elapsed < 0 ){
		  today_elapsed = 1440 + today_elapsed;
	  }
	  //console.log("Time now: ", today_time );
	  //console.log("Day start: ", this.today_start );
	  this.minutes_passed = today_elapsed;
	  this.prev = this.minutes_passed - 1;
	  this.next = this.minutes_passed + 1;
	  if( this.prev < 0 ){
		  this.prev = 1439;
	  }
	  if( this.next > 1440 ){
		  this.next = 0;
	  }

	  let reloj_passed = this.minutes_passed;
	  let reloj_prev = this.prev;
	  let reloj_next = this.next;
	  if( reloj_passed > 15 ){
		  reloj_passed = 15;
	  }
	  if( reloj_next > 15 ){
		  reloj_next = 15;
	  }
	  if( reloj_prev > 15 ){
		  reloj_prev = 15;
	  }
	  if( reloj_prev < 0 ){
		  reloj_prev = 0;
	  }
	  this.src = "https://45sna.com/archivos/motta/"+ this.pad( this.minutes_passed, 4) +".png"
	  this.prev_src = "https://45sna.com/archivos/motta/"+ this.pad( this.prev, 4) +".png"
	  this.next_src = "https://45sna.com/archivos/motta/"+ this.pad( this.next, 4) +".png"

	  this.reloj_src = "http://45sna.com/archivos/motta/reloj"+ this.pad( reloj_passed, 3) +".png"
	  this.reloj_prev_src = "http://45sna.com/archivos/motta/reloj"+ this.pad( reloj_prev, 3) +".png"
	  this.reloj_next_src = "http://45sna.com/archivos/motta/reloj"+ this.pad( reloj_next, 3) +".png"


	  //console.log( "Elapsed: ", this.minutes_passed );
  }

  appendImage(){

  }

  appendReloj(){

  }

}
