/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./birthday.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./birthday.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../app.service";
import * as i6 from "ngx-cookie-service/cookie-service/cookie.service";
var styles_BirthdayComponent = [i0.styles];
var RenderType_BirthdayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BirthdayComponent, data: {} });
export { RenderType_BirthdayComponent as RenderType_BirthdayComponent };
function View_BirthdayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "descripcion"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.video_data.items["0"] == null) ? null : _co.video_data.items["0"].snippet.title); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.video_data.items["0"] == null) ? null : _co.video_data.items["0"].snippet.description); _ck(_v, 3, 0, currVal_1); }); }
function View_BirthdayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "yt-player"]], null, null, null, null, null))], null, null); }
function View_BirthdayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["id", "inactive"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No birthday today"]))], null, null); }
export function View_BirthdayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BirthdayComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BirthdayComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BirthdayComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.active && _co.video_data); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.active; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.active; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_BirthdayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-birthday", [], null, null, null, View_BirthdayComponent_0, RenderType_BirthdayComponent)), i1.ɵdid(1, 4308992, null, 0, i3.BirthdayComponent, [i4.HttpClient, i5.AppService, i6.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BirthdayComponentNgFactory = i1.ɵccf("app-birthday", i3.BirthdayComponent, View_BirthdayComponent_Host_0, {}, {}, []);
export { BirthdayComponentNgFactory as BirthdayComponentNgFactory };
