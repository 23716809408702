<div id="screen" (window:resize)="setSize()" [ngStyle]="{'cursor': 'url(' + cursor + '), auto'}">
  <div   id="intro_mobile"  >
      <img src="../../assets/images/drawn.gif"></div>
  <div id="tools">
    <a id="gallery" title="galería" [ngClass]="{ 'blinking':blinkClass }" [inlineSVG]="'../../assets/images/whiteboard2.svg'" (click)="show_gallery = !show_gallery"></a>
    <input title="seleccionar color" id="colorpicker" [(colorPicker)]="color" [cpOKButton]="true"/>
    <a id="download" title="tomar foto" download="45sna.png" (click)="download($event);saveImage()" [inlineSVG]="'../../assets/images/whiteboard3.svg'"></a>
       <a id="informacion" title="info" download="45sna.png"  [inlineSVG]="'../../assets/images/whiteboard4.svg'"></a>
  </div>

  <div *ngIf="show_gallery" class="screenshots_gallery">
    <small>cargando</small>
    <img class="screenshot" *ngFor="let post of posts$ | async" [src]="post.media_details.sizes.medium.source_url || post.source_url" (click)="show_gallery = !show_gallery;draw( post.source_url )"/>
  </div>

<div id="informacion_container" class="showInfo">
  <div class="informacion_content">

  <div class="info">
    <div id="cerrar">
      <img src="../../assets/images/cerrar.svg">
    </div>
  <p>El muro es un espacio para dibujar con otras personas públicamente, anónimamente y simultáneamente. Todo lo que se dibuje en el muro será visto en tiempo real por los usuarios que estén en él. Use estas herramientas para seleccionar el color, tomar fotos y ver dibujos anteriores.</p></div>
</div></div>
  <!-- <div id="cursors">

    <div class="cursor_options">
      <img class="cursor_option" *ngFor="let post of posts$ | async" [src]="post.media_details.sizes.thumbnail.source_url || post.source_url" (click)="cursor=post.media_details.sizes.thumbnail.source_url || post.source_url"/>
    </div>


    <div id="cursor_uploader" [ngClass]="{'active':subir}" >
      <span *ngIf="!subir" (click)="subir = true;">Subir cursor<div [inlineSVG]="'../../assets/images/upload.svg'"></div></span>
      <label *ngIf="!uploaded && subir" class="upload-button">
        <input type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
      </label>

      <button *ngIf="!uploaded && subir" type="button" class="start-upload-btn" (click)="startUpload()">
        Subir
      </button>

      <pre *ngIf="uploading">subiendo...</pre>

      <img class="current_cursor" *ngIf="uploaded" [src]="uploaded_file" width="40" />
    </div>
  </div> -->

  <canvas class="whiteboard"
        (mousemove)="throttle( onMouseMove($event), 10 )"
        (mousedown)="onMouseDown($event)"
        (mouseup)="onMouseUp($event)"
        (touchstart)="onMouseDown($event)"
        (touchend)="onMouseUp($event)"
        (touchmove)="throttle( onMouseMove($event), 10 )"
        [ngStyle]="{'width': width+'px', 'height': height+'px', 'transform': 'translate('+offset.x+'px,'+offset.y+'px)'}"></canvas>
</div>
